



import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGetPackageByIdQuery, useUpdatePackageMutation } from '../../store/services/productService'; // Adjust import path
import Wrapper from './Wrapper'; // Adjust import path
import ScreenHeader from '../../components/ScreenHeader'; // Adjust import path
import { useTranslation } from 'react-i18next';

const UpdatePackage = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();
  const { data: packageData, error: fetchError } = useGetPackageByIdQuery(id);
  const [updatePackage, { isLoading, error: updateError }] = useUpdatePackageMutation();

  const [formData, setFormData] = useState({
    package_name: '',
    box_name: '',

    package_type: '',
    total_fees: '',
    payment_plan: '',
    is_active: false,
    courses: [], // Array of course IDs (strings)
    installments: [],
    expiration_date: '',
    image1: null,
    imagePreview: ''
  });

  useEffect(() => {
    if (packageData) {
      setFormData({
        box_name: packageData.box_name,

        package_name: packageData.package_name,
        package_type: packageData.package_type,
        total_fees: packageData.total_fees,
        payment_plan: packageData.payment_plan,
        is_active: packageData.is_active,
        courses: packageData.courses.map(course => course._id), // Store only course IDs
        installments: packageData.installments,
        expiration_date: packageData.expiration_date ? new Date(packageData.expiration_date).toISOString().substr(0, 10) : '',
        image1: null,
        imagePreview: packageData.image1 // Assuming the packageData includes the image URL
      });
    }
  }, [packageData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image1: file,
      imagePreview: URL.createObjectURL(file), // Create a preview URL
    }));
  };

  const handleCourseChange = (index, value) => {
    const updatedCourses = [...formData.courses];
    updatedCourses[index] = value; // Update course ID
    setFormData((prevData) => ({
      ...prevData,
      courses: updatedCourses,
    }));
  };

  const handleAddCourse = () => {
    setFormData((prevData) => ({
      ...prevData,
      courses: [...prevData.courses, ''], // Add an empty course ID string
    }));
  };

  const handleDeleteCourse = (index) => {
    const updatedCourses = formData.courses.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      courses: updatedCourses,
    }));
  };

  const handleInstallmentChange = (index, field, value) => {
    const updatedInstallments = [...formData.installments];
    updatedInstallments[index] = { ...updatedInstallments[index], [field]: value };
    setFormData((prevData) => ({
      ...prevData,
      installments: updatedInstallments,
    }));
  };

  const handleAddInstallment = () => {
    setFormData((prevData) => ({
      ...prevData,
      installments: [...prevData.installments, {
        installment_number: '',
        amount: '',
        due_date: '',
        paid_date: '',
        status: ''
      }],
    }));
  };

  const handleDeleteInstallment = (index) => {
    const updatedInstallments = formData.installments.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      installments: updatedInstallments,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();

    for (const key in formData) {
      if (formData[key] !== null && key !== 'courses' && key !== 'installments') {
        updatedFormData.append(key, formData[key]);
      } else if (Array.isArray(formData[key])) {
        updatedFormData.append(key, JSON.stringify(formData[key]));
      }
    }

    try {
      await updatePackage({ id, formData: updatedFormData }).unwrap();
      navigate('dashboard/get/admin/package'); // Redirect to the packages list or detail page
    } catch (error) {
      console.error('Failed to update package:', error);
    }
  };

  return (
    <Wrapper>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-md shadow-md mt-10 bg-yellow-500">
        <ScreenHeader>
        <Link to="/dashboard/get/admin/package" className="btn-dark">
        {/* Back */}
        {t('admin-update-package-backButton')}

        </Link>
        <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">
          
          {/* Update Package */}
          {t('admin-update-package-updatePackageTitle')}

          </p>
      </ScreenHeader>

        {/* {fetchError && <p className="text-red-500 mb-4">Error fetching package: {fetchError.message}</p>}
        {updateError && <p className="text-red-500 mb-4">Error updating package: {updateError.message}</p>} */}

{fetchError && <p className="text-red-500 mb-4">{t('admin-update-package-fetchError')} {fetchError.message}</p>}
{updateError && <p className="text-red-500 mb-4">{t('admin-update-package-updateError')} {updateError.message}</p>}

        <form onSubmit={handleSubmit} className="space-y-6 text-black">
          {/* Package Name */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              {/* Package Name: */}
              {t('admin-update-package-packageName')}
              </label>
            <input
              type="text"
              name="package_name"
              value={formData.package_name}
              onChange={handleChange}
              // placeholder="Package Name"
              placeholder={t('admin-update-package-packageName')}

              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Box Name:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-boxName')}</label>

            <input
              type="text"
              name="box_name"
              value={formData.box_name}
              onChange={handleChange}
              // placeholder="box Name"
              placeholder={t('admin-update-package-boxName')}

              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              
            />
          </div>

          {/* Package Type */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Package Type:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-packageType')}</label>

            <input
              type="text"
              name="package_type"
              value={formData.package_type}
              onChange={handleChange}
              // placeholder="Package Type",
              placeholder={t('admin-update-package-packageType')}

              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Total Fees */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Total Fees:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-totalFees')}</label>

            <input
              type="number"
              name="total_fees"
              value={formData.total_fees}
              onChange={handleChange}
              // placeholder="Total Fees",
              placeholder={t('admin-update-package-totalFees')}

              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Payment Plan */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Payment Plan:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-paymentPlan')}</label>

            <input
              type="text"
              name="payment_plan"
              value={formData.payment_plan}
              onChange={handleChange}
              // placeholder="Payment Plan",
              placeholder={t('admin-update-package-paymentPlan')}

              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Active Status */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Active Status:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-activeStatus')}</label>

            <input
              type="checkbox"
              name="is_active"
              checked={formData.is_active}
              onChange={(e) => setFormData(prevData => ({ ...prevData, is_active: e.target.checked }))}
              className="mr-2"
            />
            {/* <span>Active</span> */}
            <span>{t('admin-update-package-activeStatus')}</span>

          </div>

          {/* Expiration Date */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Expiration Date:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-expirationDate')}</label>

            <input
              type="date"
              name="expiration_date"
              value={formData.expiration_date}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
            />
          </div>

          {/* Image Preview */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Package Image:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-packageImage')}</label>

            <div className="mb-4">
              {formData.imagePreview && (
                <img
                  src={formData.imagePreview}
                  alt="Package Preview"
                  className="w-32 h-32 object-cover mb-2"
                />
              )}
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                className="w-full border border-gray-300 rounded-md py-2 px-4"
              />
            </div>
          </div>

          {/* Courses */}
          <div>
            {/* <label className="block text-gray-700 font-medium mb-2">Courses:</label> */}
            <label className="block text-gray-700 font-medium mb-2">{t('admin-update-package-courses')}</label>

            {formData.courses.map((courseId, index) => (
              <div key={index} className="border p-4 mb-4 rounded-md">
                <input
                  type="text"
                  value={courseId}
                  onChange={(e) => handleCourseChange(index, e.target.value)}
                  // placeholder="Course ID"
                  placeholder={t('admin-update-package-courseId')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <button
                  type="button"
                  onClick={() => handleDeleteCourse(index)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  {/* Remove */}
                  {t('admin-update-package-removeCourse')}
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddCourse}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              {/* Add Course */}
              {t('admin-update-package-addCourse')}

            </button>
          </div>

          {/* Installments */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              {/* Installments: */}
              {t('admin-update-package-installments')}

              </label>

            {formData.installments.map((installment, index) => (
              <div key={index} className="border p-4 mb-4 rounded-md">
                <input
                  type="number"
                  value={installment.installment_number}
                  onChange={(e) => handleInstallmentChange(index, 'installment_number', e.target.value)}
                  // placeholder="Installment Number"
                  placeholder={t('admin-update-package-installmentNumber')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <input
                  type="number"
                  value={installment.amount}
                  onChange={(e) => handleInstallmentChange(index, 'amount', e.target.value)}
                  // placeholder="Amount"
                  placeholder={t('admin-update-package-amount')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <input
                  type="date"
                  value={installment.due_date}
                  onChange={(e) => handleInstallmentChange(index, 'due_date', e.target.value)}
                  // placeholder="Due Date"
                  placeholder={t('admin-update-package-dueDate')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <input
                  type="date"
                  value={installment.paid_date}
                  onChange={(e) => handleInstallmentChange(index, 'paid_date', e.target.value)}
                  // placeholder="Paid Date"
                  placeholder={t('admin-update-package-paidDate')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <input
                  type="text"
                  value={installment.status}
                  onChange={(e) => handleInstallmentChange(index, 'status', e.target.value)}
                  // placeholder="Status"
                  placeholder={t('admin-update-package-status')}

                  className="w-full px-4 py-2 border border-gray-300 rounded-md mb-2"
                />
                <button
                  type="button"
                  onClick={() => handleDeleteInstallment(index)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  {/* Remove */}
                  {t('admin-update-package-removeInstallment')}

                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddInstallment}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              {/* Add Installment */}
            {t('admin-update-package-addInstallment')}

            </button>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="bg-green-500 text-white px-6 py-3 rounded-md"
              disabled={isLoading}
            >
              {isLoading ? t('admin-update-package-updating') : t('admin-update-package-submitButton')}
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default UpdatePackage;



