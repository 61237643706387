import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import {BsPersonCircle} from "react-icons/bs"
import {AiOutlineShoppingCart, AiOutlineLogout} from "react-icons/ai"
import {useDispatch} from "react-redux"
import { logout } from "../../store/reducers/authReducer"
const AccountList = () => {
  const { t } = useTranslation();

    const dispatch = useDispatch();
  return (
    <>
    <NavLink to="/user" className="account-list">
    <BsPersonCircle size={22} />
    <span className="account-list-title">
      {/* my account */}
      {t('user-dashboard-account')}
      </span>
    </NavLink>
    <NavLink to="/orders" className="account-list">
    <AiOutlineShoppingCart size={22} />
    <span className="account-list-title">
      {/* Paid Course */}
      {t('user-dashboard-paid-course')}
      </span>
    </NavLink>
    <NavLink to="/user-order-details" className="account-list">
    <AiOutlineShoppingCart size={22} />
    <span className="account-list-title">
       {/* User Profile */}
       {t('user-dashboard-profile')}
       </span>
    </NavLink>
    <span className="account-list cursor-pointer" onClick={() => dispatch(logout('userToken'))}>
    <AiOutlineLogout size={22} />
    <span className="account-list-title">
      {/* logout */}
      {t('user-dashboard-logout')}
      </span>
    </span>
    </>
  )
}

export default AccountList