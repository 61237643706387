


// import React, { useState } from 'react';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import exampleImagea from '../../assets/a.png'; 
// import exampleImageb from '../../assets/b.png'; 


// const ImageSlider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const slides = [
//     {
//       image: exampleImagea,
//       title: 'COFFEE',
//       description: 'Arabica Plantation, Robusta Cherry, Robusta Parchment & Instant Coffee.',
//       buttonText: 'View All',
//       onClick: () => {
//         console.log('Breathtaking Nature clicked');
//       },
//     },
//     {
//       image: exampleImageb,
//       title: 'Urban Exploration',
//       description: 'Dive into the heart of the city and uncover its secrets.',
//       buttonText: 'Learn More',
//       onClick: () => {
//         console.log('Urban Exploration clicked');
//       },
//     },
//     {
//       image: 'https://source.unsplash.com/random/1920x1080?technology',
//       title: 'Futuristic Tech',
//       description: 'Discover the cutting-edge technologies shaping our world.',
//       buttonText: 'Explore Now',
//       onClick: () => {
//         console.log('Futuristic Tech clicked');
//       },
//     },
//     {
//       image: 'https://source.unsplash.com/random/1920x1080?ocean',
//       title: 'Ocean Wonders',
//       description: 'Dive deep into the mysteries of the ocean.',
//       buttonText: 'Dive In',
//       onClick: () => {
//         console.log('Ocean Wonders clicked');
//       },
//     },
//   ];

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === slides.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const prevSlide = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? slides.length - 1 : prevIndex - 1
//     );
//   };

//   const goToSlide = (index) => {
//     setCurrentIndex(index);
//   };

//   return (
//     <div className="relative h-screen w-full bg-black">
//       <div
//         className="absolute inset-0 bg-cover bg-center"
//         style={{ backgroundImage: `url(${slides[currentIndex].image})` }}
//       >
//         <div className="absolute inset-0 bg-black bg-opacity-50"></div>

//         <div className="absolute inset-0 flex flex-col justify-center items-center text-white px-4 md:px-8">
//           <h2 className="text-3xl md:text-5xl font-bold mb-4 text-center">
//             {slides[currentIndex].title}
//           </h2>
//           <p className="text-lg md:text-2xl text-center mb-8">
//             {slides[currentIndex].description}
//           </p>
//           <button
//             onClick={() => slides[currentIndex].onClick()}
//             className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-lg"
//           >
//             {slides[currentIndex].buttonText}
//           </button>
//         </div>

//         {/* Arrows */}
//         <button
//           className="absolute md:top-1/2 md:left-4 md:transform md:-translate-y-1/2 bottom-4 left-4 bg-transparent border border-opacity-30 border-white text-opacity-30 text-white rounded-full p-1 md:p-1.5 hover:bg-black hover:border-opacity-100 hover:text-opacity-100 transition-all duration-300 ease-in-out md:w-[70px] md:h-[70px] w-[70px] h-[70px] flex justify-center items-center"
//           onClick={prevSlide}
//         >
//           <FaArrowLeft className="h-4 w-4 md:h-5 md:w-5" />
//         </button>
//         <button
//           className="absolute md:top-1/2 md:right-4 md:transform md:-translate-y-1/2 bottom-4 right-4 bg-transparent border border-opacity-30 border-white text-opacity-30 text-white rounded-full p-1 md:p-1.5 hover:bg-black hover:border-opacity-100 hover:text-opacity-100 transition-all duration-300 ease-in-out md:w-[70px] md:h-[70px] w-[70px] h-[70px] flex justify-center items-center"
//           onClick={nextSlide}
//         >
//           <FaArrowRight className="h-4 w-4 md:h-5 md:w-5" />
//         </button>
//       </div>

//       {/* Slide Indicators - Removed on Mobile */}
//       <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 hidden md:flex space-x-3">
//         {slides.map((_, index) => (
//           <div
//             key={index}
//             onClick={() => goToSlide(index)}
//             className={`h-3 w-3 md:h-4 md:w-4 rounded-full cursor-pointer ${
//               index === currentIndex
//                 ? 'bg-blue-600'
//                 : 'bg-white bg-opacity-50 hover:bg-opacity-75'
//             }`}
//           ></div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ImageSlider;















import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import exampleImagea from '../../assets/a.png'; 
import exampleImageb from '../../assets/b.png'; 
import exampleImagec from '../../assets/c.png'; 
import exampleImaged from '../../assets/d.png'; 
import exampleImagee from '../../assets/e.png'; 

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate(); // Hook to navigate programmatically

  const slides = [
    {
      image: exampleImageb,
      title: 'COFFEE',
      description: 'Arabica Plantation, Robusta Cherry, Robusta Parchment & Instant Coffee.',
      buttonText: 'View All',
      route: '/coffee/Arabica', // Route to navigate to
    },
    {
      image: exampleImagea,
      title: 'RICE ',
      description: '1121 Basmati, Pusa Basmati, Parimal (PR 11/14), Sharbati, Sugandha etc.',
      buttonText: 'View All',
      route: '/indianricehome/Basmati_Rice', // Route to navigate to
    },
    {
      image: exampleImagee,
      title: 'OIL SEEDS',
      description: 'Sesame Seeds - Natural, Hulled & Roasted. Groudnuts - Bold & Java.',
      buttonText: 'View All',
      route: '/oilseedshome/Sesame_Seeds', // Route to navigate to
    },
    {
      image: exampleImagec,
      title: 'Dry Fruits',
      description: 'We have tied up with industry leading facilities to be able to supply highest quality nutmeg and control moisture below 11% and ensure almost 0 fungus beans.',
      buttonText: 'View All',
      route: '/dryfruithome/Nutmeg', // Route to navigate to
    },
    {
      image: exampleImaged,
      title: 'Indian Spices',
      description: 'We are a quality-conscious supplier, exporter and wholesaler of high-quality Indian Coriander.',
      buttonText: 'View All',
      route: '/spicesindianhome/Coriander', // Route to navigate to
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handleButtonClick = (route) => {
    navigate(route); // Navigate to the specified route
  };

  return (
    <div className="relative h-screen w-full bg-black">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${slides[currentIndex].image})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        <div className="absolute inset-0 flex flex-col justify-center items-center text-white px-4 md:px-8">
          <h2 className="text-3xl md:text-5xl font-bold mb-4 text-center">
            {slides[currentIndex].title}
          </h2>
          <p className="text-lg md:text-2xl text-center mb-8 w-[50%]">
            {slides[currentIndex].description}
          </p>
          <button
            onClick={() => handleButtonClick(slides[currentIndex].route)}
            className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-lg"
          >
            {slides[currentIndex].buttonText}
          </button>
        </div>

        {/* Arrows */}
        <button
          className="absolute md:top-1/2 md:left-4 md:transform md:-translate-y-1/2 bottom-4 left-4 bg-transparent border border-opacity-30 border-white text-opacity-30 text-white rounded-full p-1 md:p-1.5 hover:bg-black hover:border-opacity-100 hover:text-opacity-100 transition-all duration-300 ease-in-out md:w-[70px] md:h-[70px] w-[70px] h-[70px] flex justify-center items-center"
          onClick={prevSlide}
        >
          <FaArrowLeft className="h-4 w-4 md:h-5 md:w-5" />
        </button>
        <button
          className="absolute md:top-1/2 md:right-4 md:transform md:-translate-y-1/2 bottom-4 right-4 bg-transparent border border-opacity-30 border-white text-opacity-30 text-white rounded-full p-1 md:p-1.5 hover:bg-black hover:border-opacity-100 hover:text-opacity-100 transition-all duration-300 ease-in-out md:w-[70px] md:h-[70px] w-[70px] h-[70px] flex justify-center items-center"
          onClick={nextSlide}
        >
          <FaArrowRight className="h-4 w-4 md:h-5 md:w-5" />
        </button>
      </div>

      {/* Slide Indicators - Removed on Mobile */}
      <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 hidden md:flex space-x-3">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => goToSlide(index)}
            className={`h-3 w-3 md:h-4 md:w-4 rounded-full cursor-pointer ${
              index === currentIndex
                ? 'bg-blue-600'
                : 'bg-white bg-opacity-50 hover:bg-opacity-75'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;






