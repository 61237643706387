import React from 'react';
import { useTranslation } from 'react-i18next';

import HomeNav from "./HomeNav";
// import Footer from './Footer';
import { Link } from 'react-router-dom';
import Footer from './FooterLms';

const SecoundBac = () => {
  const { t } = useTranslation();

  const boxes = [
    {
      id: 1,
      title: 'Mathematics 2Bac Sm',
      coursesCount: 6,
      price: '98 د.م.',
      imageUrl: 'https://cdn.filestackcontent.com/elFL8LNT4SYTTmsLbgMM',
      link: '/p/2bac-sm-annee-maths',
    },
 
  ];

  return (
    <>
     <div className="flex flex-col min-h-[80vh] h-auto  bg-gray-200">
      <HomeNav />
      <section className="flex-grow  mt-[60px] flex items-center justify-center">
        <div className="container mx-auto px-5 py-5">
          <div className="flex flex-col md:flex-row md:justify-center gap-5">
            {/* First Item */}
            <div className="w-full md:w-1/2 lg:w-1/3">
              <Link to="/" className="block bg-yellow-300 p-5 text-center rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold mb-5">
                  {/* 2 Bac sm packs */}
                  {t('bac-secound-a')}

                  </h2>
              </Link>
            </div>

            {/* Second Item */}
            <div className="w-full md:w-1/2 lg:w-1/3">
              <Link to="/" className="block bg-yellow-300 p-5 text-center rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold mb-5">
                  {/* Another Pack */}
                  {t('bac-secound-b')}

                  </h2>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
    <Footer />
    </>
   
  );
};

export default SecoundBac;

