


import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useCProductMutation, useAllCategoriesQuery, useCoffeeTypesQuery,useGetCategoryByNameQuery,useGetAllCoffeeTypesByCategoryQuery ,useGetallCatagoryMainQuery,useGetallCatagoryForTypeAdminQuery,useGetallCatagoryTypeMainQuery,useGetallCatagoryTypeMainWithOutpageQuery} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";

const CreateProduct = () => {
    // const { data: categories = [], isFetching: isFetchingCategories } = useGetallCatagoryForTypeAdminQuery();
    const [name, setName] = useState('Coffee');
    const [categoryId, setCategoryId] = useState(null);

    const { data: categories, error, isLoading ,isFetching ,isFetchingCategories} = useGetCategoryByNameQuery(name, {
        skip: !name, // Skip the query if no name is provided
      });
      console.log("data category",categories)
      console.log("data category categories.categories._id",categories ? categories?._id : "null") 

    // const { data: coffeeTypes = [], isFetching: isFetchingCoffeeTypes } = useGetallCatagoryTypeMainWithOutpageQuery();
    // const categoryId = categories?.categories?._id;
    useEffect(() => {
        if (categories?._id) {
            console.log("categories?.categories?._id",categories?.categories?._id)
            setCategoryId(categories._id);
        }
    }, [categories,]);
    
    const { data: coffeeTypes = [], isFetching: isFetchingCoffeeTypes } = useGetAllCoffeeTypesByCategoryQuery(categoryId, {
        skip: !categoryId, // Don't fetch coffee types until we have the category ID
      });
    
    // console.log("categories categories",categories)
    console.log("coffeeTypes coffeeTypes",coffeeTypes)
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        title: '',
        // price: 0,
        category: categoryId ? categoryId : "nill",
        image1: '',
        instructorId: '',
        // Coffee-specific fields
        coffeeType: '',
        pcode: '',
        containerCapacity: {
            twentyFoot: '',
            fortyFoot: ''
        },
        newCropMonths: '',
        grade: '',
        sieve: '',
        screen: '',
        pb: '',
        triage: '',
        blackSeeds: '',
        moisture: '',
        packaging: ''
    });
    const [preview, setPreview] = useState({
        image1: ''
    });

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleInput = e => {
        const { name, value } = e.target;
        if (name === 'twentyFoot' || name === 'fortyFoot') {
            setState({ ...state, containerCapacity: { ...state.containerCapacity, [name]: value } });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const [createNewProduct, response] = useCProductMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);

        const instructorId = localStorage.getItem('instructor-id');
        if (instructorId) {
            formData.append('instructorId', instructorId);
        } else {
            toast.error("Instructor ID is missing");
            return;
        }

        createNewProduct(formData);
    };

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess("Product created successfully"));
            navigate('/dashboard/teacher');
        }
    }, [response?.isSuccess, dispatch, navigate]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark  bg-blue-500 hover:bg-red-500">
                    <i className="bi bi-arrow-left-short"></i> Coffee Product List
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3  bg-yellow-300">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="title" className="label text-black">Title</label>
                            <input type="text" name="title" className="form-control" id="title" placeholder="Title..." onChange={handleInput} value={state.title} />
                        </div>
                        {/* <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="price" className="label text-black">Price</label>
                            <input type="number" name="price" className="form-control" id="price" placeholder="Price..." onChange={handleInput} value={state.price} />
                        </div> */}
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label text-black">Categories</label>



                            {!isFetchingCategories ?  (
                                <select name="category" id="category" className="form-control" onChange={handleInput} value={state.category}>
                                    <option value="">Choose category</option>
                                    {categories?( 
                                        <option value={categories._id} key={categories._id}>{categories.name}</option>
                                    ) : <p>hello</p>}
                                </select>
                            ) : <Spinner />}



                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="coffeeType" className="label text-black">Coffee Type</label>
                            {!isFetchingCoffeeTypes ? coffeeTypes?.length > 0 && (
                                <select name="coffeeType" id="coffeeType" className="form-control" onChange={handleInput} value={state.coffeeType}>
                                    <option value="">Choose coffee type</option>
                                    {coffeeTypes?.map(coffeeType => (
                                        <option value={coffeeType._id} key={coffeeType._id}>{coffeeType.name}</option>
                                    ))}
                                </select>
                            ) : <Spinner />}
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="pcode" className="label text-black">Product Code</label>
                            <input type="text" name="pcode" className="form-control" id="pcode" placeholder="Product Code..." onChange={handleInput} value={state.pcode} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="grade" className="label text-black">Grade</label>
                            <input type="text" name="grade" className="form-control" id="grade" placeholder="Grade..." onChange={handleInput} value={state.grade} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="containerCapacityTwentyFoot" className="label text-black">20' Container Capacity</label>
                            <input type="text" name="twentyFoot" className="form-control" id="containerCapacityTwentyFoot" placeholder="20' Container Capacity..." onChange={handleInput} value={state.containerCapacity.twentyFoot} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="containerCapacityFortyFoot" className="label text-black">40' Container Capacity</label>
                            <input type="text" name="fortyFoot" className="form-control" id="containerCapacityFortyFoot" placeholder="40' Container Capacity..." onChange={handleInput} value={state.containerCapacity.fortyFoot} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="newCropMonths" className="label text-black">New Crop Months</label>
                            <input type="text" name="newCropMonths" className="form-control" id="newCropMonths" placeholder="New Crop Months..." onChange={handleInput} value={state.newCropMonths} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="sieve" className="label text-black">Sieve</label>
                            <input type="text" name="sieve" className="form-control" id="sieve" placeholder="Sieve..." onChange={handleInput} value={state.sieve} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="screen" className="label text-black">Screen</label>
                            <input type="number" name="screen" className="form-control" id="screen" placeholder="Screen..." onChange={handleInput} value={state.screen} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="pb" className="label text-black">PB</label>
                            <input type="text" name="pb" className="form-control" id="pb" placeholder="PB..." onChange={handleInput} value={state.pb} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="triage" className="label text-black">Triage</label>
                            <input type="text" name="triage" className="form-control" id="triage" placeholder="Triage..." onChange={handleInput} value={state.triage} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="blackSeeds" className="label text-black">Black Seeds</label>
                            <input type="text" name="blackSeeds" className="form-control" id="blackSeeds" placeholder="Black Seeds..." onChange={handleInput} value={state.blackSeeds} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="moisture" className="label text-black">Moisture</label>
                            <input type="text" name="moisture" className="form-control" id="moisture" placeholder="Moisture..." onChange={handleInput} value={state.moisture} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="packaging" className="label text-black">Packaging</label>
                            <input type="text" name="packaging" className="form-control" id="packaging" placeholder="Packaging..." onChange={handleInput} value={state.packaging} />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label text-black">Product Image</label>
                            <input type="file" name="image1" id="image1" className="form-control" onChange={imageHandle} />
                            {preview.image1 && <ImagesPreview url={preview.image1} heading="Image 1" image={preview.image1} />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label text-black">Description</label>
                            <ReactQuill value={value} onChange={setValue} />
                        </div>
                        <div className="w-full p-3">
                            <button type="submit" className="btn-primary bg-blue-500 p-4 rounded hover:bg-red-500">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </WrapperTeacher>
    );
};

export default CreateProduct;


