import React from 'react';
import { useTranslation } from 'react-i18next';

import HomeNav from "./HomeNav";
// import Footer from '../home/Footer';
import {
  useGetPaymentMethodsQuery
} from '../../store/services/SupService';
import Footer from './FooterLms';

const RegistrationInfo = () => {
  const { t } = useTranslation();

  const { data: paymentMethods, isLoading, error: fetchError } = useGetPaymentMethodsQuery();

  return (
    <div className='bg-[#f5f5f8]'>
                          {t('instructor')}

      <HomeNav />
      <div className="p-4 mt-[60px] md:p-8 lg:p-12 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
        {/* Subscription Options Section */}
        <div className="mb-8">
          <h1 className="text-2xl md:text-3xl font-bold mb-4 text-center text-gray-800">
            {/* How to Register */}
            {t('courses-pricecomp-price-title')}

            </h1>
          <div className="bg-blue-50 p-6 rounded-lg border border-blue-200 shadow-md">
            <h2 className="text-xl md:text-2xl font-semibold mb-4 text-blue-800">
              
              {/* Subscription Options */}
              {t('courses-pricecomp-price-box-a')}

              
              </h2>
            <p className="text-base md:text-lg mb-4">

              {/* You can subscribe either annually or monthly. With the annual subscription, you will have access to all content for the entire year. The monthly subscription allows you to access one or two lessons per month, with access to additional lessons provided upon payment of the monthly installment. */}
            
              {t('courses-pricecomp-price-box-b')}

            </p>
            <p className="text-base md:text-lg">
              <strong>
                {/* Note: */}
                {t('courses-pricecomp-price-box-c')}

                </strong>
                
                 {/* If you haven't subscribed since the beginning of the year, there's no need to worry. Registration is always open, and you will receive access to content from the beginning of the year upon subscribing. */}
                 {t('courses-pricecomp-price-box-d')}

            </p>
          </div>
        </div>

        {/* Payment Methods Section */}
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-700">
          {/* 💰 Payment Methods */}
          💰 {t('courses-pricecomp-price-box-e')}

          </h2>
        <div className="space-y-6">





          {/* Loading and error states */}
          {isLoading ? (
            <p>Loading payment methods...</p>
          ) : fetchError ? (
            <p className="text-red-500">Error fetching payment methods: {fetchError.message}</p>
          ) : (
            <div>
              {/* Payment methods list */}
              {paymentMethods.map((method) => (
                <div key={method._id} className="mb-4 p-4 border rounded">


                  <div className="p-4 bg-yellow-300 rounded-md shadow-md">
                    <h3 className="text-lg font-semibold mb-2 text-gray-900">Bank Transfer</h3>
                    <p className="text-base mb-2">Transfer the amount via bank, WafaCash, or Cash Plus agencies. For inquiries before payment, contact us on WhatsApp or call <a href="tel:+0617074062" className="text-blue-600 underline">0617074062</a>.</p>
                    <div className="bg-white p-3 rounded-md border border-gray-200">
                      <h4 className="font-semibold text-gray-800">Bank Name: {method.bank_transfer.bank_name}</h4>
                      <h4 className="font-semibold text-gray-800">Account Holder: {method.bank_transfer.account_holder_name}</h4>
                      <p>RIB: {method.bank_transfer.rib}</p>
                    </div>
                  </div>



                  {/* <h4 className="text-lg font-bold mt-2">Cash Transfer</h4> */}
                  {method.cash_transfer.methods.map((cashMethod, index) => (
                    // <div key={index} className="mb-2">
                    //   <p>Agency Name: {cashMethod.agency_name}</p>
                    //   <p>Details: {cashMethod.details}</p>
                    //   <p>Contact Name: {cashMethod.contact_name}</p>
                    //   <p>Contact First Name: {cashMethod.contact_first_name}</p>
                    //   <p>City: {cashMethod.city}</p>
                    // </div>


                    <div key={index} className="p-4 bg-blue-300 rounded-md shadow-md mt-[60px]">
                      <h3 className="text-lg font-semibold mb-2 text-gray-900">Cash Plus</h3>
                      <p className="text-base mb-2">Name: {cashMethod.agency_name}</p>
                      <p className="text-base mb-2">Contact Name: {cashMethod.contact_name}</p>
                      <p className="text-base mb-2"> First Name: {cashMethod.contact_first_name}</p>
                      <p className="text-base mb-2">City: {cashMethod.city}</p>
                      <p className="text-base">Details: {cashMethod.details}</p>
                    </div>

                  ))}


                  {/* <h4 className="text-lg font-bold mt-2">Contact Info</h4>
                  <p>WhatsApp Number: {method.contact_info.whatsapp_number}</p>
                  <p>Phone Number: {method.contact_info.phone_number}</p>
                  <p>Additional Info: {method.contact_info.additional_info}</p>
                  <p>Registration Confirmation Info: {method.contact_info.registration_confirmation_info}</p> */}

                  <div className="mt-8 p-4 bg-green-50 rounded-md border border-green-200">
                    <h2 className="text-lg font-semibold mb-2 text-green-800">📩 Registration Confirmation</h2>
                    <p className="text-base mb-4">{method.contact_info.registration_confirmation_info}</p>
                    <p className="text-sm text-gray-600">Please ensure you have the transfer receipt ready when contacting us.</p>

                    {/* WhatsApp Button */}
                    <div className="flex justify-center mt-6">
                      <a
                        href={`https://wa.me/${method.contact_info.whatsapp_number}`}
                        className="px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 transition duration-300"
                      >
                        Send a message on WhatsApp
                      </a>
                    </div>
                  </div>


                  <div className="mt-8 p-4 bg-green-50 rounded-md border border-green-200">
                    <h2 className="text-lg font-semibold mb-2 text-green-800">Additional Info:</h2>
                    <p className="text-base mb-4">{method.contact_info.additional_info}</p>
                    <p className="text-base mb-4">Phone Number: {method.contact_info.phone_number}</p>


              
                  </div>


                </div>
              ))}
            </div>
          )}















          {/* Bank Transfer */}


          {/* <div className="p-4 bg-gray-50 rounded-md shadow-md">
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Bank Transfer</h3>
            <p className="text-base mb-2">Transfer the amount via bank, WafaCash, or Cash Plus agencies. For inquiries before payment, contact us on WhatsApp or call <a href="tel:+0617074062" className="text-blue-600 underline">0617074062</a>.</p>
            <div className="bg-white p-3 rounded-md border border-gray-200">
              <h4 className="font-semibold text-gray-800">ATTIJARI BANK</h4>
              <p>RIB ZAKARIAE BOUICHA: 007349000485530040115061</p>
            </div>
          </div> */}

          {/* Cash Plus */}


          {/* <div className="p-4 bg-gray-50 rounded-md shadow-md">
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Cash Plus</h3>
            <p className="text-base mb-2">Name: Bouicha</p>
            <p className="text-base mb-2">First Name: Zakariae</p>
            <p className="text-base mb-2">City: Sidi Slimane</p>
            <p className="text-base">After the transfer, send the receipt on WhatsApp to <a href="tel:+0617074062" className="text-blue-600 underline">0617074062</a> or call directly. Transfer costs are not included.</p>
          </div> */}

          {/* Wafa Cash */}

          
          {/* <div className="p-4 bg-gray-50 rounded-md shadow-md">
            <h3 className="text-lg font-semibold mb-2 text-gray-900">Wafa Cash</h3>
            <p className="text-base mb-2">Name: Bouicha</p>
            <p className="text-base mb-2">First Name: Zakariae</p>
            <p className="text-base mb-2">City: Sidi Slimane</p>
            <p className="text-base">After the transfer, send the receipt on WhatsApp to <a href="tel:+0617074062" className="text-blue-600 underline">0617074062</a> or call directly. Transfer costs are not included. You will also be added to the WhatsApp group for registered users.</p>
          </div> */}
        </div>

        {/* Registration Confirmation Section */}
        {/* <div className="mt-8 p-4 bg-green-50 rounded-md border border-green-200">
          <h2 className="text-lg font-semibold mb-2 text-green-800">📩 Registration Confirmation</h2>
          <p className="text-base mb-4">After the transfer, send the receipt on WhatsApp to <a href="tel:+0617074062" className="text-blue-600 underline">0617074062</a> or call directly. Once registered, you will receive access to the platform’s lessons and be added to the WhatsApp group for registered users.</p>
          <p className="text-sm text-gray-600">Please ensure you have the transfer receipt ready when contacting us.</p> */}

          {/* WhatsApp Button */}
          {/* <div className="flex justify-center mt-6">
            <a
              href="https://wa.me/0617074062"
              className="px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-lg hover:bg-green-600 transition duration-300"
            >
              Send a message on WhatsApp
            </a> */}
          {/* </div> */}
        {/* </div> */}
      </div>
      <Footer/>
      {/* <Footer /> */}
    </div>

  );
};

export default RegistrationInfo;

