



 
import ScreenHeader from "../../components/ScreenHeader";
import { useTranslation } from 'react-i18next';

import Wrapper from "./Wrapper";
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useGetStudentsingleQuery,
  useToupdateStudentMutation
} from '../../store/services/productService';
import Spinner from '../../components/Spinner';
import { setSuccess } from '../../store/reducers/globalReducer';

const EditStudent = () => {
  const { t } = useTranslation(); // Initialize translation hook

  const { id } = useParams();
  const { data: student, isFetching: fetching } = useGetStudentsingleQuery(id);
  const [value, setValue] = useState('');
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    whatsapp_number: '',
    role: 'user',
    paid_courses: [],
    total_paid_amount: 0,
    email_verified: false,
    admin: false,
  });

  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handlePaidCoursesChange = (index, e) => {
    const updatedCourses = [...state.paid_courses];
    updatedCourses[index][e.target.name] = e.target.value;
    setState({ ...state, paid_courses: updatedCourses });
  };

  const addNewCourse = () => {
    setState({
      ...state,
      paid_courses: [
        ...state.paid_courses,
        {
          course_id: '',
          amount_paid: 0,
          date_of_payment: '',
          access_expires_at: '',
        },
      ],
    });
  };

  const removeCourse = (index) => {
    const updatedCourses = [...state.paid_courses];
    updatedCourses.splice(index, 1);
    setState({ ...state, paid_courses: updatedCourses });
  };

  const [updateStudent, response] = useToupdateStudentMutation();
  console.log("response",response)
  const createStudent = (e) => {
    e.preventDefault();
    updateStudent({ id, ...state });
  };

  useEffect(() => {
    if (!response.isSuccess) {
      response?.error?.data?.errors?.forEach((err) => {
        toast.error(err.msg);
      });
    }
  }, [response?.error?.data?.errors]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.isSuccess) {
      dispatch(setSuccess(response?.data?.msg));
      navigate('/dashboard/products');
    }
  }, [response?.isSuccess]);

  useEffect(() => {
    if (!fetching) {
      setState(student);
      setValue(student?.bio || ''); // assuming there's a bio field in the student model
    }
  }, [student]);

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/products" className="btn-dark">
          <i className="bi bi-arrow-left-short"></i>
          
           {/* Students List */}
           {t('admin-dashboard-products-students-update-students_list_title')}

        </Link>
      </ScreenHeader>
      <Toaster position="top-right" reverseOrder={true} />
      {!fetching ? (
        <div className="flex flex-wrap -mx-3">
          <form className="w-full xl:w-8/12 p-3" onSubmit={createStudent}>
            <h3 className="pl-3 capitalize text-lg font-medium text-gray-400">
              {/* Edit Student */}
              {t('admin-dashboard-products-students-update-edit_student_title')}


            </h3>
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="name" className="label">
                  {/* Name */}
                  {t('admin-dashboard-products-students-update-name')}


                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  // placeholder="Name..."
                  placeholder={t('admin-dashboard-products-students-update-placeholder_name')}
                  onChange={handleInput}
                  value={state.name}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="email" className="label">
                  {/* Email */}

                  {t('admin-dashboard-products-students-update-email')}

                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  // placeholder="Email..."
                  placeholder={t('admin-dashboard-products-students-update-placeholder_email')}

                  onChange={handleInput}
                  value={state.email}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="whatsapp_number" className="label">
                  {/* WhatsApp Number */}

                  {t('admin-dashboard-products-students-update-whatsapp_number')}

                </label>
                <input
                  type="text"
                  name="whatsapp_number"
                  className="form-control"
                  id="whatsapp_number"
                  // placeholder="WhatsApp Number..."
                  placeholder={t('admin-dashboard-products-students-update-placeholder_whatsapp_number')}

                  onChange={handleInput}
                  value={state.whatsapp_number}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="role" className="label">
                  {/* Role */}
                  {t('admin-dashboard-products-students-update-role')}


                </label>
                <select
                  name="role"
                  id="role"
                  className="form-control"
                  onChange={handleInput}
                  value={state.role}
                >
                  <option value="user">
                    {/* User */}

                    {t('admin-dashboard-products-students-update-role-user')}

                  </option>
                  <option value="admin">
                    {/* Admin */}

                    {t('admin-dashboard-products-students-update-role-admin')}

                  </option>
                </select>
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="total_paid_amount" className="label">

                  {/* Total Paid Amount */}
{/* / */}
                  {t('admin-dashboard-products-students-update-total_paid_amount')}

                </label>
                <input
                  type="number"
                  name="total_paid_amount"
                  className="form-control"
                  id="total_paid_amount"
                  // placeholder="Total Paid Amount..."
                  placeholder={t('admin-dashboard-products-students-update-placeholder_total_paid_amount')}

                  onChange={handleInput}
                  value={state.total_paid_amount}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="email_verified" className="label">
                  {/* Email Verified */}

                  {t('admin-dashboard-products-students-update-email_verified')}

                </label>
                <input
                  type="checkbox"
                  name="email_verified"
                  className="form-control"
                  id="email_verified"
                  onChange={(e) =>
                    setState({ ...state, email_verified: e.target.checked })
                  }
                  checked={state.email_verified}
                />
              </div>
              <div className="w-full md:w-6/12 p-3">
                <label htmlFor="admin" className="label">
                  {/* Admin */}


                  {t('admin-dashboard-products-students-update-admin')}

                </label>
                <input
                  type="checkbox"
                  name="admin"
                  className="form-control"
                  id="admin"
                  onChange={(e) =>
                    setState({ ...state, admin: e.target.checked })
                  }
                  checked={state.admin}
                />
              </div>
              <div className="w-full p-3">
                <label htmlFor="bio" className="label">
                  {/* Bio */}

                  {t('admin-dashboard-products-students-update-bio')}

                </label>
                <ReactQuill
                  theme="snow"
                  id="bio"
                  value={value}
                  onChange={setValue}
                  // placeholder="Bio..."
                  placeholder={t('admin-dashboard-products-students-update-placeholder_bio')}

                />
              </div>
              <div className="w-full p-3">
                <label className="label">
                  {/* Paid Courses */}
                  
                  {t('admin-dashboard-products-students-update-paid_courses_title')}
                  </label>
                {state.paid_courses.map((course, index) => (
                  <div key={index} className="border p-3 mb-3">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-6/12 p-3">
                        <label htmlFor={`course_id_${index}`} className="label">
                          {/* Course ID */}

                          {t('admin-dashboard-products-students-update-course_id')}

                        </label>
                        <input
                          type="text"
                          name="course_id"
                          className="form-control"
                          id={`course_id_${index}`}
                          // placeholder="Course ID..."
                          placeholder={t('admin-dashboard-products-students-update-course_id')}

                          onChange={(e) => handlePaidCoursesChange(index, e)}
                          value={course.course_id}
                        />
                      </div>
                      <div className="w-full md:w-6/12 p-3">
                        <label
                          htmlFor={`amount_paid_${index}`}
                          className="label"
                        >
                          {/* Amount Paid */}
                          {t('admin-dashboard-products-students-update-amount_paid')}


                        </label>
                        <input
                          type="number"
                          name="amount_paid"
                          className="form-control"
                          id={`amount_paid_${index}`}
                          // placeholder="Amount Paid..."
                          placeholder={t('admin-dashboard-products-students-update-amount_paid')}

                          onChange={(e) => handlePaidCoursesChange(index, e)}
                          value={course.amount_paid}
                        />
                      </div>
                      <div className="w-full md:w-6/12 p-3">
                        <label
                          htmlFor={`date_of_payment_${index}`}
                          className="label"
                        >
                          {/* Date of Payment */}

                          {t('admin-dashboard-products-students-update-date_of_payment')}

                        </label>
                        <input
                          type="date"
                          name="date_of_payment"
                          className="form-control"
                          id={`date_of_payment_${index}`}
                          onChange={(e) =>
                            handlePaidCoursesChange(index, e)
                          }
                          value={course.date_of_payment}
                        />
                      </div>
                      <div className="w-full md:w-6/12 p-3">
                        <label
                          htmlFor={`access_expires_at_${index}`}
                          className="label"
                        >
                          {/* Access Expires At */}
                          {t('admin-dashboard-products-students-update-access_expires_at')}


                        </label>
                        <input
                          type="date"
                          name="access_expires_at"
                          className="form-control"
                          id={`access_expires_at_${index}`}
                          onChange={(e) =>
                            handlePaidCoursesChange(index, e)
                          }
                          value={course.access_expires_at}
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-danger mt-3"
                      onClick={() => removeCourse(index)}
                    >
                      {/* Remove Course */}
                      {t('admin-dashboard-products-students-update-remove_course')}


                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={addNewCourse}
                >
                  {/* Add New Course */}
                  {t('admin-dashboard-products-students-update-add_new_course')}


                </button>
              </div>
              <div className="w-full p-3">
                <button type="submit" className="btn btn-primary">
                  {/* Update Student */}

                  {t('admin-dashboard-products-students-update-update_student')}

                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default EditStudent;

