// // import React from 'react';
// // import { Link } from 'react-router-dom';

// // const Footer = () => {
// //   return (
// //     <footer className="bg-gray-900 text-white py-8">
// //       <div className="container mx-auto px-4">
// //         <div className="flex flex-wrap justify-between">
// //           {/* About Section */}
// //           <div className="w-full ml-1 sm:w-1/2 md:w-1/4 mb-6">
// //             <h3 className="text-xl font-bold mb-4">About Us</h3>
// //             <p className="text-yellow-500">
// //             Chinwi Academy: Empowering You to Learn, Grow, and Shine Bright in Every Step of Your
// //             Journey.
// //             </p>
// //           </div>
          
// //           {/* Quick Links Section */}
// //           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
// //             <h3 className="text-xl font-bold mb-4 ">Quick Links</h3>
// //             <ul className="space-y-2">
// //               <li>
// //                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
// //                   About Us
// //                 </Link>
// //               </li>
// //               <li>
// //                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
// //                   Courses
// //                 </Link>
// //               </li>
// //               <li>
// //                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
// //                   Contact
// //                 </Link>
// //               </li>
// //               <li>
// //                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
// //                   FAQ
// //                 </Link>
// //               </li>
// //             </ul>
// //           </div>
          
// //           {/* Contact Us Section */}
// //           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
// //             <h3 className="text-xl font-bold mb-4">Contact Us</h3>
// //             <p className="text-yellow-500">Damas Road Missour City, Morocco</p>
// //             <p className="text-yellow-500 mt-2">Email: Chinwiacademy@gmail.com</p>
// //             <p className="text-yellow-500">Phone: +212 60 133 8528</p>
// //           </div>
          
// //           {/* Social Media Section */}
// //           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
// //             <h3 className="text-xl font-bold mb-4">Follow Us</h3>
// //             <div className="flex space-x-4">
// //               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
// //                 <i className="fab fa-facebook-f"></i>
// //               </a>
// //               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
// //                 <i className="fab fa-twitter"></i>
// //               </a>
// //               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
// //                 <i className="fab fa-instagram"></i>
// //               </a>
// //               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
// //                 <i className="fab fa-linkedin"></i>
// //               </a>
// //             </div>
// //           </div>
// //         </div>

// //         {/* Footer Bottom Section */}
// //         <div className="border-t border-gray-800 pt-6 mt-6 text-center">
// //           <p className="text-gray-500">&copy; 2024  Chinwiacademy. All rights reserved.</p>
// //         </div>
// //       </div>
// //     </footer>
// //   );
// // };

// // export default Footer;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// const Footer = () => {
//   const { t } = useTranslation(); // Use translation hook

//   return (
//     <footer className="bg-gray-900 text-white py-8">
//       <div className="container mx-auto px-4">
//         <div className="flex flex-wrap justify-between">
//           {/* About Section */}
//           <div className="w-full ml-1 sm:w-1/2 md:w-1/4 mb-6">
//             <h3 className="text-xl font-bold mb-4">{t('footer-about-us')}</h3>
//             <p className="text-yellow-500">
//               {t('footer-about-description')}
//             </p>
//           </div>
          
//           {/* Quick Links Section */}
//           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
//             <h3 className="text-xl font-bold mb-4">{t('footer-quick-links')}</h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
//                   {t('footer-about')}
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
//                   {t('footer-courses')}
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
//                   {t('footer-contact')}
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/" className="text-yellow-500 hover:text-white transition duration-300">
//                   {t('footer-faq')}
//                 </Link>
//               </li>
//             </ul>
//           </div>
          
//           {/* Contact Us Section */}
//           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
//             <h3 className="text-xl font-bold mb-4">{t('footer-contact-us')}</h3>
//             <p className="text-yellow-500">{t('footer-address')}</p>
//             <p className="text-yellow-500 mt-2">{t('footer-email')}</p>
//             <p className="text-yellow-500">{t('footer-phone')}</p>
//           </div>
          
//           {/* Social Media Section */}
//           <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
//             <h3 className="text-xl font-bold mb-4">{t('footer-follow-us')}</h3>
//             <div className="flex space-x-4">
//               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
//                 <i className="fab fa-facebook-f"></i> {t('footer-facebook')}
//               </a>
//               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
//                 <i className="fab fa-twitter"></i> {t('footer-twitter')}
//               </a>
//               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
//                 <i className="fab fa-instagram"></i> {t('footer-instagram')}
//               </a>
//               <a href="#" className="text-gray-400 hover:text-white transition duration-300">
//                 <i className="fab fa-linkedin"></i> {t('footer-linkedin')}
//               </a>
//             </div>
//           </div>
//         </div>

//         {/* Footer Bottom Section */}
//         <div className="border-t border-gray-800 pt-6 mt-6 text-center">
//           <p className="text-gray-500">{t('footer-copyright')}</p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;










import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto px-4">
        <div className="lg:flex lg:justify-between">
          {/* Left Section */}
          <div className="mb-8 lg:mb-0">
            <h3 className="text-xl font-bold mb-4">About Us</h3>
            <p className="text-gray-400 mb-4">
              WGK INTERNATIONAL PVT. LTD is committed to providing exceptional
              service and high-quality products. Established in 1998,
            </p>
            <a href="#contact" className="text-blue-400 hover:underline">
              Contact Us
            </a>
          </div>

          {/* Right Section */}
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebookF className="text-blue-600 hover:text-blue-800" size={24} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="text-blue-400 hover:text-blue-600" size={24} />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className="text-blue-700 hover:text-blue-900" size={24} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="text-pink-500 hover:text-pink-700" size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-700 py-4 mt-2 mb-[-35px]  text-center">
        <p className="text-gray-400 text-sm">
          &copy; {new Date().getFullYear()}  WGK INTERNATIONAL PVT. LTD. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;



