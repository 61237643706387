








// import React, { useEffect, useState } from 'react';
// import SkeletonLoader from './Skeltonmain'; // Adjust the import path accordingly

// // Simulated API Call
// const fetchProducts = async () => {
//   // Simulated delay
//   await new Promise(resolve => setTimeout(resolve, 1000));

//   return Array.from({ length: 8 }, (_, index) => ({
//     id: index + 1,
//     name: `Product ${index + 1}`,
//     image: `https://picsum.photos/300?random=${index + 1}`
//   }));
// };

// const PopularProducts = () => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const loadProducts = async () => {
//       setLoading(true);
//       try {
//         const data = await fetchProducts();
//         setProducts(data);
//       } finally {
//         setLoading(false);
//       }
//     };

//     loadProducts();
//   }, []);

//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="container mx-auto px-4">
//         <h2 className="text-4xl font-bold text-center mb-12">Popular Products</h2>
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
//           {loading
//             ? Array.from({ length: 8 }, (_, index) => <SkeletonLoader key={index} />)
//             : products.map(product => (
//               <div
//                 key={product.id}
//                 className="relative group cursor-pointer rounded-lg overflow-hidden shadow-lg transform transition duration-300 ease-in-out hover:scale-105"
//               >
//                 <img
//                   src={product.image}
//                   alt={product.name}
//                   className="w-full h-60 object-cover"
//                 />
//                 <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center text-white transition-opacity duration-300">
//                   <span className="text-lg font-semibold">{product.name}</span>
//                 </div>
//               </div>
//             ))
//           }
//         </div>
//       </div>
//     </section>
//   );
// };

// export default PopularProducts;









// import React from 'react';
// import SkeletonLoader from './Skeltonmain'; // Adjust the import path accordingly
// import { useGetRandomCoffeeProductsQuery } from '../../store/services/productServiceTeacher'; // Import the API hook

// const PopularProducts = () => {
//   const { data: products, error, isLoading } = useGetRandomCoffeeProductsQuery();

//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="container mx-auto px-4">
//         <h2 className="text-4xl font-bold text-center mb-12">Popular Products</h2>
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
//           {isLoading
//             ? Array.from({ length: 8 }, (_, index) => <SkeletonLoader key={index} />)
//             : products && products.map(product => (
//               <div
//                 key={product.id}
//                 className="relative group cursor-pointer rounded-lg overflow-hidden shadow-lg transform transition duration-300 ease-in-out hover:scale-105"
//               >
//                 <img
//                   src={product.thumbnailUrl} // Updated to use the correct thumbnailUrl field
//                   alt={product.name}
//                   className="w-full h-60 object-cover"
//                 />
//                 <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center text-white transition-opacity duration-300">
//                   <span className="text-lg font-semibold">{product.name}</span>
//                 </div>
//               </div>
//             ))
//           }
//         </div>
//         {error && <div className="text-red-500 text-center mt-4">Error loading products.</div>}
//       </div>
//     </section>
//   );
// };

// export default PopularProducts;















import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import SkeletonLoader from './Skeltonmain'; // Adjust the import path accordingly
import { useGetRandomCoffeeProductsQuery } from '../../store/services/productServiceTeacher'; // Import the API hook

const apiUrl = process.env.REACT_APP_API_URL_TESTINGIMAGE;
// src={`${serverBaseURL}${product.thumbnail_url}`}
// const serverBaseURL = apiUrl;

const PopularProducts = () => {
  const serverBaseURL = apiUrl;

  const { data: products, error, isLoading } = useGetRandomCoffeeProductsQuery();

  return (
    <section className="py-16 bg-gray-100 bg-gray-300">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Popular Products</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {isLoading
            ? Array.from({ length: 8 }, (_, index) => <SkeletonLoader key={index} />)
            : products && products.map(product => (
              <Link
                key={product.id}
                to={`/productdetails/${product.id}`} // Navigate to the product details page with product ID
                className="relative group cursor-pointer rounded-lg overflow-hidden shadow-lg transform transition duration-300 ease-in-out hover:scale-105"
              >
                <img
                  // src={product.thumbnailUrl} // Updated to use the correct thumbnailUrl field
                 src={`${serverBaseURL}${product.thumbnailUrl}`}

                 
                  alt={product.name}
                  className="w-full h-60 object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center text-white transition-opacity duration-300">
                  <span className="text-lg font-semibold">{product.name}</span>
                </div>
              </Link>
            ))
          }
        </div>
        {error && <div className="text-red-500 text-center mt-4">Error loading products.</div>}
      </div>
    </section>
  );
};

export default PopularProducts;
