import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetCategoryDataQuery } from '../../store/services/productServiceTeacher';
import html2plaintext from 'html2plaintext';
import NavDubai from './NavDubai';

const apiUrl = process.env.REACT_APP_API_URL_TESTINGIMAGE;

const DryFruit = () => {
  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(category || 'Cashews');

  const { data, error, isLoading, refetch } = useGetCategoryDataQuery(selectedCategory);
console.log("data",data)
  // Update selectedCategory when the URL parameter changes
  useEffect(() => {
    setSelectedCategory(category);
    refetch();
  }, [category, refetch]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
  const serverBaseURL = apiUrl;

  // Default values if the data is not loaded yet
  const heading = data?.coffeeType?.name || 'Loading...';
  const description = data?.coffeeType?.description || 'Please wait while we load the products.';
  const products = data?.products || [];

  return (

    <>
 




<div className="flex flex-col items-center justify-center mt-[100px] bg-black" style={{ height: '200px', padding: '16px' }}>
  {/* Title */}
  <div className="text-center mb-4">
    <h1 className="text-white text-lg md:text-xl">Dry Fruits    </h1>
  </div>

  {/* Horizontal Rule */}
  <hr className="w-1/2 border-t border-gray-600 mb-4" />

  {/* Navigation Menu */}
  <div className="w-full overflow-x-auto">
    <ul className="flex space-x-2 md:space-x-6 md:justify-center">
      <li className="text-center flex-shrink-0">
        <button
          className={`py-2 px-4 md:py-3 md:px-6 rounded ${selectedCategory === 'Cashews' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-800'} hover:bg-blue-500 text-sm md:text-base`}
          onClick={() => handleCategoryChange('Cashews')}
        >
        Cashews
        </button>
      </li>

      <li className="text-center flex-shrink-0">
        <button
          className={`py-2 px-4 md:py-3 md:px-6 rounded ${selectedCategory === 'Walnuts' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-800'} hover:bg-blue-500 text-sm md:text-base`}
          onClick={() => handleCategoryChange('Walnuts')}
        >
          Walnuts
        </button>
      </li>

      <li className="text-center flex-shrink-0">
        <button
          className={`py-2 px-4 md:py-3 md:px-6 rounded ${selectedCategory === 'Raisins' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-800'} hover:bg-blue-500 text-sm md:text-base`}
          onClick={() => handleCategoryChange('Raisins')}
        >
          Raisins	
        </button>
      </li>

      <li className="text-center flex-shrink-0">
        <button
          className={`py-2 px-4 md:py-3 md:px-6 rounded ${selectedCategory === 'Nutmeg' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-800'} hover:bg-blue-500 text-sm md:text-base`}
          onClick={() => handleCategoryChange('Nutmeg')}
        >
          Nutmeg
        </button>
      </li>

     
    </ul>
  </div>
</div>










    <NavDubai />
    <div className='bg-gray-200'>
    <div className="container mx-auto px-4 py-8 mt-0  ">
      
      

    










      {/* Loading, Error and Data Handling */}
      {isLoading ? (
        <div className="text-center mb-8 mt-9">
          <h2 className="text-3xl font-bold mb-2 text-gray-800">Loading...</h2>
          <p className="text-gray-600">Fetching products...</p>
        </div>
      ) : error ? (
        <div className="text-center mb-8 mt-9">
          <h2 className="text-3xl font-bold mb-2 text-red-800">Error</h2>
          <p className="text-gray-600">Failed to load products. Please try again later.</p>
        </div>
      ) : (
        <>
          <div className="text-center mb-8  ">
            <h2 className="text-3xl font-bold mb-2 text-gray-800">{heading}</h2>
            <p className="text-gray-600">{  html2plaintext(description)}</p>
          </div>

          {/* Product Grid */}
          {/* <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {products.length > 0 ? (
          products.map((product) => (
            <Link 
            
            key={product.id} 
            
            to={`/productdetails/${product._id}`} 

            className="relative block  bg-gray-300 border border-gray-200 rounded overflow-hidden group"
            >
              <img 
              
            //   src={product.image}
              src={`${serverBaseURL}${product.thumbnail_url}`}

              
              alt={product.name} className="w-full h-48 object-cover" />
              <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <h3 className="text-white text-lg font-semibold mb-1">{product.name}</h3>
                <p className="text-white text-md mb-2">{product.price}</p>
                <button className="bg-blue-600 text-white py-1 px-4 rounded">View Details</button>
              </div>
              <div className="p-4 text-center">
                <h3 className="text-lg font-semibold text-gray-800">{product.name}</h3>
                <p className="text-gray-600">{product.price}</p>
                <button className="mt-2 bg-blue-500 text-white py-1 px-4 rounded">View Details</button>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-center text-gray-600">No products found for this category.</p>
        )}
      </div> */}













{/* Product Grid */}
<div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
  {products.length > 0 ? (
    products.map((product) => (
      <Link
        key={product.id}
        to={`/productdetails/${product._id}`}
        className="relative block bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden group transition-transform transform hover:scale-105"
      >
        <img
          src={`${serverBaseURL}${product.thumbnail_url}`}
          alt={product.name}
          className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
        />
        <div className="absolute inset-0 flex flex-col justify-end p-4 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <h3 className="text-white text-lg font-bold mb-2">{product.name}</h3>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition-colors duration-300">View Details</button>
        </div>
        <div className="p-4 text-center bg-white">
          <h3 className="text-lg font-semibold text-gray-800 mb-1">{product.name}</h3>
          {/* <p className="text-gray-600 mb-2">${product.price}</p> */}
          <button className="mt-2 bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition-colors duration-300">View Details</button>
        </div>
      </Link>
    ))
  ) : (
    <p className="text-center text-gray-600">No products found for this category.</p>
  )}
</div>











        </>
      )}
    </div>
    </div>
   
    </>

   
  );
};

export default DryFruit;
