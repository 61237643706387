import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;

const CourseServiceMain = createApi({
    reducerPath: 'CourseServiceMain',
    tagTypes: 'coursesmain',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api/',
        baseUrl: `${apiUrl}/api/`,     
        // prepareHeaders: (headers, {getState}) => {
        //     const reducers = getState();
        //     const token = reducers?.authReducer?.instructorToken;
        //     console.log(token)
        //     headers.set('authorization', token ? `Bearer ${token}` : '');
        //     return headers;
        // }
    }),
    endpoints: (builder) => {
        return {
          

            getMainCourses: builder.query({
                query: () => {
                 return {
                     url: `/maincourses`,
                     method: 'GET'
                 }
                },
                providesTags: ['coursesmain']
            }),

            getCourseById: builder.query({
                query: (id) => `/singlecourse/${id}`,
            }),



            // video componennt 
            getVideosByCourseId: builder.query({
                query: (courseId) => `/singlevideo/${courseId}`,
              }),

              getVideoById: builder.query({
                query: ({ courseid, videoid, userid }) => 
                  `/singlevideos/${courseid}/${videoid}?userId=${userid}`,
              }),


    
        }
    }
})
export const {useGetMainCoursesQuery, useGetCourseByIdQuery ,useGetVideosByCourseIdQuery, useGetVideoByIdQuery} = CourseServiceMain;
export default CourseServiceMain