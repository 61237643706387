

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { clearMessage } from "../../store/reducers/globalReducer";
import WrapperTeacher from "./WrapperTeacher";
import {
  useGetProductsAllcoffeeQuery,
  useDeleteProductMutation,
  useDeleteCoffeeProductMutation
} from "../../store/services/productServiceTeacher";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

const apiUrl = process.env.REACT_APP_API_URL_TESTINGIMAGE;



const DabindianOilSeeds = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  let { page } = useParams();
  if (!page) {
    page = 1;
  }

  const teacherId = localStorage.getItem("instructor-id");

  const { data = [], isFetching } = useGetProductsAllcoffeeQuery({
    page,
    teacherId,
  });
  console.log("data",data)

  const serverBaseURL = apiUrl;

  const { success } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      toast.success(success);
    }
    return () => {
      dispatch(clearMessage());
    };
  }, [success, dispatch]);

  const [delProduct, response] = useDeleteCoffeeProductMutation();

  const deleteProduct = (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      delProduct(id);
    }
  };

  const showMoreDetails = (product) => {
    setSelectedProduct(product);
  };

  const closePopup = () => {
    setSelectedProduct(null);
  };

  return (
    <WrapperTeacher>
      <ScreenHeaderTeacher>
        <Link to="/dashboard/create/indianoilseeds" className="btn-dark  bg-blue-500 hover:bg-red-500">
          Create Indian Oil SeedsProduct
        </Link>
        <Toaster position="top-right" />
      </ScreenHeaderTeacher>

      {!isFetching ? (
        data?.products?.length > 0 ? (
          <div>
            <table className="w-full bg-gray-900 rounded-md">
              <thead>
                <tr className="border-b border-gray-800 text-left">
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Name
                  </th>
               
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Category
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Image
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Edit
                  </th>
                 
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Delete
                  </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    More
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.products?.map((product) => (
                  <tr className="odd:bg-gray-800" key={product._id}>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      {product.name}
                    </td>
                 
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      {product.coffeeType}
                    </td>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <img
                        src={`${serverBaseURL}${product.thumbnail_url}`}
                        alt="Product"
                        className="w-20 h-20 rounded-md object-cover"
                      />
                    </td>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <Link
                        to={`/dashboard/edit/indianoilseeds/${product._id}`}
                        className="btn btn-warning"
                      >
                        Edit
                      </Link>
                    </td>
           
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <span
                        className="btn btn-danger cursor-pointer"
                        onClick={() => deleteProduct(product._id)}
                      >
                        Delete
                      </span>
                    </td>
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <button
                        className="btn btn-info cursor-pointer bg-green-500 p-2"
                        onClick={() => showMoreDetails(product)}
                      >
                        More Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              page={parseInt(page)}
              perPage={data.perPage}
              count={data.count}
              path="dashboard/teacher"
            />
          </div>
        ) : (
          "No products!"
        )
      ) : (
        <Spinner />
      )}

      {/* Popup for more details */}
      {selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-yellow-300 text-black p-6 rounded-md shadow-md w-11/12 md:w-2/3 lg:w-1/2">
            <h2 className="text-lg font-bold mb-4">{selectedProduct.name}</h2>
            <p>
              <strong>Black Seeds:</strong> {selectedProduct.blackSeeds || "N/A"}
            </p>
            <p>
              <strong>Coffee Type:</strong> {selectedProduct.coffeeType || "N/A"}
            </p>
            <p>
              <strong>Grade:</strong> {selectedProduct.grade || "N/A"}
            </p>
            <p>
              <strong>Moisture:</strong> {selectedProduct.moisture || "N/A"}
            </p>
            <p>
              <strong>New Crop Months:</strong>{" "}
              {selectedProduct.newCropMonths?.join(", ") || "N/A"}
            </p>
            <p>
              <strong>Packaging:</strong> {selectedProduct.packaging || "N/A"}
            </p>
            <p>
              <strong>PB:</strong> {selectedProduct.pb || "N/A"}
            </p>
            <p>
              <strong>PCode:</strong> {selectedProduct.pcode || "N/A"}
            </p>
            <p>
              <strong>Screen:</strong> {selectedProduct.screen || "N/A"}
            </p>
            <p>
              <strong>Sieve:</strong> {selectedProduct.sieve || "N/A"}
            </p>
            <p>
              <strong>Container Capacity (20-foot):</strong>{" "}
              {selectedProduct.containerCapacity?.twentyFoot || "N/A"}
            </p>
            <p>
              <strong>Container Capacity (40-foot):</strong>{" "}
              {selectedProduct.containerCapacity?.fortyFoot || "N/A"}
            </p>
            <p>
              <strong>Triage:</strong> {selectedProduct.triage || "N/A"}
            </p>
            <button
              className="btn btn-danger mt-4"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </WrapperTeacher>
  );
};

export default DabindianOilSeeds;












