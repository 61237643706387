// import { useParams, useNavigate } from "react-router-dom";
// import { useState } from "react";
// import currency from "currency-formatter";
// import { MdOutlineKeyboardBackspace } from "react-icons/md";
// import moment from "moment";
// import Nav from "../../components/home/Nav";
// import Header from "../../components/home/Header";
// import AccountList from "../../components/home/AccountList";
// import { useDetailsQuery } from "../../store/services/userOrdersService";
// import Spinner from "../../components/Spinner";
// import { discount } from "../../utils/discount";
// // import ReviewForm from "../../components/ReviewForm";
// // import DetailsList from "../../components/DetailsList";
// const UserOrderDetails = () => {
//   const [state, setState] = useState(false);
//   const toggleReview = () => {
//     setState(!state);
//   };
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { data, isFetching } = useDetailsQuery(id);
//   console.log("orders: ", data);
//   const total = currency.format(
//     discount(
//       data?.details?.productId?.price,
//       data?.details?.productId?.discount
//     ) * data?.details?.quantities,
//     {
//       code: "USD",
//     }
//   );

//   return (
//     <>
//       <Nav />
//       <div className="mt-[70px]">
//         <Header>order details</Header>
//         <div className="my-container mt-[40px]">
//           <div className="flex flex-wrap -mx-6">
//             <div className="w-full md:w-4/12 p-6">
//               <AccountList />
//             </div>
//             <div className="w-full md:w-8/12 p-6">
//               <h1 className="heading flex items-center">
//                 {" "}
//                 <MdOutlineKeyboardBackspace
//                   className="cursor-pointer text-gray-500"
//                   onClick={() => navigate(-1)}
//                 />{" "}
//                 <span className="ml-5">details</span>
//               </h1>
//               {!isFetching ? (
//                 <div className="flex flex-col md:flex-row flex-wrap my-5">
//                   <div className="w-[130px] md:w-[160px] h-[130px] md:h-[160px] overflow-hidden">
//                     <img
//                       src={`/images/${data?.details?.productId?.image1}`}
//                       alt=""
//                       className="w-full h-full object-cover rounded-md"
//                     />
//                   </div>
//                   <div className="flex-1 my-4 md:my-0 md:ml-4">

                
//                   </div>
//                 </div>
//               ) : (
//                 <Spinner />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserOrderDetails;



// import { useParams, Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import currency from "currency-formatter";
// import Nav from "../../components/home/Nav";
// import Header from "../../components/home/Header";
// import AccountList from "../../components/home/AccountList";
// import Spinner from "../../components/Spinner";
// import {
//   useGetOrdersQuery,
//   useReceivedOrderMutation,
// } from "../../store/services/userOrdersService";
// import { discount } from "../../utils/discount";
// import Pagination from "../../components/Pagination";
// const UserOrders = () => {

//   const userDataString = localStorage.getItem('user');
//   const userData = userDataString ? JSON.parse(userDataString) : null;

//   const userid = userData ? userData._id : null; // Get userId from localStorage

//   const { data, error, isLoading ,isFetching} = useGetPaidCoursesDetailsQuery(userid);


//   let { page } = useParams();
//   page = page ? page : 1;
//   const { user } = useSelector((state) => state.authReducer);
//   // const { data, isFetching } = useGetOrdersQuery({ page, userId: user.id });
  
//   const [updateOrder, response] = useReceivedOrderMutation();
//   const orderReceived = (id) => {
//     updateOrder(id);
//   };
//   return (
//     <>
//       <Nav />
//       <div className="mt-[70px]">
//         <Header>my orders</Header>
//         <div className="my-container mt-[40px]">
//           <div className="flex flex-wrap -mx-6">
//             <div className="w-full md:w-4/12 p-6">
//               <AccountList />
//             </div>
//             <div className="w-full md:w-8/12 p-6">
//               <h1 className="heading mb-6">orders</h1>
//               {!isFetching ? (
//                 data?.paidCourses?.length > 0 ? (
//                   <>
//                     <div className="table-container">
//                       <table className="w-full">
//                         <thead>
//                           <tr className="thead-tr">
//                             <th className="th">image</th>
//                             <th className="th">name</th>
//                             <th className="th">total</th>
//                             <th className="th">details</th>
//                             <th className="th">received</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {data.paidCourses.map((item) => {
                        
//                             return (
//                               <tr className="even:bg-gray-50" key={item._id}>
//                                 <td className="td">
//                                   <img
//                                     src={`/images/${item.productId.image1}`}
//                                     alt={item.productId.title}
//                                     className="w-12 h-12 object-cover rounded-full"
//                                   />
//                                 </td>
//                                 <td className=" td font-medium">
//                                   {item.productId.title}
//                                 </td>
//                                 <td className="td font-bold ">{total}</td>
//                                 <td className="td">
//                                   <Link
//                                     to={`/user-order-details/${item._id}`}
//                                     className="btn btn-indigo"
//                                   >
//                                     details
//                                   </Link>
//                                 </td>
//                                 <td className="td">
//                                   {item.status ? (
//                                     item.received ? (
//                                       <span className="capitalize font-medium text-emerald-600">
//                                         received
//                                       </span>
//                                     ) : (
//                                       <button
//                                         className="btn btn-indigo"
//                                         onClick={() => orderReceived(item._id)}
//                                       >
//                                         received?
//                                       </button>
//                                     )
//                                   ) : (
//                                     <span className="capitalize font-medium text-rose-600">
//                                       under process
//                                     </span>
//                                   )}
//                                 </td>
//                               </tr>
//                             );
//                           })}
//                         </tbody>
//                       </table>
//                     </div>
//                     <Pagination
//                       page={parseInt(page)}
//                       perPage={data.perPage}
//                       count={data.count}
//                       path={`orders`}
//                       theme="light"
//                     />
//                   </>
//                 ) : (
//                   <div className="bg-indigo-50 border border-indigo-100 rounded px-4 py-2.5 capitalize text-indigo-900 text-sm font-medium">
//                     no orders
//                   </div>
//                 )
//               ) : (
//                 <Spinner />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserOrders;




import Nav from "../../components/home/Nav";
import Header from "../../components/home/Header";
import AccountList from "../../components/home/AccountList";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { useGetPaidCoursesDetailsQuery } from "../../store/services/StudentService"; // Adjust import as necessary
import HomeNav from "../../components/CommonCompo/HomeNav";

const UserOrders = () => {
    const userDataString = localStorage.getItem('user');
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const userId = userData ? userData._id : null; // Get userId from localStorage

    const { data, isFetching } = useGetPaidCoursesDetailsQuery(userId);
console.log("data",data)
    let { page } = useParams();
    page = page ? page : 1;

    return (
        <>
            <Nav  />
            <div className="mt-[70px]">
            <HomeNav className="mt-[70px]"/>

                <Header>My Courses</Header>
                <div className="my-container mt-[40px]">
                    <div className="flex flex-wrap -mx-6">
                        <div className="w-full md:w-4/12 p-6">
                            <AccountList />
                        </div>
                        <div className="w-full md:w-8/12 p-6">
                            <h1 className="heading mb-6">Paid Courses</h1>
                            {!isFetching ? (
                                data?.paidCourses?.length > 0 ? (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                        {data.paidCourses.map((course) => (
                                            <Link to={`/coursesmainsingle/${course.courseId}`} key={course.courseId} className="block">
                                                <div className="p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300">
                                                    <img
                                                        src={course.courseDetails.thumbnail_url}
                                                        alt={course.courseDetails.title}
                                                        className="w-full h-48 object-cover rounded-t-lg"
                                                    />
                                                    <div className="p-4">
                                                        <h3 className="text-xl font-semibold mb-2">{course.courseDetails.title}</h3>
                                                        <p className="text-gray-600 mt-2">Category: {course.courseDetails.category}</p>
                                                        <p className="text-gray-600">Price: ${course.courseDetails.price}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="bg-indigo-50 border border-indigo-100 rounded px-4 py-2.5 capitalize text-indigo-900 text-sm font-medium">
                                        No paid courses found
                                    </div>
                                )
                            ) : (
                                <Spinner />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserOrders;

