
import React from 'react';
import { useGetProductForCoffeeForEditQuery } from '../../store/services/productServiceTeacher';
import { useParams } from 'react-router-dom';
import NavDubai from './NavDubai';


const apiUrl = process.env.REACT_APP_API_URL_TESTINGIMAGE;
// src={`${serverBaseURL}${product.thumbnail_url}`}
// const serverBaseURL = apiUrl;


// Skeleton Loader Component
const SkeletonLoader = () => (
  <div className="bg-gray-200 animate-pulse rounded shadow p-4">
    <div className="h-4 bg-gray-300 mb-4 rounded"></div>
    <div className="h-4 bg-gray-300 mb-4 rounded"></div>
    <div className="h-4 bg-gray-300 mb-4 rounded"></div>
  </div>
);

const CoffeeProductDetails = () => {
  const { id } = useParams();
  const serverBaseURL = apiUrl;

  const { data, error, isLoading } = useGetProductForCoffeeForEditQuery(id);
console.log("data",data)
  if (isLoading) {
    return (
      <div>
        {/* Top Section */}
        <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 mt-[96px] h-[210px] flex items-center justify-center text-white">
          <div className="flex items-center">
            <hr className="w-8 sm:w-16 border-white mr-2 sm:mr-4" />
            <div className="text-xl sm:text-3xl font-bold">Loading...</div>
            <hr className="w-8 sm:w-16 border-white ml-2 sm:ml-4" />
          </div>
        </div>

        {/* Product Specifications Section */}
        <div className="flex flex-col md:flex-row mt-8 px-4 md:px-16">
          <div className="md:w-1/2">
            <SkeletonLoader />
          </div>
          <div className="md:w-1/2 md:pl-8 mt-4 md:mt-0">
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </div>
        </div>

        {/* New Section for Container Capacity and New Crop Months */}
        <div className="mt-8 px-4 md:px-16">
          <SkeletonLoader />
          <SkeletonLoader />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-8">
        <p className="text-red-500">Failed to load data</p>
      </div>
    );
  }

  // const {
  //   name,
  //   grade,
  //   sieve,
  //   screen,
  //   pb,
  //   triage,
  //   blackSeeds,
  //   moisture,
  //   packaging,
  //   containerCapacity = {},
  //   newCropMonths = [],
  //   thumbnail_url = ''
  // } = data || {};


  const {
    name,
    grade,
    sieve,
    screen,
    pb,
    triage,
    blackSeeds,
    moisture,
    packaging,
    containerCapacity = {},
    newCropMonths = [],
    thumbnail_url = '',
    category,
    image1,
    instructorId,
    
    // Coffee-specific fields
    coffeeType,
    pcode,

    
    // Packaging
    ppBags,
    paperBags,
    nonWovenBags,
    boppBags,
    vacuumBags,
    juteBags,
    pouches,
    
    // Additional fields specific to coffee
 
    
    // Product Specifications
    purity,
    admixture,
    oilContent,
    ffa,
    darkSeeds,
    split,
    unblanch,
    damaged,
    redNose,
    overRoasted,
    aflatoxin,
    color,
    count,
    averageLength,
    cooking,
    broken,
    kettRatio,
    moistureContent,
    foreignMatter,
    damagedDiscoloured,
    blackGrains,
    smell,
    process,
    ash,
    granulation,
    extraneousMatter,
    totalAsh,
    acidInsolubleAsh,
    volatileOil,
    ltr,
    lightBerries,
    nonVolatileEtherExtract,
    
    // Additional Product Specifications
    size,
    totalDefected,
    undersizeSeeds,
    brokenKernels,
    discolouredKernels,
    stones,
    starch,
    appearance,
    seeds,
    totalTartaricAcid,
    residualSO2,
    leadPb,
    shelfLife,
    
    // Nutritional Information
    energy,
    fat,
    protein,
    carbohydrates,
    crudeFiber,
    calcium,
    iron,
    thiamin,
    riboflavin,
    niacin,

    
                // new here 
        
                shape,
                colouruniformity,
                capstems,
                colourcontamination,
                sticks,
                cartons,
                colour,
                nlsglng,
                pieces,
                taste,
                texture,
                mouldInsects,
                quarters,
                halves,
                caffeinehplc,
                bulkdensity,
                ph,
                colorvalue,
                bottles,
                hdpebags,
                refillpack,
                blend,

                //new b here 

                pungency,
colorasta,
looseseeds,
blackorsplit,
shrivelled,
malformed,
ecoil,
salomonella,
                
} = data || {};

  return (
    <div>
<NavDubai />
      {/* Top Section */}
      <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 mt-[96px] h-[210px] flex items-center justify-center text-white">
        <div className="flex items-center">
          <hr className="w-8 sm:w-16 border-white mr-2 sm:mr-4" />
          <div className="text-xl sm:text-3xl font-bold">{name}</div>
          <hr className="w-8 sm:w-16 border-white ml-2 sm:ml-4" />
        </div>
      </div>

      {/* Product Specifications Section */}
      <div className="flex flex-col md:flex-row mt-8 px-4 md:px-16 ">
        <div className="md:w-1/2">
          <img
          //  src={thumbnail_url} alt="Product"
           
           src={`${serverBaseURL}${thumbnail_url}`}

           
           className="w-full h-auto" />
        </div>
        <div className="md:w-1/2 md:pl-8 mt-4 md:mt-0">
          <div className="bg-white p-4 rounded shadow">
            <h2 className="text-2xl font-bold mb-4">Product Specifications</h2>
            <table className="table-auto w-full border-collapse border border-gray-300 ">
              <tbody>
              {data.grade && (
                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Grade</td>
                  <td className="py-2 border px-4">{grade}</td>
                </tr>
 )}


                {/* <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Sieve</td>
                  <td className="py-2 border px-4">{sieve}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Screen</td>
                  <td className="py-2 border px-4">{screen}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">PB</td>
                  <td className="py-2 border px-4">{pb}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Triage</td>
                  <td className="py-2 border px-4">{triage}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Black Seeds</td>
                  <td className="py-2 border px-4">{blackSeeds}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Moisture</td>
                  <td className="py-2 border px-4">{moisture}</td>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >AcidInsolubleAsh</td>
                  <td className="py-2 border px-4">{acidInsolubleAsh}</td>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >aflatoxin</td>
                  <td className="py-2 border px-4">{aflatoxin}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >appearance</td>
                  <td className="py-2 border px-4">{appearance}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >ash</td>
                  <td className="py-2 border px-4">{ash}</td>
                </tr> */}


{data.sieve && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Sieve</td>
    <td className="py-2 border px-4">{data.sieve}</td>
  </tr>
)}

{data.screen && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Screen</td>
    <td className="py-2 border px-4">{data.screen}</td>
  </tr>
)}

{data.pb && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">PB</td>
    <td className="py-2 border px-4">{data.pb}</td>
  </tr>
)}

{data.triage && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Triage</td>
    <td className="py-2 border px-4">{data.triage}</td>
  </tr>
)}

{data.blackSeeds && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Black Seeds</td>
    <td className="py-2 border px-4">{data.blackSeeds}</td>
  </tr>
)}

{data.moisture && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Moisture</td>
    <td className="py-2 border px-4">{data.moisture}</td>
  </tr>
)}

{data.acidInsolubleAsh && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Acid Insoluble Ash</td>
    <td className="py-2 border px-4">{data.acidInsolubleAsh}</td>
  </tr>
)}

{data.aflatoxin && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Aflatoxin</td>
    <td className="py-2 border px-4">{data.aflatoxin}</td>
  </tr>
)}

{data.appearance && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Appearance</td>
    <td className="py-2 border px-4">{data.appearance}</td>
  </tr>
)}

{data.ash && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Ash</td>
    <td className="py-2 border px-4">{data.ash}</td>
  </tr>
)}


















{/* 
                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >admixture</td>
                  <td className="py-2 border px-4">{admixture}</td>
                </tr>



                
                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >crudeFiber</td>
                  <td className="py-2 border px-4">{crudeFiber}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >broken</td>
                  <td className="py-2 border px-4">{broken}</td>
                </tr>

                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >damagedDiscoloured</td>
                  <td className="py-2 border px-4">{damagedDiscoloured}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >averageLength</td>
                  <td className="py-2 border px-4">{averageLength}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >blackGrains</td>
                  <td className="py-2 border px-4">{blackGrains}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >brokenKernels</td>
                  <td className="py-2 border px-4">{brokenKernels}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >calcium</td>
                  <td className="py-2 border px-4">{calcium}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >carbohydrates</td>
                  <td className="py-2 border px-4">{carbohydrates}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >color</td>
                  <td className="py-2 border px-4">{color}</td>
                </tr>
 */}




{data.admixture && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Admixture</td>
    <td className="py-2 border px-4">{data.admixture}</td>
  </tr>
)}

{data.crudeFiber && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Crude Fiber</td>
    <td className="py-2 border px-4">{data.crudeFiber}</td>
  </tr>
)}

{data.broken && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Broken</td>
    <td className="py-2 border px-4">{data.broken}</td>
  </tr>
)}

{data.damagedDiscoloured && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Damaged/Discoloured</td>
    <td className="py-2 border px-4">{data.damagedDiscoloured}</td>
  </tr>
)}

{data.averageLength && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Average Length</td>
    <td className="py-2 border px-4">{data.averageLength}</td>
  </tr>
)}

{data.blackGrains && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Black Grains</td>
    <td className="py-2 border px-4">{data.blackGrains}</td>
  </tr>
)}

{data.brokenKernels && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Broken Kernels</td>
    <td className="py-2 border px-4">{data.brokenKernels}</td>
  </tr>
)}

{data.calcium && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Calcium</td>
    <td className="py-2 border px-4">{data.calcium}</td>
  </tr>
)}

{data.carbohydrates && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Carbohydrates</td>
    <td className="py-2 border px-4">{data.carbohydrates}</td>
  </tr>
)}

{data.color && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Color</td>
    <td className="py-2 border px-4">{data.color}</td>
  </tr>
)}











{/* 
                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >cooking</td>
                  <td className="py-2 border px-4">{cooking}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >count</td>
                  <td className="py-2 border px-4">{count}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >crudeFiber</td>
                  <td className="py-2 border px-4">{aflatoxin}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >damaged</td>
                  <td className="py-2 border px-4">{damaged}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >aflatoxin</td>
                  <td className="py-2 border px-4">{aflatoxin}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >darkSeeds</td>
                  <td className="py-2 border px-4">{darkSeeds}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >discolouredKernels</td>
                  <td className="py-2 border px-4">{discolouredKernels}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >energy</td>
                  <td className="py-2 border px-4">{energy}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >extraneousMatter</td>
                  <td className="py-2 border px-4">{extraneousMatter}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >fat</td>
                  <td className="py-2 border px-4">{fat}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >ffa</td>
                  <td className="py-2 border px-4">{ffa}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >foreignMatter</td>
                  <td className="py-2 border px-4">{foreignMatter}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >granulation</td>
                  <td className="py-2 border px-4">{granulation}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >iron</td>
                  <td className="py-2 border px-4">{iron}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >juteBags</td>
                  <td className="py-2 border px-4">{juteBags}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >kettRatio</td>
                  <td className="py-2 border px-4">{kettRatio}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >leadPb</td>
                  <td className="py-2 border px-4">{leadPb}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >lightBerries</td>
                  <td className="py-2 border px-4">{lightBerries}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >ltr</td>
                  <td className="py-2 border px-4">{ltr}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >moisture</td>
                  <td className="py-2 border px-4">{moisture}</td>
                </tr>
 */}







{data.cooking && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Cooking</td>
    <td className="py-2 border px-4">{data.cooking}</td>
  </tr>
)}

{data.count && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Count</td>
    <td className="py-2 border px-4">{data.count}</td>
  </tr>
)}

{data.crudeFiber && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Crude Fiber</td>
    <td className="py-2 border px-4">{data.crudeFiber}</td>
  </tr>
)}

{data.damaged && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Damaged</td>
    <td className="py-2 border px-4">{data.damaged}</td>
  </tr>
)}

{data.aflatoxin && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Aflatoxin</td>
    <td className="py-2 border px-4">{data.aflatoxin}</td>
  </tr>
)}

{data.darkSeeds && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Dark Seeds</td>
    <td className="py-2 border px-4">{data.darkSeeds}</td>
  </tr>
)}

{data.discolouredKernels && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Discoloured Kernels</td>
    <td className="py-2 border px-4">{data.discolouredKernels}</td>
  </tr>
)}

{data.energy && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Energy</td>
    <td className="py-2 border px-4">{data.energy}</td>
  </tr>
)}

{data.extraneousMatter && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Extraneous Matter</td>
    <td className="py-2 border px-4">{data.extraneousMatter}</td>
  </tr>
)}

{data.fat && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Fat</td>
    <td className="py-2 border px-4">{data.fat}</td>
  </tr>
)}

{data.ffa && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">FFA</td>
    <td className="py-2 border px-4">{data.ffa}</td>
  </tr>
)}

{data.foreignMatter && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Foreign Matter</td>
    <td className="py-2 border px-4">{data.foreignMatter}</td>
  </tr>
)}

{data.granulation && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Granulation</td>
    <td className="py-2 border px-4">{data.granulation}</td>
  </tr>
)}

{data.iron && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Iron</td>
    <td className="py-2 border px-4">{data.iron}</td>
  </tr>
)}

{data.juteBags && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Jute Bags</td>
    <td className="py-2 border px-4">{data.juteBags}</td>
  </tr>
)}

{data.kettRatio && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Kett Ratio</td>
    <td className="py-2 border px-4">{data.kettRatio}</td>
  </tr>
)}

{data.leadPb && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Lead Pb</td>
    <td className="py-2 border px-4">{data.leadPb}</td>
  </tr>
)}

{data.lightBerries && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Light Berries</td>
    <td className="py-2 border px-4">{data.lightBerries}</td>
  </tr>
)}

{data.ltr && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">LTR</td>
    <td className="py-2 border px-4">{data.ltr}</td>
  </tr>
)}

{data.moisture && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Moisture</td>
    <td className="py-2 border px-4">{data.moisture}</td>
  </tr>
)}





                {/* <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >moistureContent</td>
                  <td className="py-2 border px-4">{moistureContent}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >niacin</td>
                  <td className="py-2 border px-4">{niacin}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >nonVolatileEtherExtract</td>
                  <td className="py-2 border px-4">{nonVolatileEtherExtract}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >oilContent</td>
                  <td className="py-2 border px-4">{oilContent}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >overRoasted</td>
                  <td className="py-2 border px-4">{overRoasted}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >pb</td>
                  <td className="py-2 border px-4">{pb}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >process</td>
                  <td className="py-2 border px-4">{process}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >protein</td>
                  <td className="py-2 border px-4">{protein}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >purity</td>
                  <td className="py-2 border px-4">{purity}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >redNose</td>
                  <td className="py-2 border px-4">{redNose}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >residualSO2</td>
                  <td className="py-2 border px-4">{residualSO2}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >riboflavin</td>
                  <td className="py-2 border px-4">{riboflavin}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >seeds</td>
                  <td className="py-2 border px-4">{seeds}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >shelfLife</td>
                  <td className="py-2 border px-4">{shelfLife}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >sieve</td>
                  <td className="py-2 border px-4">{sieve}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >size</td>
                  <td className="py-2 border px-4">{size}</td>
                </tr>






                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >smell</td>
                  <td className="py-2 border px-4">{smell}</td>
                </tr>






                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >split</td>
                  <td className="py-2 border px-4">{split}</td>
                </tr>






                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >starch</td>
                  <td className="py-2 border px-4">{starch}</td>
                </tr>







                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >stones</td>
                  <td className="py-2 border px-4">{stones}</td>
                </tr>







                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >thiamin</td>
                  <td className="py-2 border px-4">{thiamin}</td>
                </tr>







                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >totalAsh</td>
                  <td className="py-2 border px-4">{totalAsh}</td>
                </tr>






                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >totalDefected</td>
                  <td className="py-2 border px-4">{totalDefected}</td>
                </tr>






                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >totalTartaricAcid</td>
                  <td className="py-2 border px-4">{totalTartaricAcid}</td>
                </tr>





                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >unblanch</td>
                  <td className="py-2 border px-4">{unblanch}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >undersizeSeeds</td>
                  <td className="py-2 border px-4">{undersizeSeeds}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4 " >volatileOil</td>
                  <td className="py-2 border px-4">{volatileOil}</td>
                </tr> */}


{data.moistureContent && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Moisture Content</td>
    <td className="py-2 border px-4">{data.moistureContent}</td>
  </tr>
)}

{data.niacin && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Niacin</td>
    <td className="py-2 border px-4">{data.niacin}</td>
  </tr>
)}

{data.nonVolatileEtherExtract && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Non-Volatile Ether Extract</td>
    <td className="py-2 border px-4">{data.nonVolatileEtherExtract}</td>
  </tr>
)}

{data.oilContent && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Oil Content</td>
    <td className="py-2 border px-4">{data.oilContent}</td>
  </tr>
)}

{data.overRoasted && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Over Roasted</td>
    <td className="py-2 border px-4">{data.overRoasted}</td>
  </tr>
)}

{data.pb && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">PB</td>
    <td className="py-2 border px-4">{data.pb}</td>
  </tr>
)}

{data.process && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Process</td>
    <td className="py-2 border px-4">{data.process}</td>
  </tr>
)}

{data.protein && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Protein</td>
    <td className="py-2 border px-4">{data.protein}</td>
  </tr>
)}

{data.purity && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Purity</td>
    <td className="py-2 border px-4">{data.purity}</td>
  </tr>
)}

{data.redNose && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Red Nose</td>
    <td className="py-2 border px-4">{data.redNose}</td>
  </tr>
)}

{data.residualSO2 && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Residual SO2</td>
    <td className="py-2 border px-4">{data.residualSO2}</td>
  </tr>
)}

{data.riboflavin && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Riboflavin</td>
    <td className="py-2 border px-4">{data.riboflavin}</td>
  </tr>
)}

{data.seeds && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Seeds</td>
    <td className="py-2 border px-4">{data.seeds}</td>
  </tr>
)}

{data.shelfLife && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Shelf Life</td>
    <td className="py-2 border px-4">{data.shelfLife}</td>
  </tr>
)}

{data.sieve && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Sieve</td>
    <td className="py-2 border px-4">{data.sieve}</td>
  </tr>
)}

{data.size && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Size</td>
    <td className="py-2 border px-4">{data.size}</td>
  </tr>
)}

{data.smell && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Smell</td>
    <td className="py-2 border px-4">{data.smell}</td>
  </tr>
)}

{data.split && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Split</td>
    <td className="py-2 border px-4">{data.split}</td>
  </tr>
)}

{data.starch && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Starch</td>
    <td className="py-2 border px-4">{data.starch}</td>
  </tr>
)}

{data.stones && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Stones</td>
    <td className="py-2 border px-4">{data.stones}</td>
  </tr>
)}

{data.thiamin && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Thiamin</td>
    <td className="py-2 border px-4">{data.thiamin}</td>
  </tr>
)}

{data.totalAsh && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Total Ash</td>
    <td className="py-2 border px-4">{data.totalAsh}</td>
  </tr>
)}

{data.totalDefected && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Total Defected</td>
    <td className="py-2 border px-4">{data.totalDefected}</td>
  </tr>
)}

{data.totalTartaricAcid && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Total Tartaric Acid</td>
    <td className="py-2 border px-4">{data.totalTartaricAcid}</td>
  </tr>
)}

{data.unblanch && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Unblanch</td>
    <td className="py-2 border px-4">{data.unblanch}</td>
  </tr>
)}

{data.undersizeSeeds && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Undersize Seeds</td>
    <td className="py-2 border px-4">{data.undersizeSeeds}</td>
  </tr>
)}

{data.volatileOil && (
  <tr className="border-b border-gray-300">
    <td className="py-2 font-semibold border px-4">Volatile Oil</td>
    <td className="py-2 border px-4">{data.volatileOil}</td>
  </tr>
)}


{data.shape && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Shape</td>
        <td className="py-2 border px-4">{data.shape}</td>
      </tr>
    )}
    {data.colouruniformity && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Colour Uniformity</td>
        <td className="py-2 border px-4">{data.colouruniformity}</td>
      </tr>
    )}
    {data.capstems && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Cap Stems</td>
        <td className="py-2 border px-4">{data.capstems}</td>
      </tr>
    )}
    {data.colourcontamination && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Colour Contamination</td>
        <td className="py-2 border px-4">{data.colourcontamination}</td>
      </tr>
    )}
    {data.sticks && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Sticks</td>
        <td className="py-2 border px-4">{data.sticks}</td>
      </tr>
    )}
    {data.colour && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Colour</td>
        <td className="py-2 border px-4">{data.colour}</td>
      </tr>
    )}
    {data.nlsglng && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">NLS/GLNG</td>
        <td className="py-2 border px-4">{data.nlsglng}</td>
      </tr>
    )}
    {data.pieces && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Pieces</td>
        <td className="py-2 border px-4">{data.pieces}</td>
      </tr>
    )}
    {data.taste && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Taste</td>
        <td className="py-2 border px-4">{data.taste}</td>
      </tr>
    )}
    {data.texture && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Texture</td>
        <td className="py-2 border px-4">{data.texture}</td>
      </tr>
    )}
    {data.mouldInsects && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Mould/Insects</td>
        <td className="py-2 border px-4">{data.mouldInsects}</td>
      </tr>
    )}
    {data.quarters && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Quarters</td>
        <td className="py-2 border px-4">{data.quarters}</td>
      </tr>
    )}
    {data.halves && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Halves</td>
        <td className="py-2 border px-4">{data.halves}</td>
      </tr>
    )}
    {data.caffeinehplc && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Caffeine HPLC</td>
        <td className="py-2 border px-4">{data.caffeinehplc}</td>
      </tr>
    )}
    {data.bulkdensity && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Bulk Density</td>
        <td className="py-2 border px-4">{data.bulkdensity}</td>
      </tr>
    )}
    {data.ph && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">pH</td>
        <td className="py-2 border px-4">{data.ph}</td>
      </tr>
    )}
    {data.colorvalue && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Color Value</td>
        <td className="py-2 border px-4">{data.colorvalue}</td>
      </tr>
    )}
    {data.blend && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Blend</td>
        <td className="py-2 border px-4">{data.blend}</td>
      </tr>
    )}

{/* //new b here  */}

{data.pungency && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Pungency</td>
        <td className="py-2 border px-4">{data.pungency}</td>
      </tr>
    )}


{data.colorasta && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Color ASTA</td>
        <td className="py-2 border px-4">{data.colorasta}</td>
      </tr>
    )}




{data.looseseeds && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Loose Seeds</td>
        <td className="py-2 border px-4">{data.looseseeds}</td>
      </tr>
    )}




{data.blackorsplit && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Black or Split</td>
        <td className="py-2 border px-4">{data.blackorsplit}</td>
      </tr>
    )}




{data.shrivelled && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Shrivelled</td>
        <td className="py-2 border px-4">{data.shrivelled}</td>
      </tr>
    )}




{data.malformed && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Malformed</td>
        <td className="py-2 border px-4">{data.malformed}</td>
      </tr>
    )}


{data.ecoil && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">E Coil</td>
        <td className="py-2 border px-4">{data.ecoil}</td>
      </tr>
    )}



{data.salomonella && (
      <tr className="border-b border-gray-300">
        <td className="py-2 font-semibold border px-4">Salomonella</td>
        <td className="py-2 border px-4">{data.salomonella}</td>
      </tr>
    )}
  



              </tbody>
            </table>
          </div>

          <div className="bg-white p-4 rounded shadow mt-6">
          <h2 className="text-2xl font-bold mb-4">Container Available</h2>
          <table className="table-auto w-full border-collapse border border-gray-300">
              <tbody>


              <tr className="border-b border-gray-300 bg-green-400">
                  <td className="py-2 font-semibold border px-4">  Container Available On Customer Demands</td>
                  {/* <td className="py-2 border px-4">              Container Available On Customer Demands
                  </td> */}
                </tr> 


               {/* <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">Packaging</td>
                  <td className="py-2 border px-4">{packaging}</td>
                </tr> 




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">boppBags</td>
                  <td className="py-2 border px-4">{boppBags}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">juteBags</td>
                  <td className="py-2 border px-4">{juteBags}</td>
                </tr>



                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">nonWovenBags</td>
                  <td className="py-2 border px-4">{nonWovenBags}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">paperBags</td>
                  <td className="py-2 border px-4">{paperBags}</td>
                </tr>




                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">ppBags</td>
                  <td className="py-2 border px-4">{ppBags}</td>
                </tr>


                <tr className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">pouches</td>
                  <td className="py-2 border px-4">{pouches}</td>
                </tr> */}



            
      {data.packaging && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Packaging</td>
          <td className="py-2 border px-4">{packaging}</td>
        </tr>
      )}
      
      {data.boppBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Bopp Bags</td>
          <td className="py-2 border px-4">{boppBags}</td>
        </tr>
      )}

      {data.juteBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Jute Bags</td>
          <td className="py-2 border px-4">{juteBags}</td>
        </tr>
      )}

      {data.nonWovenBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Non-Woven Bags</td>
          <td className="py-2 border px-4">{nonWovenBags}</td>
        </tr>
      )}

      {data.paperBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Paper Bags</td>
          <td className="py-2 border px-4">{paperBags}</td>
        </tr>
      )}

      {data.ppBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">PP Bags</td>
          <td className="py-2 border px-4">{ppBags}</td>
        </tr>
      )}

      {data.pouches && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Pouches</td>
          <td className="py-2 border px-4">{pouches}</td>
        </tr>
      )}


{data.vacuumBags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Vacuum Bags</td>
          <td className="py-2 border px-4">{vacuumBags}</td>
        </tr>
      )}


{data.refillpack && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">Refill Pack </td>
          <td className="py-2 border px-4">{refillpack}</td>
        </tr>
      )}


      
{data.hdpebags && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4">HDPE Bags  </td>
          <td className="py-2 border px-4">{hdpebags}</td>
        </tr>
      )}


{data.bottles && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4"> Bottles  </td>
          <td className="py-2 border px-4">{bottles}</td>
        </tr>
      )}


{data.cartons && (
        <tr className="border-b border-gray-300">
          <td className="py-2 font-semibold border px-4"> Cartons  </td>
          <td className="py-2 border px-4">{cartons}</td>
        </tr>
      )}






              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* New Section for Container Capacity and New Crop Months */}
      <div className="mt-8 px-4 md:px-16">
        <div className="bg-white p-4 rounded shadow mb-6">
          <h2 className="text-2xl font-bold mb-4">Container Capacity</h2>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <tbody>
              {Object.entries(containerCapacity).map(([key, value], index) => (
                <tr key={index} className="border-b border-gray-300">
                  <td className="py-2 font-semibold border px-4">{key.replace(/([A-Z])/g, ' $1').toUpperCase()}</td>
                  <td className="py-2 border px-4">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-white p-4 rounded shadow">
          <h2 className="text-2xl font-bold mb-4">New Crop Months</h2>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <tbody>
              <tr className="border-b border-gray-300">
                <td className="py-2 font-semibold border px-4">New Crop Months</td>
                <td className="py-2 border px-4">{newCropMonths.join(', ')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CoffeeProductDetails;







