import Routing from "./routes/Routing"
import './i18n'; // Import the i18n configuration
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function App() {
  return (
   <Routing />
  );
}
export default App;
