import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import HomeNav from "./HomeNav";
import { useGetPackagesQuery } from '../../store/services/SupService'; // Adjust import path
import Footer from './FooterLms';

// import Footer from './Footer';

const FirstBac = () => {
  const { t } = useTranslation();

  const { data: packages, error, isLoading } = useGetPackagesQuery();
  console.log("packages",packages)
  return (
    <>
    <div className="flex flex-col min-h-[80vh] h-auto  bg-gray-200">
      <HomeNav />
      <section className="flex-grow  mt-[60px]">
        <div className="container mx-auto px-5 py-5">
          <div className="flex flex-col gap-5">
            {/* First Div with Link */}
            <Link to="/" className="bg-yellow-300 p-5 block">
              <h2 className="text-3xl font-bold text-center mb-5">
                {/* 2 Bac sm packs */}
                
                {t('bac-first-a')}
                </h2>
              {/* Additional content for the first div */}
            </Link>
            {/* Second Div with Link */}
            <Link to="/" className="bg-yellow-300 p-5 block">
              <h2 className="text-3xl font-bold text-center mb-5">
                
                {/* Another Course Pack */}
                
                {t('bac-first-b')}
                </h2>
              {/* Additional content for the second div */}
            </Link>
          </div>
        </div>
      </section>
      {/* <Footer /> */}

    </div>
    <Footer/>
    </>
    
  );
};

export default FirstBac;
