import {useEffect} from "react"
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import WrapperTeacher from "./WrapperTeacher"
// import { useGetProductsQuery, useDeleteProductMutation } from "../../store/services/productService";
import { useGetProductsQuery, useDeleteProductMutation,useGetallCatagoryMainQuery } from "../../store/services/productServiceTeacher";
import ScreenHeader from "../../components/ScreenHeader";
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import html2plaintext from 'html2plaintext';


const apiUrl = process.env.REACT_APP_API_URL_TESTINGIMAGE;


const TeacherPanel = () => {
   const { t } = useTranslation();

   let {page} = useParams();
   if(!page) {
      page = 1;
   }

   const teacherId = localStorage.getItem('instructor-id'); // Assuming you store the teacher ID in local storage

   const {data = [], isFetching} =useGetallCatagoryMainQuery({ page });
   // const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed
   const serverBaseURL = apiUrl; // Change this as needed

   console.log(data);
   const {success} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    useEffect(() => {
     if(success) {
       toast.success(success);
     }
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    const [delProduct, response] = useDeleteProductMutation();
    
    const deleteProduct = id => {
      if(window.confirm("Are you really want to delete this product?")) {
          delProduct(id);
      }
    }
    return(
       <WrapperTeacher >
         <ScreenHeaderTeacher>
          {/* <Link to="/dashboard/create-product" className="btn-dark">create Course</Link> */}
          <Link to="/dashboard/teacher/create-category" className="p-2 bg-blue-500 rounded hover:bg-red-500">

           Add Ctagory

          </Link>
          <Toaster position="top-right" />
          </ScreenHeaderTeacher>
          {!isFetching ? data?.products?.length > 0 ? <div >
            <table className="w-full bg-gray-900 rounded-md ">
            <thead>
                    <tr className="border-b border-gray-800 text-left">
                    <th className="p-3 uppercase text-sm font-medium text-gray-500">
                     Name
                     </th>
                                
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 Decprition
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Image */}
                                 {t('dashboard-teacher-image')}
                                 </th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 {/* Edit */}
                                 {t('dashboard-teacher-edit')}
                                 </th>
                                {/* <th className="p-3 uppercase text-sm font-medium text-gray-500">
                                 Delete
                                 </th> */}
                    </tr>
                 </thead>
                 <tbody>
                  {data?.products?.map(product => (
                     <tr className="odd:bg-gray-800" key={product._id}>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.name}</td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{ html2plaintext(product.description)}</td>
                      
                           <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        <img src={`${serverBaseURL}${product.thumbnail_url}`} alt="Product" className="w-20 h-20 rounded-md object-cover" />
                                     </td>
                                     <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/teacher/update-category/${product._id}`} className="btn btn-warning">
                                     {/* edit */}
                                     {t('dashboard-teacher-edit-product')}
                                     
                                     </Link>
                                     </td>
                                     {/* <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>
                                       delete
                                       </span>
                                       </td> */}
                     </tr>
                  ))}
                 </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/teacher/tcategories" />
          </div> : 'No products!' : <Spinner />}
       </WrapperTeacher>
    )
}
export default TeacherPanel;











