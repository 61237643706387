import { NavLink } from "react-router-dom";
import Heada from "./Heada";
import Headb from "./Headb";
import Headc from "./Headc";
import Headd from "./Headd";
import Heade from "./Heade";
import Headg from "./Headg";
import Headh from "./Headh";
import Headi from "./Headi";
import HomeNav from "./HomeNav";
import FooterLms from "./FooterLms";
import Headf from "./Headf";

import WhatsAppButton from './WhatsAppButto'; // Adjust the path if needed
import NavDubai from "./NavDubai";


const HomeComon = () => {

  return (
    <>

<HomeNav/>

<NavDubai />

<Heada/>
<Headb />
      <Headc />
      <Headd />
      {/* <Headf/> */}



      {/* <Headb />
      <Headc />
      <Headd />
      <Heade />
      <Headi />
      <Headg />
      <Headh /> */}
         {/* WhatsApp Button */}
         <WhatsAppButton />
<FooterLms />
    </>
  );
};
export default HomeComon;
