// components/AdminPaymentMethods.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetPaymentMethodsQuery,
  useCreatePaymentMethodMutation,
  useUpdatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
} from '../../store/services/productService';
import Wrapper from './Wrapper';

// components/AdminPaymentMethods.js

// import React, { useState, useEffect } from 'react';
// import {
//   useGetPaymentMethodsQuery,
//   useCreatePaymentMethodMutation,
//   useUpdatePaymentMethodMutation,
//   useDeletePaymentMethodMutation,
// } from '../redux/api/paymentMethodsApi';

const AdminPaymentMethods = () => {
  const { t } = useTranslation();

  const { data: paymentMethods, isLoading, error: fetchError } = useGetPaymentMethodsQuery();
  const [createPaymentMethod, { error: createError }] = useCreatePaymentMethodMutation();
  const [updatePaymentMethod, { error: updateError }] = useUpdatePaymentMethodMutation();
  const [deletePaymentMethod, { error: deleteError }] = useDeletePaymentMethodMutation();

  const [form, setForm] = useState({
    bank_name: '',
    rib: '',
    account_holder_name: '',
    agency_name: '',
    details: '',
    contact_name: '',
    contact_first_name: '',
    city: '',
    whatsapp_number: '',
    phone_number: '',
    additional_info: '',
    registration_confirmation_info: '',
  });

  const [editingId, setEditingId] = useState(null);
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handle form input changes
  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Handle form submission for both create and update
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError('');
    setSuccessMessage('');

    const payload = {
      bank_transfer: {
        bank_name: form.bank_name,
        rib: form.rib,
        account_holder_name: form.account_holder_name,
      },
      cash_transfer: {
        methods: [
          {
            agency_name: form.agency_name,
            details: form.details,
            contact_name: form.contact_name,
            contact_first_name: form.contact_first_name,
            city: form.city,
          },
        ],
      },
      contact_info: {
        whatsapp_number: form.whatsapp_number,
        phone_number: form.phone_number,
        additional_info: form.additional_info,
        registration_confirmation_info: form.registration_confirmation_info,
      },
    };

    try {
      if (editingId) {
        // Update existing payment method
        await updatePaymentMethod({ id: editingId, ...payload }).unwrap();
        setSuccessMessage('Payment method updated successfully!');
      } else {
        // Create new payment method
        await createPaymentMethod(payload).unwrap();
        setSuccessMessage('Payment method created successfully!');
      }
      // Reset form after successful submission
      setForm({
        bank_name: '',
        rib: '',
        account_holder_name: '',
        agency_name: '',
        details: '',
        contact_name: '',
        contact_first_name: '',
        city: '',
        whatsapp_number: '',
        phone_number: '',
        additional_info: '',
        registration_confirmation_info: '',
      });
      setEditingId(null);
    } catch (err) {
      setFormError('Failed to submit the form. Please try again.');
    }
  };

  // Handle edit action
  const handleEdit = (method) => {
    setEditingId(method._id);
    setForm({
      bank_name: method.bank_transfer.bank_name,
      rib: method.bank_transfer.rib,
      account_holder_name: method.bank_transfer.account_holder_name,
      agency_name: method.cash_transfer.methods[0].agency_name,
      details: method.cash_transfer.methods[0].details,
      contact_name: method.cash_transfer.methods[0].contact_name,
      contact_first_name: method.cash_transfer.methods[0].contact_first_name,
      city: method.cash_transfer.methods[0].city,
      whatsapp_number: method.contact_info.whatsapp_number,
      phone_number: method.contact_info.phone_number,
      additional_info: method.contact_info.additional_info,
      registration_confirmation_info: method.contact_info.registration_confirmation_info,
    });
  };

  // Handle delete action
  const handleDelete = async (id) => {
    try {
      await deletePaymentMethod(id).unwrap();
      setSuccessMessage('Payment method deleted successfully!');
    } catch (err) {
      setFormError('Failed to delete the payment method. Please try again.');
    }
  };

  return (
    <Wrapper>
      <div className="p-4  bg-yellow-500 text-black">
        <h2 className="text-2xl font-bold mb-4">
          {/* Payment Methods */}
          {t('admin-payment-methods-header')}

        </h2>

        {/* Display success message */}
        {successMessage && (
          <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
            {successMessage}
          </div>
        )}

        {/* Display error messages */}
        {formError && (
          <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
            {formError}
          </div>
        )}

        {/* Form for creating or editing payment methods */}
        <form onSubmit={handleSubmit} className="mb-4">
          {/* Bank Transfer Section */}
          <h3 className="text-xl font-bold mb-2">
            {/* Bank Transfer */}
            {t('admin-payment-methods-bank-transfer')}


          </h3>
          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="bank_name">
              {/* Bank Name */}
              {t('admin-payment-methods-bank-name')}

            </label>
            <input
              type="text"
              id="bank_name"
              name="bank_name"
              value={form.bank_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="rib">
              {/* RIB */}
              {t('admin-payment-methods-rib')}

            </label>
            <input
              type="text"
              id="rib"
              name="rib"
              value={form.rib}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="account_holder_name">
              {/* Account Holder Name */}
              {t('admin-payment-methods-account-holder-name')}

            </label>
            <input
              type="text"
              id="account_holder_name"
              name="account_holder_name"
              value={form.account_holder_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          {/* Cash Transfer Section */}
          <h3 className="text-xl font-bold mb-2">
            {/* Cash Transfer */}

            {t('admin-payment-methods-cash-transfer')}

          </h3>
          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="agency_name">
              {/* Agency Name */}
              {t('admin-payment-methods-agency-name')}

            </label>
            <input
              type="text"
              id="agency_name"
              name="agency_name"
              value={form.agency_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="details">
              {/* Details */}
              {t('admin-payment-methods-details')}

            </label>
            <input
              type="text"
              id="details"
              name="details"
              value={form.details}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="contact_name">
              {/* Contact Name */}
              {t('admin-payment-methods-contact-name')}

            </label>
            <input
              type="text"
              id="contact_name"
              name="contact_name"
              value={form.contact_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="contact_first_name">
              {/* Contact First Name */}
              {t('admin-payment-methods-contact-first-name')}

            </label>
            <input
              type="text"
              id="contact_first_name"
              name="contact_first_name"
              value={form.contact_first_name}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="city">
              {/* City */}
              {t('admin-payment-methods-city')}

            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={form.city}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          {/* Contact Info Section */}
          <h3 className="text-xl font-bold mb-2">
            {/* Contact Info */}
            {t('admin-payment-methods-contact-info')}

          </h3>
          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="whatsapp_number">
              {/* WhatsApp Number */}

              {t('admin-payment-methods-whatsapp-number')}

            </label>
            <input
              type="text"
              id="whatsapp_number"
              name="whatsapp_number"
              value={form.whatsapp_number}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
              required
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="phone_number">
              {/* Phone Number */}

              {t('admin-payment-methods-phone-number')}

            </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={form.phone_number}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="additional_info">
              {/* Additional Info */}

              {t('admin-payment-methods-additional-info')}

            </label>
            <input
              type="text"
              id="additional_info"
              name="additional_info"
              value={form.additional_info}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-bold mb-1" htmlFor="registration_confirmation_info">
              {/* Registration Confirmation Info */}

              {t('admin-payment-methods-registration-confirmation-info')}

            </label>
            <input
              type="text"
              id="registration_confirmation_info"
              name="registration_confirmation_info"
              value={form.registration_confirmation_info}
              onChange={handleInputChange}
              className="w-full px-2 py-1 border rounded"
            />
          </div>

          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded mt-4">
            {editingId ? 'Update' : 'Create'}
            {/* Payment Method */}
            {t('admin-payment-methods-header')}

          </button>
        </form>

        {/* Loading and error states */}
        {isLoading ? (
          <p
          >
            {/* Loading payment methods... */}
            {t('admin-payment-methods-loading')}


          </p>
        ) : fetchError ? (
          <p className="text-red-500">
            {/* Error fetching payment methods: */}
            {t('admin-payment-methods-fetch')}


            {fetchError.message}</p>
        ) : (
          <div>
            {/* Payment methods list */}
            {paymentMethods.map((method) => (
              <div key={method._id} className="mb-4 p-4 border rounded">
                <h4 className="text-lg font-bold">
                  {/* Bank Transfer */}
                  {t('admin-payment-methods-bank-transfer')}


                </h4>
                <p>
                  {/* Bank Name: */}
                  
                   {t('admin-payment-methods-bank-name')} {method.bank_transfer.bank_name}</p>
                <p>
                  {/* RIB:  */}

                  {t('admin-payment-methods-rib')} {method.bank_transfer.rib}</p>
                <p>Account Holder:  {t('admin-payment-methods-account-holder-name')} {method.bank_transfer.account_holder_name}</p>

                <h4 className="text-lg font-bold mt-2"
                >
                  {/* Cash Transfer */}
                {t('admin-payment-methods-cash-transfer')}
                </h4>
                {method.cash_transfer.methods.map((cashMethod, index) => (
                  <div key={index} className="mb-2">
                    {/* <p>Agency Name: {t('admin-payment-methods-header')}   {cashMethod.agency_name}</p>
                  <p>Details: {t('admin-payment-methods-header')} {cashMethod.details}</p>
                  <p>Contact Name: {t('admin-payment-methods-header')} {cashMethod.contact_name}</p>
                  <p>Contact First Name:{t('admin-payment-methods-header')} {cashMethod.contact_first_name}</p>
                  <p>City:{t('admin-payment-methods-header')} {cashMethod.city}</p> */}
                    <p>
                      {/* Agency Name:  */}
                      {t('admin-payment-methods-agency-name')}   {cashMethod.agency_name}</p>
                    <p>
                      {/* Details:  */}
                      {t('admin-payment-methods-details')} {cashMethod.details}</p>
                    <p>
                      {/* Contact Name:  */}
                      {t('admin-payment-methods-contact-name')} {cashMethod.contact_name}</p>
                    <p>
                      {/* Contact First Name: */}
                      {t('admin-payment-methods-contact-first-name')} {cashMethod.contact_first_name}</p>
                    <p>
                      {/* City: */}
                      {t('admin-payment-methods-city')} {cashMethod.city}</p>
                  </div>
                ))}


                {/* <h4 className="text-lg font-bold mt-2">
                Contact Info
                {t('admin-payment-methods-header')}
                </h4>
              <p>WhatsApp Number: {t('admin-payment-methods-header')} {method.contact_info.whatsapp_number}</p>
              <p>Phone Number: {t('admin-payment-methods-header')} {method.contact_info.phone_number}</p>
              <p>Additional Info:  {t('admin-payment-methods-header')} {method.contact_info.additional_info}</p>
              <p>Registration Confirmation Info: {t('admin-payment-methods-header')} {method.contact_info.registration_confirmation_info}</p> */}



                <h4 className="text-lg font-bold mt-2">
                  {/* Contact Info */}
                  {t('admin-payment-methods-contact-info')}
                </h4>
                <p>
                  {/* WhatsApp Number:  */}
                  
                  {t('admin-payment-methods-whatsapp-number')} {method.contact_info.whatsapp_number}</p>
                <p>
                  {/* Phone Number:  */}
                  {t('admin-payment-methods-phone-number')} {method.contact_info.phone_number}</p>
                <p>
                  {/* Additional Info:  */}
                   {t('admin-payment-methods-additional-info')} {method.contact_info.additional_info}</p>
                <p>
                  {/* Registration Confirmation Info:  */}
                  {t('admin-payment-methods-registration-confirmation-info')} {method.contact_info.registration_confirmation_info}</p>

                <button
                  onClick={() => handleEdit(method)}
                  className="px-2 py-1 hover:bg-red-500 bg-blue-500 text-white rounded mr-2"
                >
                  {/* Edit */}
                  {t('admin-payment-methods-edit')}

                </button>
                <button
                  onClick={() => handleDelete(method._id)}
                  className="px-2 py-1 bg-red-500 hover:bg-blue-500 text-white rounded"
                >
                  {/* Delete */}
                  {t('admin-payment-methods-delete')}

                </button>
              </div>
            ))}
          </div>
        )}
      </div>

    </Wrapper>

  );
};

export default AdminPaymentMethods;

