import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper";
import { useGetInstructorSearchQuery, useDeleteInstructorsearchMutation } from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

const Instructors = () => {
    const { t } = useTranslation(); // Initialize translation hook

    let { page } = useParams();
    if (!page) {
        page = 1;
    }

    // State for search fields
    const [searchFields, setSearchFields] = useState({
        name: "",
        email: "",
        mobile_number: "",
        total_courses: ""
    });

    const [searchTerm, setSearchTerm] = useState(""); // Combined search term

    const { data = [], isFetching } = useGetInstructorSearchQuery(searchTerm, {
        skip: !searchTerm,  // Skip the query if searchTerm is empty
    });

    const { success } = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        };
    }, [success, dispatch]);

    const [deleteInstructor, response] = useDeleteInstructorsearchMutation();

    const handleDelete = id => {
        if (window.confirm("Are you sure you want to delete this instructor?")) {
            deleteInstructor(id);
        }
    };

    const handleSearch = () => {
        // Build the query string based on non-empty search fields
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(searchFields).filter(([key, value]) => value.trim() !== '')
            )
        ).toString();

        console.log("Generated Query Params:", queryParams); // Log for debugging
        setSearchTerm(queryParams); // Set the searchTerm with query parameters
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Reset all fields to empty except for the one being updated
        setSearchFields({
            name: name === 'name' ? value : '',
            email: name === 'email' ? value : '',
            mobile_number: name === 'mobile_number' ? value : '',
            total_courses: name === 'total_courses' ? value : ''
        });
    };

    return (
        <Wrapper>
            <ScreenHeader>
                {/* <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">Teacher Search</p> */}
                <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">{t('admin-dashboard-teacher-search-title')}</p>

                <Toaster position="top-right" />
            </ScreenHeader>
            {/* Input fields for search criteria */}
            <div className="flex space-x-4 mb-4 text-black">
                <input
                    type="text"
                    name="name"
                    // placeholder="Name"
                    placeholder={t('admin-dashboard-teacher-search-name')}

                    value={searchFields.name}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="email"
                    // placeholder="Email"
                    placeholder={t('admin-dashboard-teacher-search-email')}

                    value={searchFields.email}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="mobile_number"
                    placeholder="Mobile Number"
                    placeholder={t('admin-dashboard-teacher-search-mobile-number')}

                    value={searchFields.mobile_number}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="total_courses"
                    placeholder="Total Courses"
                    placeholder={t('admin-dashboard-teacher-search-total-courses')}

                    value={searchFields.total_courses}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <button
                    onClick={handleSearch}
                    className="btn btn-primary hover:bg-red-500 bg-blue-500"
                >
                    Search
                    {t('admin-dashboard-teacher-search-search-button')}

                </button>
            </div>
            {!isFetching ? data?.instructors?.length > 0 ? (
                <div>
                    <table className="w-full bg-gray-900 rounded-md">
                        <thead>
                            <tr className="border-b border-gray-800 text-left">
                                {/* <th className="p-3 uppercase text-sm font-medium text-gray-500">Name</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Email</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Mobile Number</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Total Courses</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th> */}

                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-table-name')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-table-email')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-table-mobile-number')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-table-total-courses')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-edit-button')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-teacher-search-delete-button')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.instructors?.map(instructor => (
                                <tr className="odd:bg-gray-800" key={instructor._id}>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">{instructor.name}</td>
                                    <td className="p-3 text-sm font-normal text-gray-400">{instructor.email}</td>
                                    <td className="p-3 text-sm font-normal text-gray-400">{instructor.mobile_number}</td>
                                    <td className="p-3 text-sm font-normal text-gray-400">{instructor.total_courses}</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        {/* <Link to={`/dashboard/instructor/update/${instructor._id}`} className="btn btn-warning">Edit</Link> */}
                                        <Link to={`/dashboard/instructor/update/${instructor._id}`} className="btn btn-warning">{t('admin-dashboard-teacher-search-edit-button')}</Link>

                                    </td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        {/* <span className="btn btn-danger cursor-pointer" onClick={() => handleDelete(instructor._id)}>Delete</span> */}
                                        <span className="btn btn-danger cursor-pointer" onClick={() => handleDelete(instructor._id)}>{t('admin-dashboard-teacher-search-delete-button')}</span>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/instructors" />
                </div>
            // ) : <p className="bg-red-500 p-4">'No instructors found!'</p> : <Spinner />}
            ) : <p className="bg-red-500 p-4">{t('admin-dashboard-teacher-search-no-instructors')}</p>  : <Spinner />}
            </Wrapper>
    );
};

export default Instructors;
