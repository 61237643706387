import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;
const studentService = createApi({
    reducerPath: 'studentService',
    tagTypes: 'students',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api/student/',
        baseUrl: `${apiUrl}/api/student/`   ,
        prepareHeaders: (headers, {getState}) => {
            const reducers = getState();
            console.log("this from api call ",reducers)
            const token = reducers?.authReducer?.userToken;
            console.log(token)
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            // // TeacherController
            // cProduct: builder.mutation({
            //     query: (formData) => {
            //        return {
            //            url: '/createcoursetech',
            //            method: 'POST',
            //            body: formData,
            //        }
            //     },
            //     invalidatesTags: ['students']
            // }), 

            getPaidCoursesDetails: builder.query({
                query: (userId) => {
                 return {
                     url: `paid-courses-details?userId=${userId}`,
                     method: 'GET'
                 }
                },
                providesTags: ['students']
            }),
             
        }
    }
})
export const {useGetPaidCoursesDetailsQuery } = studentService;
export default studentService

