// import { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import { clearMessage } from "../../store/reducers/globalReducer";
// import Wrapper from "./Wrapper";
// import {useGetStudentsearchQuery ,useDeleteStudentadminsearchMutation} from "../../store/services/productService";
// import ScreenHeader from "../../components/ScreenHeader";
// import Spinner from "../../components/Spinner";
// import Pagination from "../../components/Pagination";

// const Students = () => {
//    let { page } = useParams();
//    if (!page) {
//       page = 1;
//    }

//    const [searchTerm, setSearchTerm] = useState("");
//    console.log("query",searchTerm)
//    const { data = [], isFetching } = useGetStudentsearchQuery(searchTerm); // Fetch based on searchTerm
//    console.log("data",data)
//    const { success } = useSelector(state => state.globalReducer);
//    const dispatch = useDispatch();

//    useEffect(() => {
//       if (success) {
//          toast.success(success);
//       }
//       return () => {
//          dispatch(clearMessage());
//       };
//    }, [success, dispatch]);

//    const [deleteStudent, response] = useDeleteStudentadminsearchMutation();

//    const handleDelete = id => {
//       if (window.confirm("Are you sure you want to delete this student?")) {
//          deleteStudent(id);
//       }
//    };

//    const handleSearch = (e) => {
//       setSearchTerm(e.target.value);
//    };

//    return (
//       <Wrapper>
//          <ScreenHeader>
//             <Link to="/dashboard/create-student" className="btn-dark">Create Student</Link>
//             <Toaster position="top-right" />
//          </ScreenHeader>
//          <input
//             type="text"
//             placeholder="Search Students..."
//             value={searchTerm}
//             onChange={handleSearch}
//             className="p-2 mb-4 rounded"
//          />
//          {!isFetching ? data?.students?.length > 0 ? (
//             <div>
//                <table className="w-full bg-gray-900 rounded-md">
//                   <thead>
//                      <tr className="border-b border-gray-800 text-left">
//                         <th className="p-3 uppercase text-sm font-medium text-gray-500">Name</th>
//                         <th className="p-3 uppercase text-sm font-medium text-gray-500">Email</th>
//                         <th className="p-3 uppercase text-sm font-medium text-gray-500">WhatsApp Number</th>
//                         <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
//                         <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th>
//                      </tr>
//                   </thead>
//                   <tbody>
//                      {data?.students?.map(student => (
//                         <tr className="odd:bg-gray-800" key={student._id}>
//                            <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.name}</td>
//                            <td className="p-3 text-sm font-normal text-gray-400">{student.email}</td>
//                            <td className="p-3 text-sm font-normal text-gray-400">{student.whatsapp_number}</td>
//                            <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                               <Link to={`/dashboard/student/update/${student._id}`} className="btn btn-warning">Edit</Link>
//                            </td>
//                            <td className="p-3 capitalize text-sm font-normal text-gray-400">
//                               <span className="btn btn-danger cursor-pointer" onClick={() => handleDelete(student._id)}>Delete</span>
//                            </td>
//                         </tr>
//                      ))}
//                   </tbody>
//                </table>
//                <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/students" />
//             </div>
//          ) : 'No students found!' : <Spinner />}
//       </Wrapper>
//    );
// };

// export default Students;





import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'; // Import useTranslation

import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper";
import { useGetStudentsearchQuery, useDeleteStudentadminsearchMutation } from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

const Students = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook

    let { page } = useParams();
    if (!page) {
        page = 1;
    }

    // State for search fields
    const [searchFields, setSearchFields] = useState({
        name: "",
        email: "",
        studentId: "",
        whatsapp_number: ""
    });

    const [searchTerm, setSearchTerm] = useState(""); // Combined search term

    const { data = [], isFetching } = useGetStudentsearchQuery(searchTerm, {
        skip: !searchTerm,  // Skip the query if searchTerm is empty
    });

    const { success } = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            toast.success(success);
        }
        return () => {
            dispatch(clearMessage());
        };
    }, [success, dispatch]);

    const [deleteStudent, response] = useDeleteStudentadminsearchMutation();

    const handleDelete = id => {
        if (window.confirm("Are you sure you want to delete this student?")) {
            deleteStudent(id);
        }
    };

    const handleSearch = () => {
        // Build the query string based on non-empty search fields
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(searchFields).filter(([key, value]) => value.trim() !== '')
            )
        ).toString();

        console.log("Generated Query Params:", queryParams); // Log for debugging
        setSearchTerm(queryParams); // Set the searchTerm with query parameters
    };



    // const handleChange = (e) => {
    //     setSearchFields({
    //         ...searchFields,
    //         [e.target.name]: e.target.value
    //     });
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Reset all fields to empty except for the one being updated
        setSearchFields({
            name: name === 'name' ? value : '',
            email: name === 'email' ? value : '',
            studentId: name === 'studentId' ? value : '',
            whatsapp_number: name === 'whatsapp_number' ? value : ''
        });
    };


    return (
        <Wrapper>
            <ScreenHeader>
                {/* <Link to="/dashboard/create-student" className="btn-dark">Create Student</Link> */}
                <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">{t('admin-dashboard-student-search-title')}</p>

                <Toaster position="top-right" />
            </ScreenHeader>
            {/* Input fields for search criteria */}
            <div className="flex space-x-4 mb-4 text-black">
                <input
                    type="text"
                    name="name"
                    // placeholder="Name"
                    placeholder={t('admin-dashboard-student-search-search-name')}

                    value={searchFields.name}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="email"
                    // placeholder="Email"
                    placeholder={t('admin-dashboard-student-search-search-email')}

                    value={searchFields.email}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="studentId"
                    // placeholder="Student ID"
                    placeholder={t('admin-dashboard-student-search-search-student-id')}

                    value={searchFields.studentId}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <input
                    type="text"
                    name="whatsapp_number"
                    // placeholder="WhatsApp Number"
                    placeholder={t('admin-dashboard-student-search-search-whatsapp-number')}

                    value={searchFields.whatsapp_number}
                    onChange={handleChange}
                    className="p-2 rounded"
                />
                <button
                    onClick={handleSearch}
                    className="btn btn-primary hover:bg-red-500 bg-blue-500"
                >
                    {/* Search */}
                    {t('admin-dashboard-student-search-search-button')}
                </button>
            </div>
            {!isFetching ? data?.students?.length > 0 ? (
                <div>
                    <table className="w-full bg-gray-900 rounded-md">
                        <thead>
                            <tr className="border-b border-gray-800 text-left">
                                {/* <th className="p-3 uppercase text-sm font-medium text-gray-500">Name</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Email</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">WhatsApp Number</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Edit</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">Delete</th> */}

                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-student-search-table-name')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-student-search-table-email')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-student-search-table-whatsapp-number')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-student-search-edit-button')}</th>
                                <th className="p-3 uppercase text-sm font-medium text-gray-500">{t('admin-dashboard-student-search-delete-button')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.students?.map(student => (
                                <tr className="odd:bg-gray-800" key={student._id}>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">{student.name}</td>
                                    <td className="p-3 text-sm font-normal text-gray-400">{student.email}</td>
                                    <td className="p-3 text-sm font-normal text-gray-400">{student.whatsapp_number}</td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        {/* <Link to={`/dashboard/edit-student/${student._id}`} className="btn btn-warning">Edit</Link> */}
                                        <Link to={`/dashboard/edit-student/${student._id}`} className="btn btn-warning">{t('admin-dashboard-student-search-edit-button')}</Link>

                                    </td>
                                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                                        {/* <span className="btn btn-danger cursor-pointer" onClick={() => handleDelete(student._id)}>Delete</span> */}
                                        <span className="btn btn-danger cursor-pointer" onClick={() => handleDelete(student._id)}>{t('admin-dashboard-student-search-delete-button')}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/students" />
                </div>
            ) : <p className="bg-red-500 p-4">{t('admin-dashboard-student-search-no-students')}</p>  : <Spinner />}
        </Wrapper>
    );
};

export default Students;









