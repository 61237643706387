import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { logout } from '../../store/reducers/Coursereducer/authSlice';
import { logout } from "../../store/reducers/authReducer"
//langue 1
import { useTranslation } from 'react-i18next';
// import { lang } from 'moment';
import bgImage from "../../assets/images/mainlogo.png"


const HomeNav = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();


  const [isOpen, setIsOpen] = useState(false);
  //language 2 start 

  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);

  const [isCollageDropdownOpen, setIsCollageDropdownOpen] = useState(false);

  const [isLyceeDropdownOpen, setIsLyceeDropdownOpen] = useState(false);

  // const langDropdownRef = useRef(null);


  const handleLanguageChange = (lng) => {
    console.log("lng",lng)

    i18n.changeLanguage(lng);
    console.log("lng",lng)
    setIsLangDropdownOpen(false); // Close the dropdown after selecting a language
    setIsOpen(!isOpen);

  };

  // Toggle language dropdown
  const handleLangDropdownToggle = () => {
    setIsLangDropdownOpen(!isLangDropdownOpen);
  };


  const handleCollageDropdownToggle = () => {
    setIsCollageDropdownOpen(!isCollageDropdownOpen);

  };

  const handleCollageLinkDropdownToggle = (data) => {
    setIsCollageDropdownOpen(!isCollageDropdownOpen);
    navigate(`/${data}`);

  };




  const handleLyceeDropdownToggle = () => {
    setIsLyceeDropdownOpen(!isLyceeDropdownOpen);

  };

  const handleLyceeLinkDropdownToggle = (data) => {
    setIsLyceeDropdownOpen(!isLyceeDropdownOpen);
    navigate(`/${data}`);

  };
  // Close dropdown on outside click
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (langDropdownRef.current && !langDropdownRef.current.contains(event.target)) {
  //       setIsLangDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  //language 2 close 

  // Toggle mobile menu
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { token, user } = useSelector((state) => state.auth);
  const { userToken, user } = useSelector((state) => state.authReducer);

  console.log("userToken", userToken)
  console.log("user", user)


  const handleLogout = (data) => {
    console.log("data", data)

    dispatch(logout(data));
    navigate('/login');
    // Redirect to login page after logout
  };


  return (
    <nav className="bg-[#476CC4] text-white fixed w-full top-0 z-50 shadow-md">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-4">
        <div className="relative flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
   
            <Link to="/" className=" font-bold text-blue-400 flex ">
            {/* Teachable Clone */}
            <img src={bgImage} alt="logo" className='h-[60px] w-[120px]' />   
              <p className='mt-3 ml-1 text-yellow-300'>Chinwi Academy</p>

            
                     </Link>
          </div>
          {/* Desktop Menu */}
          <div className="hidden lg:flex lg:ml-6 ">
          {/* <div className="flex space-x-4 ml-auto flex-shrink-0"> */}
          <div className="flex   ml-auto  flex-wrap ">
          <Link to="/" className="text-white  hover:bg-white hover:text-black px-2 py-2 rounded-md  text-sm font-medium">
          {t('nav-home')}  
          {/* Home */}
          
          </Link>


              {/* lycee Dropdown for desktop */}
 <div className="relative">
            <button onClick={handleCollageDropdownToggle} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">
              {/* Language */}
              {t('nav-lycee')}  
            </button>
            {isCollageDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <button onClick={() => handleCollageLinkDropdownToggle("commoncore")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm"> {t('nav-commoncore')} </button>
                <button onClick={() => handleCollageLinkDropdownToggle("firstbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-baca')}</button>
                <button onClick={() => handleCollageLinkDropdownToggle("secoundbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-bacb')}</button>
              </div>
            )}
          </div>



     {/* Collage Dropdown for desktop */}
     <div className="relative">
            <button onClick={handleLyceeDropdownToggle} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">
              {/* Language */}
              {t('nav-collage')}  
            </button>
            {isLyceeDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <button onClick={() => handleLyceeLinkDropdownToggle("commoncore")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm"> {t('nav-collagef')} </button>
                <button onClick={() => handleLyceeLinkDropdownToggle("firstbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-collages')}</button>
                <button onClick={() => handleLyceeLinkDropdownToggle("secoundbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-bacb')}</button>
              </div>
            )}
          </div>


{/* 
              <Link to="/commoncore" className="  text-white hover:bg-white hover:text-black  px-2 py-2 rounded-md text-sm font-medium">
              Commin Core
              {t('nav-commoncore')}  

              </Link>
              <Link to="/firstbac" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
              1St Bac
              {t('nav-baca')}  
              </Link>
              <Link to="/secoundbac" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
              {t('nav-bacb')}  
              </Link> */}
              <Link to="/sup" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
              {/* Sup */}
              {t('nav-sup')}  
              </Link>
              {/* <Link to="/about" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">About</Link> */}
              {/* <Link to="/video" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Contact</Link> */}
              <Link to="/coursesmain" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
              {/* All Courses */}
              {t('nav-alcourses')}  
              </Link>
              <Link to="/courses" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
              {/* Prices */}
              {t('nav-price')}  
              </Link>

              {/* <Link to="/login" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Login</Link> */}
              {/* <Link to="/register" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Register</Link> */}

              {userToken ? (
                <>
                  <button onClick={() => handleLogout('userToken')} className="text-white hover:bg-white hover:text-black px-1 py-2 rounded-md text-sm font-medium">Logout</button>
                  <Link to="/user" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">{user?.name ? user?.name : "no"}</Link>


                </>

              ) : (
                <>
                  <Link to="/login" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  
                  {/* Login */}
                  {t('nav-login')}  

                  </Link>

                  {/* <Link to="/loginb" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Login</Link> */}
                  <Link to="/register" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  {t('nav-register')}  

                  {/* Register */}
                  </Link>
                  <Link to="/instructorregister" className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  
                  {/* Teacher */}
                  {t('nav-teacher')}  

                  </Link>

                </>
              )}

              {/* Language Dropdown */}
              <div className="relative" >
              {/* <div className="relative" ref={langDropdownRef}> */}
                <button onClick={handleLangDropdownToggle} className="text-white hover:bg-white hover:text-black px-2 py-2 rounded-md text-sm font-medium">
                  Language
                </button>
                {isLangDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <button onClick={() => handleLanguageChange('en')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">English</button>
                    <button onClick={() => handleLanguageChange('ar')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">العربية</button>
                    <button onClick={() => handleLanguageChange('fr')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">française</button>
                  </div>
                )}
              </div>

              {/* <button  onClick={() => handleLanguageChange('en')} className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">English</button>
                                <button onClick={() => handleLanguageChange('ar')} className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">العربية</button>
                                <button onClick={() => handleLanguageChange('fr')} className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">française</button>
 */}


            </div>
          </div>
          {/* Mobile Menu Button */}
          <div className="-mr-2 flex items-center lg:hidden">
            <button onClick={handleMenuToggle} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="sr-only">Open main menu</span>
              <svg className={`h-6 w-6 ${isOpen ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className={`h-6 w-6 ${isOpen ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Items */}
      <div className={`${isOpen ? 'block' : 'hidden'} lg:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Home</Link>


              {/* lycee Dropdown for desktop */}
              <div className="relative">
            <button onClick={handleCollageDropdownToggle} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">
              {/* Language */}
              {t('nav-lycee')}  
            </button>
            {isCollageDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <button onClick={() => handleCollageLinkDropdownToggle("commoncore")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm"> {t('nav-commoncore')} </button>
                <button onClick={() => handleCollageLinkDropdownToggle("firstbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-baca')}</button>
                <button onClick={() => handleCollageLinkDropdownToggle("secoundbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-bacb')}</button>
              </div>
            )}
          </div>



     {/* Collage Dropdown for desktop */}
     <div className="relative">
            <button onClick={handleLyceeDropdownToggle} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">
              {/* Language */}
              {t('nav-collage')}  
            </button>
            {isLyceeDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <button onClick={() => handleLyceeLinkDropdownToggle("commoncore")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm"> {t('nav-collagef')} </button>
                <button onClick={() => handleLyceeLinkDropdownToggle("firstbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-collages')}</button>
                <button onClick={() => handleLyceeLinkDropdownToggle("secoundbac")} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">{t('nav-collaget')}</button>
              </div>
            )}
          </div>








          {/* <Link to="/commoncore" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Commin Core</Link> */}
          {/* <Link to="/firstbac" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">1St Bac</Link> */}
          {/* <Link to="/secoundbac" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">2Nd Bac</Link> */}
          <Link to="/sup" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Sup</Link>
          <Link to="/video" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Video</Link>
          <Link to="/coursesmain" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">All Courses</Link>
          <Link to="/courses" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Prices</Link>

          {userToken ? (
            <>
              <button onClick={() => handleLogout('userToken')} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">Logout</button>
              <Link to="/user" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">{user?.name ? user?.name : "no"}</Link>


            </>

          ) : (
            <>
              <Link to="/login" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Login</Link>
              <Link to="/register" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Register</Link>
              <Link to="/instructorregister" className="text-white hover:bg-white hover:text-black block px-3 py-2 rounded-md text-base font-medium">Teacher</Link>



            </>
          )}



 {/* Language Dropdown for Mobile */}
 <div className="relative">
            <button onClick={handleLangDropdownToggle} className="text-white hover:bg-white hover:text-black px-3 py-2 rounded-md text-sm font-medium">
              Language
            </button>
            {isLangDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <button onClick={() => handleLanguageChange('en')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">English</button>
                <button onClick={() => handleLanguageChange('ar')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">العربية</button>
                <button onClick={() => handleLanguageChange('fr')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">française</button>
              </div>
            )}
          </div>

   {/* Language Dropdown */}
   {/* <div className="relative" ref={langDropdownRef}>
                <button onClick={handleLangDropdownToggle} className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">
                  Language
                </button>
                {isLangDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <button onClick={() => handleLanguageChange('en')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">English</button>
                    <button onClick={() => handleLanguageChange('ar')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">العربية</button>
                    <button onClick={() => handleLanguageChange('fr')} className="text-black hover:bg-gray-200 block px-4 py-2 text-sm">française</button>
                  </div>
                )}
              </div> */}



        </div>
      </div>
    </nav>
  );
};

export default HomeNav;






























// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   // Toggle mobile menu
//   const handleMenuToggle = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="bg-[#476CC4] text-white fixed w-full top-0 z-50 shadow-md">
//       <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
//         <div className="relative flex items-center justify-between h-16">
//           {/* Logo */}
//           <div className="flex-shrink-0">
//             <Link to="/" className="text-2xl font-bold text-blue-400">Teachable Clone</Link>
//           </div>
//           {/* Desktop Menu */}
//           <div className="hidden tablet:flex tablet:ml-6">
//             <div className="flex space-x-4 ml-auto">
//               <Link to="/" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Home</Link>
//               <Link to="/courses" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Courses</Link>
//               <Link to="/about" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">About</Link>
//               <Link to="/contact" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Contact</Link>
//               <Link to="/login" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Login</Link>
//               <Link to="/register" className="text-white hover:bg-opacity-20 hover:bg-black px-3 py-2 rounded-md text-sm font-medium">Register</Link>
//             </div>
//           </div>
//           {/* Mobile Menu Button */}
//           <div className="-mr-2 flex items-center tablet:hidden">
//             <button onClick={handleMenuToggle} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
//               <span className="sr-only">Open main menu</span>
//               <svg className={`h-6 w-6 ${isOpen ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
//               </svg>
//               <svg className={`h-6 w-6 ${isOpen ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Mobile Menu Items */}
//       <div className={`${isOpen ? 'block' : 'hidden'} tablet:hidden`}>
//         <div className="px-2 pt-2 pb-3 space-y-1">
//           <Link to="/" className="text-white hover:bg-opacity-20 hover:bg-black block px-3 py-2 rounded-md text-base font-medium">Home</Link>
//           <Link to="/courses" className="text-white hover:bg-opacity-20 hover:bg-black block px-3 py-2 rounded-md text-base font-medium">Courses</Link>
//           <Link to="/about" className="text-white hover:bg-opacity-20 hover:bg-black block px-3 py-2 rounded-md text-base font-medium">About</Link>
//           <Link to="/contact" className="text-white hover:bg-opacity-20 hover:bg-black block px-3 py-2 rounded-md text-base font-medium">Contact</Link>
//           <Link to="/login" className="text-white hover:bg-opacity-20 hover:bg-black block px-3 py-2 rounded-md text-base font-medium">Login</Link>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
