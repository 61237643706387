
// import { useEffect, useState } from "react";
// import { useParams, Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import { useGetInstructorQuery, useUpdateInstructorMutation } from "../../store/services/productService";
// import Wrapper from "./Wrapper";
// import ScreenHeader from "../../components/ScreenHeader";
// import Spinner from "../../components/Spinner";
// import { clearMessage } from "../../store/reducers/globalReducer";







// const EditInstructor = () => {
//   const { id } = useParams();
//   const { data: instructorData, isFetching } = useGetInstructorQuery(id);
//   const [updateInstructor, { isLoading }] = useUpdateInstructorMutation();
//   const { success } = useSelector(state => state.globalReducer);
//   const dispatch = useDispatch();

//   const [instructor, setInstructor] = useState({
//     name: "",
//     picture_url: "",
//     userimage: "",
//     total_courses: 0,
//     balance: 0,
//     paid: 0,
//     withdrawable: 0,
//     total_withdrawn_fees: 0,
//     bank_account: "",
//     mobile_number: "",
//     email: "",
//     password: "",
//     email_verified: false,
//   });

//   const [preview, setPreview] = useState("");

//   useEffect(() => {
//     if (instructorData) {
//       setInstructor(instructorData);
//       setPreview(instructorData.userimage ? ` http://localhost:5000/uploads/instructors/${instructorData.userimage}` : "");
//     }
//   }, [instructorData]);

//   useEffect(() => {
//     if (success) {
//       toast.success(success);
//     }
//     return () => {
//       dispatch(clearMessage)// Dispatch any necessary actions like clearing success messages
//     };
//   }, [success, dispatch]);

//   const handleChange = (e) => {
//     setInstructor({ ...instructor, [e.target.name]: e.target.value });
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setInstructor({ ...instructor, userimage: file });
//       setPreview(URL.createObjectURL(file));
//     }
//   };

  

//   const handleSubmit = (e) => {
//     e.preventDefault();
  
//     const formData = new FormData();
//     Object.keys(instructor).forEach((key) => {
//       if (instructor[key] !== "" && key !== 'userimage') {
//         formData.append(key, instructor[key]);
//       }
//     });
  
//     if (instructor.userimage) {
//       formData.append("userimage", instructor.userimage);
//     }
  
//     console.log("Submitting form data:", formData);
  
//     // Update the instructor
//     updateInstructor({ id, body: formData })
//       .unwrap()
//       .then(response => {
//         console.log("Update response:", response);
//         toast.success('Instructor updated successfully');
//       })
//       .catch(error => {
//         console.error("Update error:", error);
//         toast.error('Failed to update instructor');
//       });
//   };
  

//   if (isFetching || isLoading) return <Spinner />;

//   return (
//     <Wrapper>
//       <ScreenHeader>
//         <Link to="/dashboard/instructors" className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700">
//           Back to Instructors
//         </Link>
//         <Toaster position="top-right" />
//       </ScreenHeader>
//       <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-6 bg-yellow-500 text-black shadow-lg rounded-lg" encType="multipart/form-data">
//         {/* Name */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Name</label>
//           <input
//             type="text"
//             name="name"
//             value={instructor.name}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Email */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Email</label>
//           <input
//             type="email"
//             name="email"
//             value={instructor.email}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Password */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Password</label>
//           <input
//             type="password"
//             name="password"
//             value={instructor.password}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Phone Number */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Phone Number</label>
//           <input
//             type="text"
//             name="mobile_number"
//             value={instructor.mobile_number}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Picture */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Picture</label>
//           {preview && <img src={preview} alt="Instructor" className="mb-2 w-32 h-32 object-cover rounded-full" />}
//           <input
//             type="file"
//             name="userimage"
//             onChange={handleImageChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Total Courses */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Total Courses</label>
//           <input
//             type="number"
//             name="total_courses"
//             value={instructor.total_courses}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Balance */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Balance</label>
//           <input
//             type="number"
//             name="balance"
//             value={instructor.balance}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Paid */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Paid</label>
//           <input
//             type="number"
//             name="paid"
//             value={instructor.paid}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Withdrawable */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Withdrawable</label>
//           <input
//             type="number"
//             name="withdrawable"
//             value={instructor.withdrawable}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Total Withdrawn Fees */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Total Withdrawn Fees</label>
//           <input
//             type="number"
//             name="total_withdrawn_fees"
//             value={instructor.total_withdrawn_fees}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Bank Account */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Bank Account</label>
//           <input
//             type="text"
//             name="bank_account"
//             value={instructor.bank_account}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Email Verified */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Email Verified</label>
//           <select
//             name="email_verified"
//             value={instructor.email_verified}
//             onChange={handleChange}
//             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           >
//             <option value={true}>Yes</option>
//             <option value={false}>No</option>
//           </select>
//         </div>

//         <button
//           type="submit"
//           className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         >
//           Update Instructor
//         </button>
//       </form>
//     </Wrapper>
//   );
// };

// export default EditInstructor;













import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { useGetInstructorQuery, useUpdateInstructorMutation } from "../../store/services/productService";
import Wrapper from "./Wrapper";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import { clearMessage } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const EditInstructor = () => {
  const { t } = useTranslation(); // Initialize translation function
  const { id } = useParams();
  const { data: instructorData, isFetching } = useGetInstructorQuery(id);
  const [updateInstructor, { isLoading }] = useUpdateInstructorMutation();
  const { success } = useSelector(state => state.globalReducer);
  const dispatch = useDispatch();

  const [instructor, setInstructor] = useState({
    name: "",
    picture_url: "",
    userimage: "",
    total_courses: 0,
    balance: 0,
    paid: 0,
    withdrawable: 0,
    total_withdrawn_fees: 0,
    bank_account: "",
    mobile_number: "",
    email: "",
    password: "",
    email_verified: false,
  });

  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (instructorData) {
      setInstructor(instructorData);
      setPreview(instructorData.userimage ? `http://localhost:5000/uploads/instructors/${instructorData.userimage}` : "");
    }
  }, [instructorData]);

  useEffect(() => {
    if (success) {
      toast.success(success);
    }
    return () => {
      dispatch(clearMessage()); // Ensure clearMessage is invoked as a function
    };
  }, [success, dispatch]);

  const handleChange = (e) => {
    setInstructor({ ...instructor, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setInstructor({ ...instructor, userimage: file });
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    Object.keys(instructor).forEach((key) => {
      if (instructor[key] !== "" && key !== 'userimage') {
        formData.append(key, instructor[key]);
      }
    });
  
    if (instructor.userimage) {
      formData.append("userimage", instructor.userimage);
    }
  
    console.log("Submitting form data:", formData);
  
    updateInstructor({ id, body: formData })
      .unwrap()
      .then(response => {
        console.log("Update response:", response);
        toast.success(t('admin-dashboard-teacher-update-successUpdate'));
      })
      .catch(error => {
        console.error("Update error:", error);
        toast.error(t('admin-dashboard-teacher-update-errorUpdate'));
      });
  };
  
  if (isFetching || isLoading) return <Spinner />;

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/instructors" className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700">
          {t('admin-dashboard-teacher-update-backToInstructors')}
        </Link>
        <Toaster position="top-right" />
      </ScreenHeader>
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-6 bg-yellow-500 text-black shadow-lg rounded-lg" encType="multipart/form-data">
        {/* Name */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-name')}</label>
          <input
            type="text"
            name="name"
            value={instructor.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-email')}</label>
          <input
            type="email"
            name="email"
            value={instructor.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Password */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-password')}</label>
          <input
            type="password"
            name="password"
            value={instructor.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Phone Number */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-phoneNumber')}</label>
          <input
            type="text"
            name="mobile_number"
            value={instructor.mobile_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Picture */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-picture')}</label>
          {preview && <img src={preview} alt="Instructor" className="mb-2 w-32 h-32 object-cover rounded-full" />}
          <input
            type="file"
            name="userimage"
            onChange={handleImageChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Total Courses */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-totalCourses')}</label>
          <input
            type="number"
            name="total_courses"
            value={instructor.total_courses}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Balance */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-balance')}</label>
          <input
            type="number"
            name="balance"
            value={instructor.balance}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Paid */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-paid')}</label>
          <input
            type="number"
            name="paid"
            value={instructor.paid}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Withdrawable */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-withdrawable')}</label>
          <input
            type="number"
            name="withdrawable"
            value={instructor.withdrawable}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Total Withdrawn Fees */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-totalWithdrawnFees')}</label>
          <input
            type="number"
            name="total_withdrawn_fees"
            value={instructor.total_withdrawn_fees}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Bank Account */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">{t('admin-dashboard-teacher-update-bankAccount')}</label>
          <input
            type="text"
            name="bank_account"
            value={instructor.bank_account}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600"
        >
          {t('admin-dashboard-teacher-update-updateInstructor')}
        </button>
      </form>
    </Wrapper>
  );
};

export default EditInstructor;






