import React from 'react';

const WhatsAppButton = () => {
  const whatsappNumber = ' +971527842024'; // Replace with your WhatsApp number
  const whatsappLink = `https://wa.me/${whatsappNumber}`;

  return (
    <div className="fixed bottom-4 right-4 mr-2">
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 hover:bg-green-600 text-white w-[80px] h-[80px] rounded-full p-4 flex items-center justify-center"
      >
        {/* WhatsApp Icon from Bootstrap Icons */}
        <i className="bi bi-whatsapp text-3xl"></i>
      </a>
    </div>
  );
};

export default WhatsAppButton;
