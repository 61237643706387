





















// import React from 'react';

// const WelcomeSection = () => {
//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-white px-6 py-12 text-center">
//       {/* Main Heading */}
//       <h1 className="text-4xl md:text-5xl lg:text-[68px] font-extrabold text-gray-900 mb-4 leading-tight">
//         WELCOME TO
//       </h1>
      
//       {/* Second Heading */}
//       <h2 className="text-2xl md:text-3xl lg:text-[48px] font-serif text-gray-800 mb-6 tracking-wider">
//         OM SHREE INTERNATIONAL PVT. LTD
//       </h2>
      
//       {/* Description Text Box */}
//       <p className="text-sm md:text-base lg:text-lg max-w-3xl mx-auto mb-8 text-gray-700 leading-relaxed">
//         Our group was established in the year 1988, for INTERNATIONAL presence. OM SHREE INTERNATIONAL was established for the export of Agricultural products in the year 1993. The Company has travelled a long way since then. Keeping strictly to its policy of clarity and transparency with the buyers and very strict quality controls on the supply side, the Company has grown in terms of its geographical reach as well as the range of products that it exports.
//       </p>
      
//       {/* Centered Button */}
//       <button className="px-8 py-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-full text-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
//         Learn More
//       </button>
//     </div>
//   );
// };

// export default WelcomeSection;

















import React from 'react';
import bgImage from '../../assets/welcome-bg.jpg'; // Add a background image of your choice here
import logoImage from '../../assets/company-logo.png'; // Add a logo or any image here
import { useNavigate } from 'react-router-dom';

const WelcomeSection = () => {
  const navigate=useNavigate()


  const gotoweb = () => {
    
    navigate("/aboutdubai#aboutus")
   
  };
  return (
    <div
      className="relative flex flex-col items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>

      {/* Content */}
      <div className="relative z-10 px-6 py-12 text-center flex flex-col items-center">
        {/* Logo or Image */}
        <img
          src={logoImage}
          alt="Company Logo"
          className="mb-8 w-24 h-24 md:w-32 md:h-32 object-contain"
        />

        {/* Main Heading */}
        <h1 className="text-4xl md:text-5xl lg:text-[68px] font-extrabold text-white mb-4 leading-tight drop-shadow-lg">
          WELCOME TO
        </h1>

        {/* Second Heading */}
        <h2 className="text-2xl md:text-3xl lg:text-[48px] font-serif text-gray-100 mb-6 tracking-wider">
           WGK INTERNATIONAL PVT. LTD
        </h2>

        {/* Description Text Box */}
        <p className="text-sm md:text-base lg:text-lg max-w-3xl mx-auto mb-8 text-gray-200 leading-relaxed">
          Our group was established in the year 1990, for INTERNATIONAL presence.
           WGK INTERNATIONAL was established for the export of Agricultural products
          in the year 1998. The Company has travelled a long way since then, maintaining
          clarity and transparency with buyers and adhering to strict quality controls.
        </p>

        {/* Centered Button */}
        <button       onClick={gotoweb}
 className="px-8 py-4 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-full text-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
          Learn More
        </button>
      </div>

      {/* Subtle animated scroll arrow for user experience */}
      <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
        <div className="animate-bounce text-white text-2xl">↓</div>
      </div>
    </div>
  );
};

export default WelcomeSection;
