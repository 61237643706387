import { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';

import { useAuthLoginMutation ,useInstructorLoginMutation} from "../../store/services/authService"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setAdminToken, setInstructorToken } from "../../store/reducers/authReducer"
import HomeNav from "../../components/CommonCompo/HomeNav";


const InstructorLogin = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        password: ''
    })

    const [errorMessage, setErrorMessage] = useState('');



    const handleInputs = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const [login, response] = useInstructorLoginMutation();
    console.log('my response', response)

    const errors = response?.error?.data?.errors ? response?.error?.data?.errors : [];

    const adminLoginFunction = e => {
        e.preventDefault();
        login(state);
    }
    const dispatch = useDispatch();
    useEffect(() => {
        // if(response.isSuccess) {
        //     localStorage.setItem('admin-token', response?.data?.token);
        //     dispatch(setAdminToken(response?.data?.token));
        //     navigate('/dashboard/products');
        // }
        if (response.isSuccess) {
            const { token, instructor } = response.data;
            console.log("this re sdata ",response.data)
            if (instructor) {
                localStorage.setItem('instructor-id', response.data.user._id);
                localStorage.setItem('instructor-token', token);
                dispatch(setInstructorToken(token));
                navigate('/dashboard/get/indianrice');
            } else {
                setErrorMessage('No instructor privileges');
                navigate('/auth/instructor-login');

            }
        }
    }, [response.isSuccess])
    return (
        <div className="bg-black1 h-screen flex justify-center items-center">
            {/* <HomeNav/> */}
            <form className="bg-black2 p-5 w-10/12 sm:w-8/12 md:w-6/12 lg:w-3/12 rounded" onSubmit={adminLoginFunction}>
                <h3 className="mb-4 text-white capitalize font-semibold text-lg">
                    
                    Dubai Admin dashboard login
                    
                    </h3>
                {errors.length > 0 && errors.map((error, key) => (
                    <div key={key}>
                        <p className="alert-danger">{error.msg}</p>
                    </div>
                ))}

                {errorMessage && (
                    <div>
                        <p className="alert-danger">{errorMessage}</p>
                    </div>
                )}

                <div className="mb-4 mt-4">
                    <input type="email" name="email" className="w-full bg-black1 p-4 rounded outline-none text-white" onChange={handleInputs} value={state.email} placeholder={t('instructor-login-email')} />
                </div>
                <div className="mb-4">
                    <input type="password" name="password" className="w-full bg-black1 p-4 rounded outline-none text-white" onChange={handleInputs} value={state.password} placeholder={t('instructor-login-password')} />
                </div>
                <div className="mb-4">
                    <input type="submit" value={response.isLoading ? 'Loading...' : t('instructor-login-buttton')} className="bg-indigo-600 w-full p-4 rounded text-white uppercase font-semibold cursor-pointer" />
                </div>
                <div className="text-white">
                        <p>
                            {/* Don't have an account ?  */}
                            {t('instructor-login-down-line')}

                            <span className="capitalize font-medium text-base text-white "><Link to="/auth/dubai-admin-register">
                       
                        {/* register */}
                        {t('instructor-login-down-line-register')}

                        </Link></span></p>
                    </div>
            </form>
        </div>
    )
}
export default InstructorLogin;