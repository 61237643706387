// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useGetMainCoursesQuery } from '../../store/services/CourseServiceMain';
// import Spinner from '../Spinner';
// import HomeNav from './HomeNav';

// const CoursesList = () => {
//   const { data = [], isFetching, error, isLoading } = useGetMainCoursesQuery();
//   console.log("req.data ", data);
//   const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

//   console.log("req.data ", data.length);

//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>Error fetching courses.</div>;

//   return (
//     <>
//       <HomeNav />
//       <div className="p-4 mt-[60px] bg-gray-200">
//         <h2 className="text-2xl font-semibold mb-4 bg-yellow-500 text-black p-4"> All Courses</h2>
//         {!isFetching ? data?.length > 0 ? (
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
//             {data?.map((course) => (
//               <Link to={`/coursesmainsingle/${course._id}`} key={course._id} className="block">
//                 <div className="p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300">
//                   <img
//                     src={`${serverBaseURL}${course.thumbnail_url}`}
//                     alt={course.title}
//                     className="w-full h-48 object-cover rounded-t-lg"
//                   />
//                   <div className="p-4">
//                     <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
//                     <p className="text-gray-600 mt-2">Category: {course.category}</p>
//                     <p className="text-gray-600">Price: ${course.price}</p>
//                   </div>
//                 </div>
//               </Link>
//             ))}
//           </div>
//         ) : 'No products!' : <Spinner />}
//       </div>
//     </>
//   );
// };

// export default CoursesList;





import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useGetMainCoursesQuery } from '../../store/services/CourseServiceMain';
import Spinner from '../Spinner';
import HomeNav from './HomeNav';
import FooterLms from './FooterLms';

const CoursesList = () => {
  const { t } = useTranslation();

  const { data = [], isFetching, error, isLoading } = useGetMainCoursesQuery();
  console.log("req.data ", data);
  const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

  console.log("req.data ", data.length);

  // Skeleton loader component for the loading state
  const SkeletonLoader = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="p-4 bg-white rounded-lg shadow border border-gray-200 animate-pulse">
          <div className="h-48 bg-gray-300 rounded-t-lg"></div>
          <div className="p-4">
            <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          </div>
        </div>
      ))}
    </div>
  );

  // Error UI component
  const ErrorUI = () => (
    <div className="flex flex-col items-center justify-center mt-8 ">
      <div className="text-red-500 text-xl font-semibold mb-4">
        
        {/* Error fetching courses */}
        
        {t('coursesmain-top-error-a')}

        </div>
      <p className="text-gray-600 mb-4">
        
        {/* There seems to be a network issue. Please try again later. */}
        {t('coursesmain-top-error-b')}
        
        </p>
      {/* Add more UI elements as needed, such as retry button */}
    </div>
  );

  if (isLoading) {
    return (
      <>
        <HomeNav />
        <div className="p-4 mt-[60px] bg-gray-200">
          <h2 className="text-2xl font-semibold mb-4 bg-yellow-500 text-black p-4">
            {/* All Courses */}
            {t('coursesmain-top-error-title')}

            </h2>
          <SkeletonLoader />
        </div>
        <FooterLms />
      </>
    );
  }

  if (error) {
    return (
      <>
        <HomeNav />
        <div className="p-4 mt-[60px] bg-gray-200 min-h-[80vh] h-auto ">
          <h2 className="text-2xl font-semibold mb-4 bg-yellow-500 text-black p-4">
            {/* All Courses */}
            {t('coursesmain-top-error-title')}
            </h2>
          <ErrorUI />
        </div>
        <FooterLms/>
      </>
    );
  }

  return (
    <>
      <HomeNav />
      <div className="p-4 mt-[60px] bg-gray-200">
        <h2 className="text-2xl font-semibold mb-4 bg-yellow-500 text-black p-4">
          {/* All Courses */}
          {t('coursesmain-top-error-title')}
          </h2>
        {!isFetching ? (
          data?.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {data?.map((course) => (
                <Link to={`/coursesmainsingle/${course._id}`} key={course._id} className="block">
                  <div className="p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300">
                    <img
                      src={`${serverBaseURL}${course.thumbnail_url}`}
                      alt={course.title}
                      className="w-full h-48 object-cover rounded-t-lg"
                    />
                    <div className="p-4">
                      <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                      <p className="text-gray-600 mt-2">
                        {/* Category: {course.category} */}
                        {t('coursesmain-top-error-catagory')}: {course.category}
                        
                        </p>
                      <p className="text-gray-600">{t('price')}{course.price}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            'No products!'
          )
        ) : (
          <Spinner />
        )}
      </div>
      <FooterLms />
    </>
  );
};

export default CoursesList;

