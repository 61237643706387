




import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import {
    useGetCategoryByNameQuery,
    useGetAllCoffeeTypesByCategoryQuery,
    // useGetProductByIdQuery,
    useUpdateProductMutation,
    useGetProductForCoffeeForEditQuery,
    useCOffeeUpdateMutation
} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";

const EditProduct = () => {
    const { id } = useParams(); // Get product ID from URL params
    const [name, setName] = useState('Coffee');
    const [categoryId, setCategoryId] = useState(null);

    // Fetch categories
    const { data: categories, isFetching: isFetchingCategories } = useGetCategoryByNameQuery(name, {
        skip: !name,
    });
    console.log("categories",categories)

 

    // Fetch coffee types based on the selected category
    const { data: coffeeTypes = [], isFetching: isFetchingCoffeeTypes } = useGetAllCoffeeTypesByCategoryQuery(categoryId, {
        skip: !categoryId,
    });
    console.log("coffeeTypes",coffeeTypes)

    // Fetch product details by ID
    const { data: product, isFetching: isFetchingProduct } = useGetProductForCoffeeForEditQuery(id);

    console.log("product",product)
    // State management
    const [state, setState] = useState({
        name: '',
        // price: 0,
        category: '',
        image1: '',
        coffeeType: '',
        pcode: '',
        containerCapacity: {
            twentyFoot: '',
            fortyFoot: ''
        },
        newCropMonths: '',
        grade: '',
        sieve: '',
        screen: '',
        pb: '',
        triage: '',
        blackSeeds: '',
        moisture: '',
        packaging: ''
    });
    const [preview, setPreview] = useState({
        image1: ''
    });
    const [value, setValue] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateProduct, { isLoading: isUpdating ,isSuccess },response] = useCOffeeUpdateMutation();
console.log("isUpdating",isUpdating)
console.log("response",response)
    useEffect(() => {
        if (product) {
            // Prepopulate the form with product details
            setState({
                ...product,
                containerCapacity: product.containerCapacity || {
                    twentyFoot: '',
                    fortyFoot: ''
                }
            });
            setValue(product.description || '');
            setCategoryId(product.category);
        }
    }, [product]);

    useEffect(() => {
        if (categories?._id) {
            setCategoryId(categories._id);
        }
    }, [categories]);

    const handleInput = e => {
        const { name, value } = e.target;
        if (name === 'twentyFoot' || name === 'fortyFoot') {
            setState({ ...state, containerCapacity: { ...state.containerCapacity, [name]: value } });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);

        updateProduct({ id, formData });
    };

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSuccess("Product updated successfully"));
            navigate('/dashboard/teacher');
        }
    }, [isSuccess, dispatch, navigate]);

    // if (isFetchingProduct || isFetchingCategories || isFetchingCoffeeTypes) return <Spinner />;

    if (isFetchingProduct || isFetchingCategories || isFetchingCoffeeTypes) {
        return (
            <WrapperTeacher>
                <ScreenHeaderTeacher>
                    <Link to="/dashboard/teacher" className="btn-dark">
                        <i className="bi bi-arrow-left-short"></i> back to Product List
                    </Link>
                </ScreenHeaderTeacher>
                <Toaster position="top-right" reverseOrder={true} />
                <Spinner />
                <Skeleton />
            </WrapperTeacher>
        );
    }

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher" className="btn-dark bg-blue-500 hover:bg-red-500 ">
                    <i className="bi bi-arrow-left-short"></i> back to Product List
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3 bg-yellow-300 ">
                <form className="w-full xl:w-8/12 p-3" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap ">
                        <div className="w-full p-3">
                            <label htmlFor="name" className="label">name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="name..."
                                onChange={handleInput}
                                value={state.name}
                            />
                        </div>
                        {/* <div className="w-full p-3">
                            <label htmlFor="price" className="label">Price</label>
                            <input
                                type="number"
                                name="price"
                                className="form-control"
                                id="price"
                                placeholder="Price..."
                                onChange={handleInput}
                                value={state.price}
                            />
                        </div> */}
                        <div className="w-full p-3">
                            <label htmlFor="category" className="label">Category</label>
                            <select
                                name="category"
                                className="form-control"
                                id="category"
                                onChange={e => setCategoryId(e.target.value)}
                                value={categoryId}
                            >
                                <option value="">Select Category</option>
                                {categories ?(
                                    <option key={categories._id} value={categories._id}>{categories.name}</option>
                                ) : <p>no found</p>}
                            </select>
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="coffeeType" className="label">Coffee Type</label>
                            <select
                                name="coffeeType"
                                className="form-control"
                                id="coffeeType"
                                onChange={handleInput}
                                value={state.coffeeType}
                            >
                                <option value="">Select Coffee Type</option>
                                {coffeeTypes?.map(type => (
                                    <option key={type._id} value={type._id}>{type.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="pcode" className="label">Product Code</label>
                            <input
                                type="text"
                                name="pcode"
                                className="form-control"
                                id="pcode"
                                placeholder="Product Code..."
                                onChange={handleInput}
                                value={state.pcode}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="containerCapacity" className="label">Container Capacity (Twenty Foot)</label>
                            <input
                                type="text"
                                name="twentyFoot"
                                className="form-control"
                                id="twentyFoot"
                                placeholder="Twenty Foot Capacity..."
                                onChange={handleInput}
                                value={state.containerCapacity.twentyFoot}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="containerCapacity" className="label">Container Capacity (Forty Foot)</label>
                            <input
                                type="text"
                                name="fortyFoot"
                                className="form-control"
                                id="fortyFoot"
                                placeholder="Forty Foot Capacity..."
                                onChange={handleInput}
                                value={state.containerCapacity.fortyFoot}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="newCropMonths" className="label">New Crop Months</label>
                            <input
                                type="text"
                                name="newCropMonths"
                                className="form-control"
                                id="newCropMonths"
                                placeholder="New Crop Months..."
                                onChange={handleInput}
                                value={state.newCropMonths}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="grade" className="label">Grade</label>
                            <input
                                type="text"
                                name="grade"
                                className="form-control"
                                id="grade"
                                placeholder="Grade..."
                                onChange={handleInput}
                                value={state.grade}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="sieve" className="label">Sieve</label>
                            <input
                                type="text"
                                name="sieve"
                                className="form-control"
                                id="sieve"
                                placeholder="Sieve..."
                                onChange={handleInput}
                                value={state.sieve}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="screen" className="label">Screen</label>
                            <input
                                type="text"
                                name="screen"
                                className="form-control"
                                id="screen"
                                placeholder="Screen..."
                                onChange={handleInput}
                                value={state.screen}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="pb" className="label">PB</label>
                            <input
                                type="text"
                                name="pb"
                                className="form-control"
                                id="pb"
                                placeholder="PB..."
                                onChange={handleInput}
                                value={state.pb}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="triage" className="label">Triage</label>
                            <input
                                type="text"
                                name="triage"
                                className="form-control"
                                id="triage"
                                placeholder="Triage..."
                                onChange={handleInput}
                                value={state.triage}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="blackSeeds" className="label">Black Seeds</label>
                            <input
                                type="text"
                                name="blackSeeds"
                                className="form-control"
                                id="blackSeeds"
                                placeholder="Black Seeds..."
                                onChange={handleInput}
                                value={state.blackSeeds}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="moisture" className="label">Moisture</label>
                            <input
                                type="text"
                                name="moisture"
                                className="form-control"
                                id="moisture"
                                placeholder="Moisture..."
                                onChange={handleInput}
                                value={state.moisture}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="packaging" className="label">Packaging</label>
                            <input
                                type="text"
                                name="packaging"
                                className="form-control"
                                id="packaging"
                                placeholder="Packaging..."
                                onChange={handleInput}
                                value={state.packaging}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label">Product Image</label>
                            <input
                                type="file"
                                name="image1"
                                id="image1"
                                className="form-control"
                                onChange={imageHandle}
                            />
                            {preview.image1 && <ImagesPreview url={preview.image1} heading="Image 1" image={preview.image1} />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">Description</label>
                            <ReactQuill value={value} onChange={setValue} />
                        </div>
                        <div className="w-full p-3">
                            <button type="submit" className="btn-primary bg-blue-500 p-4 rounded hover:bg-red-500" disabled={isUpdating}>
                                {isUpdating ? "Updating..." : "Update"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </WrapperTeacher>
    );
};

export default EditProduct;











// Skeleton.js
const Skeleton = () => (
    <div className="p-3 space-y-4">
        {Array.from({ length: 12 }).map((_, index) => (
            <div key={index} className="animate-pulse bg-gray-200 h-6 rounded"></div>
        ))}
    </div>
);

// export default Skeleton;