import { useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from '../assets/images/logoteacher.png'


const Sidebar = ({ side, closeSidebar }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);


  const handleLanguageChange = (lng) => {
    console.log("lng",lng)

    i18n.changeLanguage(lng);
    console.log("lng",lng)
    setIsLangDropdownOpen(false); // Close the dropdown after selecting a language
    // setIsOpen(!isOpen);

  };

  
  // Toggle language dropdown
  const handleLangDropdownToggle = () => {
    setIsLangDropdownOpen(!isLangDropdownOpen);
  };

  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen bg-gray-800 z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="bg-white p-4  ">
        {/* <img src={logo} alt="logo" className="h-[50px] "  /> */}
        <h2 className="h-[50px] px-[20%]">Mohsan kah</h2>
      </div>
      <ul className="mt-4">
        {/* <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher" className="text-base capitalize">
            Cources
          </Link>
        </li> */}

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/get/indianrice" className="text-base capitalize">
            Create Product
          </Link>
        </li>

        {/* <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/get/indianspices" className="text-base capitalize">
            Indian Spices
          </Link>
        </li> */}

        {/* <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/get/indianoilseeds" className="text-base capitalize">
            Indian Oil Seeds
          </Link>
        </li> */}

        
        {/* <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/tvideo" className="text-base capitalize">
            Video

          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/tvideo" className="text-base capitalize">
            orders
          </Link>
        </li> */}


        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-people-fill mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/search/indianrice" className="text-base capitalize">
            Search Product 
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher/tcategories" className="text-base capitalize">
            categories
          </Link>
        </li>

        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-gray-600">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/dashboard/teacher/tcategories/type" className="text-base capitalize">
            categories Type
          </Link>
        </li>









      </ul>
    </div>
  );
};
export default Sidebar;
