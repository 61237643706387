import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;
const productServiceTeacher = createApi({
  reducerPath: 'productsTeacher',
  tagTypes: 'products',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiUrl}/api/teacher/`,
    // baseUrl: 'http://localhost:5000/api/teacher/',
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.instructorToken;
      console.log(token)
      headers.set('authorization', token ? `Bearer ${token}` : '');
      return headers;
    }
  }),
  endpoints: (builder) => {
    return {

      // TeacherController for dubai  for create catagory a
      createCatagoryMain: builder.mutation({
        query: (formData) => {
          return {
            url: '/create/catagory/main/admin',
            method: 'POST',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),


      // TeacherController for duabi for catagory get with page  all a
      getallCatagoryMain: builder.query({
        query: ({ page }) => {
          return {
            url: `/get/all/catagory/main/admin/${page}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),



      //dashboard/create/indianrice  and  /dashboard/edit/indianrice/66dc19e6af6b04324f3f757a
      // TeacherController for duabi catagory get all  with out page  for type save a 
      getallCatagoryForTypeAdmin: builder.query({
        query: () => {
          return {
            url: 'get/all/catagory/main/admin/create/fortype',
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),



      //update catgory dubai get and for update 
      getCategoryById: builder.query({
        query: (id) => `/categories/forupedit/get/admin/${id}`, // Fetch category by ID
      }),

      //update catgory dubai
      updateCategory: builder.mutation({
        query: ({ id, formData }) => ({
          url: `/categories/forupdate/admin/${id}`,
          method: 'PUT',
          body: formData,
        }),
      }),


      //dubai get catagory by name in createprodt
      getCategoryByName: builder.query({
        query: (name) => `/category/${name}`,
      }),



      //dashboard/create/indianrice    and /dashboard/edit/indianrice/66dc19e6af6b04324f3f757a
      //dubai get all types base on parent catagry id base in create procut /dashboard/edit/indianrice/66dc19e6af6b04324f3f757a
      getAllCoffeeTypesByCategory: builder.query({
        query: (categoryId) => `/categories/${categoryId}/types`,
      }),




      // dubai  get all product in admin panel  working  /dashboard/get/indianrice
      getProductsAllcoffee: builder.query({
        query: ({ page }) => {
          return {
            url: `/get/all/products/admin/${page}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),


      //dubai delete main admin panel  product form admin /dashboard/get/indianrice
      deleteCoffeeProduct: builder.mutation({
        query: (productId) => ({
          url: `/products/coffee/delete/${productId}`,
          method: 'DELETE',
        }),
      }),


      // dubai get single product id base for create  product /dashboard/edit/indianrice/66dc19e6af6b04324f3f757a
      getProductForCoffeeForEdit: builder.query({
        query: (id) => `/products/coffee/${id}`, // Endpoint for getting product by ID
      }),


      //dubai product update /dashboard/edit/indianrice/66dc19e6af6b04324f3f757a
      cOffeeUpdate: builder.mutation({
        query: ({ formData, id }) => {
          return {
            url: `/products/coffee/update/${id}`,
            method: 'PUT',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),


      //dashboard/create/indianrice
      // TeacherController create product for cofee for dubai dashboard/create/indianrice
      cProductoilseed: builder.mutation({
        query: (formData) => {
          return {
            url: '/create/product/oilseed',
            method: 'POST',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),


      searchCourses: builder.query({
        query: (searchTerm) => `/course/admin/search?${searchTerm}`,
      }),
      




      updateCategoryTypeById: builder.mutation({
        query: ({ id, formData }) => ({
          url: `/categories/type/forupdate/admin/${id}`,
          method: 'PUT',
          body: formData,
        }),
      }),


      //up good proper//up good proper//up good proper//up good proper//up good proper
      //up good proper//up good proper//up good proper//up good proper//up good proper
      //up good proper//up good proper//up good proper//up good proper//up good proper
      //up good proper//up good proper//up good proper//up good proper//up good proper
      //up good proper//up good proper//up good proper//up good proper//up good proper





      // dubai // Fetch all products based on the coffee type name for show user 
      getCategoryData: builder.query({
        query: (category) => `/coffee/${category}`,
      }),

      // Fetch 8 random coffee products with specific fields desk top

      getRandomCoffeeProducts: builder.query({
        query: () => '/coffee-products/random', // Your API endpoint
        transformResponse: (response) => response.map(product => ({
          id: product._id,  // Map the product ID from _id
          name: product.name,  // Get the product name
          thumbnailUrl: product.thumbnail_url,  // Get the thumbnail URL
        })),
      }),





      // TeacherController dubai controller for create catagory types  b
      catagoryCreataTypeadmin: builder.mutation({
        query: (formData) => {
          return {
            url: '/create/catagorytype/type/admin',
            method: 'POST',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),



      // TeacherController for duabi for catagory type get  b
      getallCatagoryTypeMain: builder.query({
        query: ({ page }) => {
          return {
            url: `/get/all/type/catagorytype/type/admin/${page}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),


      // TeacherController for duabi for catagory type get  with out page b
      getallCatagoryTypeMainWithOutpage: builder.query({
        query: () => {
          return {
            url: '/get/all/type/catagorytype/withoutpage',
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),





      // TeacherController create product for cofee for dubai
      cProduct: builder.mutation({
        query: (formData) => {
          return {
            url: '/createcoursetech',
            method: 'POST',
            body: formData,
          }
        },
        // invalidatesTags: ['products']
      }),











      // getCoffeeProductDetails: builder.query({
      //   query: (productId) => `/coffee/products/${productId}`, // Adjust endpoint as necessary
      // }),









      //dubai type get and update 
      getCategoryTypeById: builder.query({
        query: (id) => `/categories/type/forupedit/get/admin/${id}`,
      }),



    











      // TeacherController
      deleteVideo: builder.mutation({
        query: ({ id, videoId }) => ({
          url: `courses/${id}/videos/${videoId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['products'], // Assuming the 'products' tag is also tied to video data

      }),

      // TeacherController
      updateProduct: builder.mutation({
        query: data => {
          return {
            url: '/courseupdatetech',
            method: 'PUT',
            body: data
          }
        },
        invalidatesTags: ['products']
      }),


      // TeacherController
      getProducts: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/getcoursesallteacher/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getProductsVideo: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/getcoursesallteacher/video/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getStudents: builder.query({
        query: ({ page, teacherId }) => {
          return {
            url: `/allstundetpaidteach/${page}?teacherId=${teacherId}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController
      getProduct: builder.query({
        query: id => {
          return {
            url: `/couresesingletech/${id}`,
            method: 'GET'
          }
        },
        providesTags: ['products']
      }),

      // TeacherController all catagory 
      allCategories: builder.query({
        query: () => {
          return {
            url: "allcategoriestech",
            method: "GET",
          };
        },
      }),

      // TeacherController  
      deleteProduct: builder.mutation({
        query: id => {
          return {
            url: `/deletecoursetech/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['products']
      }),

      uploadVideo: builder.mutation({
        query: (formData) => ({
          url: '/upload-video',
          method: 'POST',
          body: formData,
        }),
      }),


      getCourse: builder.query({
        query: (id) => `coursesvideoalltech/${id}`,
      }),


    }
  }
})
export const {
  useCreateCatagoryMainMutation,
  useGetallCatagoryMainQuery,
  useGetallCatagoryForTypeAdminQuery,
  useCatagoryCreataTypeadminMutation,
  useGetallCatagoryTypeMainQuery,
  useGetProductsAllcoffeeQuery,
  useGetallCatagoryTypeMainWithOutpageQuery,
  useGetCategoryByNameQuery,
  useGetAllCoffeeTypesByCategoryQuery,
  useGetProductForCoffeeForEditQuery,
  useCOffeeUpdateMutation,
  useGetCategoryDataQuery,
  useDeleteCoffeeProductMutation,
  useGetCategoryByIdQuery, useUpdateCategoryMutation,
  useGetCategoryTypeByIdQuery, useUpdateCategoryTypeByIdMutation,
  useCProductMutation,
  useCProductoilseedMutation,
  useAllCategoriesQuery, useGetCourseQuery, useDeleteVideoMutation, useUploadVideoMutation, useDeleteProductMutation, useUpdateProductMutation,
  useGetProductsQuery,
  useGetRandomCoffeeProductsQuery,
useSearchCoursesQuery,
  useGetProductsVideoQuery, useGetStudentsQuery, useGetProductQuery } = productServiceTeacher;
export default productServiceTeacher