




import { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useCreatePackageMutation } from '../../store/services/productService'; // Adjust import path
import Wrapper from './Wrapper';
import ScreenHeader from '../../components/ScreenHeader';

const CreatePackage = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    package_name: '',
    box_name: '',

    package_type: 'monthly',
    total_fees: 0,
    payment_plan: 'full',
    is_active: true,
    image1: null,
    courses: [], // Use an array for dynamic courses
    installments: [], // Use an array for dynamic installments
    expiration_date: '', // Initialize expiration date as an empty string
  });

  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();
  const [createPackage, { isLoading, isError, isSuccess, error }] = useCreatePackageMutation();

  const handleInput = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = e => {
    if (e.target.files.length !== 0) {
      setState(prevState => ({ ...prevState, image1: e.target.files[0] }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setState(prevState => ({ ...prevState, image1: null }));
      setPreview(null);
    }
  };

  const handleAddCourse = () => {
    setState(prevState => ({ ...prevState, courses: [...prevState.courses, ''] }));
  };

  const handleCourseChange = (index, value) => {
    const updatedCourses = [...state.courses];
    updatedCourses[index] = value;
    setState(prevState => ({ ...prevState, courses: updatedCourses }));
  };

  const handleRemoveCourse = index => {
    const updatedCourses = state.courses.filter((_, i) => i !== index);
    setState(prevState => ({ ...prevState, courses: updatedCourses }));
  };

  const handleAddInstallment = () => {
    setState(prevState => ({
      ...prevState,
      installments: [
        ...prevState.installments,
        { installment_number: '', amount: '', due_date: '', paid_date: '', status: '' }
      ]
    }));
  };

  const handleInstallmentChange = (index, field, value) => {
    const updatedInstallments = [...state.installments];
    updatedInstallments[index] = { ...updatedInstallments[index], [field]: value };
    setState(prevState => ({ ...prevState, installments: updatedInstallments }));
  };

  const handleRemoveInstallment = index => {
    const updatedInstallments = state.installments.filter((_, i) => i !== index);
    setState(prevState => ({ ...prevState, installments: updatedInstallments }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('box_name', state.box_name);
    formData.append('package_name', state.package_name);
    formData.append('package_type', state.package_type);
    formData.append('total_fees', state.total_fees);
    formData.append('payment_plan', state.payment_plan);
    formData.append('is_active', state.is_active);
    if (state.image1) {
      formData.append('image1', state.image1);
    }
    formData.append('courses', JSON.stringify(state.courses));
    formData.append('installments', JSON.stringify(state.installments));
    formData.append('expiration_date', state.expiration_date);

    try {
      const response = await createPackage(formData).unwrap();
      toast.success(response.msg);
      navigate('/dashboard/create/admin/package'); // Redirect after successful creation
    } catch (error) {
      toast.error(error?.data?.msg || 'Something went wrong');
    }
  };

  return (
    <Wrapper>
      <ScreenHeader>
        <Link to="/dashboard/get/admin/package" className="btn-dark">Back</Link>

        <Toaster position="top-right" />
      </ScreenHeader>
      <div className="container mx-auto p-4 bg-yellow-400 text-black">
        <Toaster position="top-right" />
        <h1 className="text-2xl font-semibold mb-4">
          {/* Create New Student Package */}
          {t('admin-create-package-pakg_tittle_top')}

          </h1>
        <div className="flex flex-wrap -mx-2">
          <form className="w-full lg:w-8/12 px-2" onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-2 mb-4">


              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <label htmlFor="package_name" className="block text-sm font-medium mb-1">
                  {/* Package Name */}
                  {t('admin-create-package-package_name')}
                  </label>


                <input
                  type="text"
                  name="package_name"
                  id="package_name"
                  onChange={handleInput}
                  value={state.package_name}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </div>
              {/* hello start */}
              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <label htmlFor="box_name:" className="block text-sm font-medium mb-1">
                  {/* Box Name */}
                  
                  {t('admin-create-package-box_name')}
                  </label>

                <input
                  type="text"
                  name="box_name"
                  id="box_name"
                  onChange={handleInput}
                  value={state.box_name}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"

                />
              </div>
              {/* heloend */}


              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <label htmlFor="package_type" className="block text-sm font-medium mb-1">
                  {/* Package Type */}
                  {/* package_type */}
                  {t('admin-create-package-package_type')}

                  </label>
                <select
                  name="package_type"
                  id="package_type"
                  onChange={handleInput}
                  value={state.package_type}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                >
                  <option value="monthly">Monthly</option>
                  <option value="6-month">6-Month</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <label htmlFor="total_fees" className="block text-sm font-medium mb-1">
                  {/* Total Fees */}
                  {t('admin-create-package-total_fees')}

                  </label>
                <input
                  type="number"
                  name="total_fees"
                  id="total_fees"
                  onChange={handleInput}
                  value={state.total_fees}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <label htmlFor="payment_plan" className="block text-sm font-medium mb-1">
                  {/* Payment Plan */}
                  {t('admin-create-package-payment_plan')}

                  
                  </label>
                <select
                  name="payment_plan"
                  id="payment_plan"
                  onChange={handleInput}
                  value={state.payment_plan}
                  className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                >
                  <option value="full">
                    Full
                    {/* {t('admin-create-package-package_created')} */}

                    </option>
                  <option value="installment">
                    Installment
                    
                    {/* {t('admin-create-package-installments')} */}

                    </option>
                </select>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                name="is_active"
                id="is_active"
                checked={state.is_active}
                onChange={() => setState(prevState => ({ ...prevState, is_active: !prevState.is_active }))}
                className="mr-2"
              />
              <label htmlFor="is_active" className="text-sm font-medium">
                {/* Active */}
                
                {t('admin-create-package-active')}

                </label>
            </div>
            <div className="mb-4">
              <label htmlFor="image1" className="block text-sm font-medium mb-1">
                {/* Package Image */}
                
                {t('admin-create-package-package_image')}

                </label>
              <input
                type="file"
                name="image1"
                id="image1"
                onChange={handleFileChange}
                className="w-full border-gray-300 rounded-md shadow-sm"
              />
              {preview && <img src={preview} alt="Image Preview" className="mt-2 w-32 h-32 object-cover" />}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                {/* Courses */}
                
                {t('admin-create-package-courses')}

                </label>
              {state.courses.map((course, index) => (
                <div key={index} className="flex mb-2 items-center">
                  <input
                    type="text"
                    value={course}
                    onChange={e => handleCourseChange(index, e.target.value)}
                    className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                    // placeholder="Course ID"
                    placeholder={t('admin-create-package-courses_id')}


                    
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveCourse(index)}
                    className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                  >
                    {/* Remove */}
                    {t('admin-create-package-remove_course')}

                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddCourse}
                className="bg-indigo-600 text-white px-4 py-2 rounded"
              >
                {/* Add Course */}

                {t('admin-create-package-add_course')}

              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                {/* Installments */}
                
                {t('admin-create-package-installments')}

                </label>
              {state.installments.map((installment, index) => (
                <div key={index} className="mb-4 p-4 border border-gray-300 rounded">
                  <div className="flex mb-2">
                    <label className="w-1/3">
                    {/* Number */}
                    
                    {t('admin-create-package-Number')}

                    </label>
                    <input
                      type="text"
                      value={installment.installment_number}
                      onChange={e => handleInstallmentChange(index, 'installment_number', e.target.value)}
                      className="w-2/3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      placeholder="Installment Number"
                    />
                  </div>
                  <div className="flex mb-2">
                    <label className="w-1/3">
                    
                    {/* Amount */}
                    
                    {t('admin-create-package-Amount')}

                    </label>
                    <input
                      type="number"
                      value={installment.amount}
                      onChange={e => handleInstallmentChange(index, 'amount', e.target.value)}
                      className="w-2/3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      placeholder="Amount"
                    />
                  </div>
                  <div className="flex mb-2">
                    <label className="w-1/3">
                    
                    {/* Due Date */}
                    
                    {t('admin-create-package-Due_Date')}

                    </label>
                    <input
                      type="date"
                      value={installment.due_date}
                      onChange={e => handleInstallmentChange(index, 'due_date', e.target.value)}
                      className="w-2/3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      placeholder="Due Date"
                    />
                  </div>
                  <div className="flex mb-2">
                    <label className="w-1/3">
                    
                    {/* Paid Date */}
                    {t('admin-create-package-Paid_Date')}

                    </label>
                    <input
                      type="date"
                      value={installment.paid_date}
                      onChange={e => handleInstallmentChange(index, 'paid_date', e.target.value)}
                      className="w-2/3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      placeholder="Paid Date"
                    />
                  </div>
                  <div className="flex mb-2">
                    <label className="w-1/3">
                    
                    {/* Status */}
                    {t('admin-create-package-status')}

                    </label>
                    <input
                      type="text"
                      value={installment.status}
                      onChange={e => handleInstallmentChange(index, 'status', e.target.value)}
                      className="w-2/3 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                      placeholder="Status send active here pass must active"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveInstallment(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    {/* Remove Installment */}
                    {t('admin-create-package-remove_installment')}


                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddInstallment}
                className="bg-indigo-600 text-white px-4 py-2 rounded"
              >
                {/* Add Installment */}
                {t('admin-create-package-add_installment')}


              </button>
            </div>
            <div className="mb-4">
              <label htmlFor="expiration_date" className="block text-sm font-medium mb-1">
                
                {/* Expiration Date */}
                {t('admin-create-package-expiration_date')}

                
                </label>
              <input
                type="date"
                name="expiration_date"
                id="expiration_date"
                onChange={handleInput}
                value={state.expiration_date}
                className="w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2 px-4 rounded-md text-white ${isLoading ? 'bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            >
              {isLoading ? t('admin-create-package-creating_package')
 : t('admin-create-package-create_package')}
            </button>
            {isError && <p className="mt-2 text-red-600">{error?.data?.msg ||    t('admin-create-package-error_occurred')         }</p>}
            {isSuccess && <p className="mt-2 text-green-600">
              {/* Package created successfully! */}
              {t('admin-create-package-package_created')}
              </p>}
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default CreatePackage;



