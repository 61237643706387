import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { useSearchParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Nav from "../../components/home/Nav";
import Header from "../../components/home/Header";
import AccountList from "../../components/home/AccountList";
import { emptyCart } from "../../store/reducers/cartReducer";
import HomeNav from "../../components/CommonCompo/HomeNav";

const Dashboard = () => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.authReducer);
  const [params] = useSearchParams();
  const id = params.get("session_id");
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (isSuccess) {
  //     localStorage.removeItem("cart");
  //     toast.success(data.msg);
  //     dispatch(emptyCart());
  //     navigate("/user");
  //   }
  // }, [isSuccess]);
  return (
    <>
      {/* <Nav /> */}
      <HomeNav className="mt-[70px]"/>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="mt-[70px]">
        <Header>
          {/* my account */}
          
          {t('user-dashboard-header')}
          </Header>
        <div className="my-container mt-[40px]">
          <div className="flex flex-wrap -mx-6">
            <div className="w-full md:w-4/12 p-6">
              <AccountList />
            </div>
            <div className="w-full md:w-8/12 p-6">
              <h1 className="heading">
                {/* name */}
                
                {t('user-dashboard-name')}
                </h1>
              <span className="block mt-3 capitalize font-medium text-sm">
                {user?.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
