import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const apiUrl = process.env.REACT_APP_API_URL;

const authService = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api/'
        baseUrl: `${apiUrl}/api/`
    }),
    endpoints: (builder) => {
       return {
           authLogin: builder.mutation({
               query: (loginData) => {
                   return {
                       url: 'login',
                       method: 'POST',
                       body: loginData
                   }
               }
           }),

        //    teacherlogin
        instructorLogin: builder.mutation({
            query: (loginData) => {
                return {
                    url: 'logininstructor',
                    method: 'POST',
                    body: loginData
                }
            }
        }),
        instructorRegisters: builder.mutation({
            query: (data) => {
               return {
                   url: '/instructorregisters',
                   method: 'POST',
                   body: data
               }
            },
        }),

        //   student reginster
        studentRegister: builder.mutation({
            query: data => {
                return {
                    url: 'studentregister',
                    method: 'POST',
                    body: data
                }
            }
           }),
// student login 
studentLogin: builder.mutation({
    query: loginData => {
        return {
            url: '/studentlogin',
            method: 'POST',
            body: loginData
        }
    }
   }),





           userRegister: builder.mutation({
            query: data => {
                return {
                    url: '/register',
                    method: 'POST',
                    body: data
                }
            }
           }),
           userLogin: builder.mutation({
            query: loginData => {
                return {
                    url: '/login',
                    method: 'POST',
                    body: loginData
                }
            }
           })
       }
    }
});
export const {useAuthLoginMutation,useInstructorRegistersMutation,useStudentLoginMutation, useInstructorLoginMutation,useUserRegisterMutation,useStudentRegisterMutation, useUserLoginMutation} = authService
export default authService