
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import HomeNav from '../HomeNav';
import { Link, useParams } from 'react-router-dom';
import { useGetVideosByCourseIdQuery, useGetVideoByIdQuery, useGetCourseByIdQuery } from '../../../store/services/CourseServiceMain'; // Adjust import to include course query
import Footer from '../FooterLms';

const VideoPage = () => {
  const { t } = useTranslation();

  const { courseid, videoid } = useParams();
  const userDataString = localStorage.getItem('user');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const userid = userData ? userData._id : null; // Get userId from localStorage
  const [selectedVideo, setSelectedVideo] = useState("");
  const [message, setMessage] = useState('');
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(''); // Store the video title
  const [isVideoFree, setIsVideoFree] = useState(false); // Store if the video is free

  const { data: courseData } = useGetCourseByIdQuery(courseid); // Fetch course data
  const { data: courseVideos } = useGetVideosByCourseIdQuery(courseid);
  const { data: videoData, error: videoError, isFetching  } = useGetVideoByIdQuery({ courseid, videoid, userid });



  console.log("courseData",courseData)
console.log("courseVideos",courseVideos)
console.log("videoData",videoData)
console.log("isVideoFree",isVideoFree)
console.log("selectedVideo",selectedVideo)


  // Replace with your server base URL
  const serverBaseURL = 'https://api.wgktrade.com'; // Change this as needed

  useEffect(() => {
    if (videoError) {
      handleVideoError(videoError);
    } else if (videoData) {
      handleVideoData(videoData);
    }
  }, [videoData, videoError, userid]);

  // const handleVideoError = (error) => {
  //   if (error.status === 403) {
  //     setMessage('Please pay for the course to view this video.');
  //   } else if (error.status === 401) {
  //     setMessage('Please log in to view this video.');
  //   } else {
  //     setMessage('Error fetching video.');
  //   }
  //   setSelectedVideo(null);
  // };


  const handleVideoError = (error) => {
    if (error.status === 403) {
      setMessage(t('please-pay-for-course'));
    } else if (error.status === 401) {
      setMessage(t('please-log-in'));
    } else {
      setMessage(t('error-fetching-video'));
    }
    setSelectedVideo(null);
  };
  
  

  const handleVideoData = (data) => {
    const { videos ,hasPaid} = data;

    if (!userid) {
      setMessage('Please log in to view this video.');
      setSelectedVideo(null);
    } else if (!videos.is_free && !hasPaid) {
      // console.log("!videos.is_free",!videos.is_free)
      // console.log("!hasPaid",!hasPaid)
      // console.log("!videos.is_free && !hasPaid ",!videos.is_free && !hasPaid)
      setMessage('Please pay for the course to view this video.');
      setSelectedVideo(null);
    } else {
      setSelectedVideo(`${serverBaseURL}${videos.video_url}`);
      setSelectedVideoTitle(videos.title); // Set the video title
      setIsVideoFree(videos.is_free); // Set if the video is free
      setMessage('');

       
    }
  };

  const handleVideoClick = (video) => {
    if (!userid) {
      setMessage('Please log in to view this video.');
      setSelectedVideo(null);
    } else if (!video.is_free && (!videoData || !videoData.hasPaid)) {
      console.log("!video.is_free",!video.is_free)
      console.log("!videoData",!videoData)
      // console.log("!videoData.hasPaid",!videoData.hasPaid)

      setMessage('Please pay for the course to view this video.');
      setSelectedVideo(null);
    } else {
      console.log("723")
      console.log("723",`${serverBaseURL}${video.video_url}`)

      setSelectedVideo(`${serverBaseURL}${video.video_url}`);
      setSelectedVideoTitle(video.title);
      setIsVideoFree(video.is_free);
      setMessage('');
    }
  };

  if (isFetching) return (
    <>
          <HomeNav />
          <div className='min-h-[80vh] h-auto mt-[96px]  '>Loading...</div>
          <Footer/>

    </>
  // <div>Loading...</div>

);


  return (
    <>
      <HomeNav />

      <div className="flex flex-col md:flex-row mt-[96px] min-h-[100vh] h-auto ">
        {/* Sidebar */}
        <div className="w-full md:w-1/3 bg-gray-100 p-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 96px)' }}>
          {courseData && (
            <div className="mb-4">
              <h2 className="text-xl font-bold">{courseData.title}</h2> {/* Display course title */}
            </div>
          )}

          {courseVideos && courseVideos.map((video) => (
            <div key={video._id} className="flex justify-between items-center p-2 mb-2 bg-white rounded shadow hover:bg-gray-200">
              <div className="font-bold">{video.title}</div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleVideoClick(video);
                }}
                className={`px-2 py-1 text-sm rounded ${video.is_free ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                  }`}
              >
                {video.is_free ? 'Free' : 'Paid'}
              </button>
            </div>
          ))}
        </div>

        {/* Video Player */}
        <div className="w-full md:w-2/3 p-4">
          {selectedVideoTitle && (
            <div className="mb-2 flex justify-between items-center">
              <h3 className="text-lg font-bold">{selectedVideoTitle}</h3> {/* Display selected video title */}
              <button
                className={`px-2 py-1 text-sm rounded ${isVideoFree ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                  }`}
              >
                {/* {isVideoFree ? 'Free' : 'Paid'} */}

                {isVideoFree ? t('coursemain-single-paid') : t('coursemain-single-free')}

                {/* {video.is_free ? t('coursemain-single-paid') : t('coursemain-single-free')} */}

              </button>
            </div>
          )}
          {message &&
            // <div className="flex flex-col items-center justify-center mx-auto p-4">
            //   <div className=" mb-4 text-center font-bold text-white bg-red-600 p-4">{message}</div>
            //   <div className="text-red-500 mb-4 text-center font-bold text-black">Content is locked, to register contact +212601938528 (wtsp)</div>
            //   <div className="text-red-500 mb-4 text-center font-bold text-black">If you are already registered,            <Link to="/login" className="text-blue-500 hover:text-black rounded-md"> log in.</Link>
            //  </div>
            //   <div className="text-red-500 mb-4 text-center">To register contact on +212601938528</div>
            //   <div className="mb-4">
            //     <button className="bg-blue-500 hover:bg-red-500 text-white px-4 py-2 rounded">
            //     <Link to="/register">
            //      Contact to Register
            //      {/* {t('video-contact-to-register-message')} */}

            //      </Link>
            //     </button>
            //   </div>
            // </div>

            <div className="flex flex-col items-center justify-center mx-auto p-4">
            <div className="mb-4 text-center font-bold text-white bg-red-600 p-4">
            {message}
            </div>
            <div className="text-red-500 mb-4 text-center font-bold">
              {t('video-content-locked-message')}
            </div>
            <div className="text-red-500 mb-4 text-center font-bold">
              {t('video-already-registered-message')}{' '}
              <Link to="/login" className="text-blue-500 hover:text-black rounded-md">
                {t('video-log-in-message')}
              </Link>
            </div>
            <div className="text-red-500 mb-4 text-center">
              {t('video-contact-register-message')}
            </div>
            <div className="mb-4">
              <button className="bg-blue-500 hover:bg-red-500 text-white px-4 py-2 rounded">
                <Link to="/register">{t('video-contact-to-register-message')}</Link>
              </button>
            </div>
          </div>

            
          }



{/* {message && <div className="text-red-500">{message}</div>} */}
          {selectedVideo && !message && (
            <video controls key={selectedVideo}>
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}















          {/* {selectedVideo && !message && (
  <div className="w-full">
    <iframe
      width="100%"
      height="315"
      src="https://www.youtube.com/embed/fL-0G-2b3Sg"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  </div>
)} */}


{/* {selectedVideo && !message && (
  <video controls className="w-full">
    <source src="http://localhost:5000/uploads/videos/your-video-file.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
)} */}


{/* {selectedVideo && !message && (
        <video ref={videoRef} controls className="w-full">
          Your browser does not support the video tag.
        </video>
      )} */}


          {/* {selectedVideo && !message && (
            <video controls className="w-full">
              {console.log("selectedVideo",selectedVideo)}
              <source src={selectedVideo  ===  "" ? "https://www.youtube.com/watch?v=fL-0G-2b3Sg": selectedVideo  } type="video/mp4" />

              Your browser does not support the video tag.
            </video>
          )} */}


        </div>
      </div>
      <Footer/>
    </>
  );
};

export default VideoPage;









