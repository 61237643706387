
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useCProductMutation ,useCatagoryCreataTypeadminMutation,useGetallCatagoryForTypeAdminQuery} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";


const CreatCategoryTypeTeacher = () => {
    const { t } = useTranslation(); // Initialize translation hook

    const {data = [], isFetching} = useGetallCatagoryForTypeAdminQuery();
    console.log("data datadatadatadata",data)
    const [value, setValue] = useState('');
    const [state, setState] = useState({
        name: '',
        category: '',

    });


    const handleInput = e => {
        setState({...state, [e.target.name]: e.target.value});
    };
    

    const [createNewProduct, response] = useCatagoryCreataTypeadminMutation();
    console.log('Your response', response);

    const createPro = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
    
    
    
        createNewProduct(formData);
    };
    
    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error?.data?.errors]);

 


     const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response.data.msg));
            console.log("this dcall  asdasd")
            navigate('/dashboard/teacher/tcategories/type');
        }
    }, [response?.isSuccess]);


    

    useEffect(() => {
        if(!response.isSuccess) {
            response?.error?.data?.errors.map(err => {
                toast.error(err.msg);
            });
        }
    }, [response?.error?.data?.errors]);




    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher/tcategories/type" className="btn-dark">
                    <i className="bi bi-arrow-left-short"></i>
                     Catagory Type List
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3">
                <form className="w-full xl:w-8/12 p-3" onSubmit={createPro}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="name" className="label">
                                Name
                                </label>
                            <input type="text" name="name" className="form-control" id="name" placeholder="name..." onChange={handleInput} value={state.name} />
                        </div>
                   
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label">
                                Categories
                                </label>
                            {!isFetching ? data?.length > 0 && (
                                <select name="category" id="categories" className="form-control" onChange={handleInput} value={state.category}>
                                    <option value="">
                                        Choose category
                                        </option>
                                    {data?.map(category => (
                                        <option value={category._id} key={category._id}>{category.name}</option>
                                    ))}
                                </select>
                            ) : <Spinner />}
                        </div>
                    
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">
                                Description
                                </label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>
                        <div className="w-full p-3">
                            <input type="submit" value={response.isLoading ? 'Loading...' : 'Save Product'} disabled={response.isLoading} className="btn btn-indigo" />

                        
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">

                </div>
            </div>
        </WrapperTeacher>
    );
};

export default CreatCategoryTypeTeacher;
