

// import React from 'react';

// // Example map image URL
// const mapImageUrl = 'https://www.publicdomainpictures.net/pictures/40000/velka/world-map-1391078299wMK.jpg';

// const InformationSection = () => {
//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="container mx-auto px-4">
//         {/* Main Heading */}
//         <h2 className="text-4xl font-bold mb-12 text-center">Our Global Reach and Regional Highlights</h2>

//         <div className="lg:flex lg:items-start lg:space-x-8">
//           {/* Left Side: Map Image */}
//           <div className="lg:w-1/2 mb-8 lg:mb-0 flex items-center justify-center">
//             <div className="relative w-full h-80">
//               {/* <img
//                 src={mapImageUrl}
//                 alt="World Map"
//                 className="w-full h-full object-cover rounded-lg shadow-lg"
//               /> */}
//                   <iframe
//               className="w-full h-full rounded-lg shadow-lg"
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34326.960849369905!2d55.30061283402954!3d25.264620811976506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cc9fd734caf%3A0x9caf12adcb822a37!2sGhanim%20Building!5e0!3m2!1sen!2s!4v1725719101871!5m2!1sen!2s"              allowFullScreen=""
//               loading="lazy"
//             ></iframe>







//             </div>
//           </div>

//           {/* Right Side: Information Boxes */}
//           <div className="lg:w-1/2 flex flex-col space-y-8">
//             <div className="flex flex-col h-full">
//               <div className="space-y-8">
//                 {/* Row 1 */}
//                 <div className="flex flex-col gap-8 lg:flex-row lg:gap-8">
//                   <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between w-full">
//                     <h3 className="text-xl font-semibold mb-2 text-center">THE AMERICAS</h3>
//                     <p className="text-gray-700 text-center">USA, Canada, Mexico, Guatemala</p>
//                   </div>
//                   <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between w-full">
//                     <h3 className="text-xl font-semibold mb-2 text-center">EUROPE</h3>
//                     <p className="text-gray-700 text-center">UK, Belgium, Denmark, Germany, Netherlands, Norway, Spain, Sweden, Turkey</p>
//                   </div>
//                 </div>
//                 {/* Row 2 */}
//                 <div className="flex flex-col gap-8 lg:flex-row lg:gap-8">
//                   <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between w-full">
//                     <h3 className="text-xl font-semibold mb-2 text-center">ASIA</h3>
//                     <p className="text-gray-700 text-center">Iraq, Jordan, Kuwait, Lebanon, Saudi Arabia, UAE</p>
//                   </div>
//                   <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between w-full">
//                     <h3 className="text-xl font-semibold mb-2 text-center">AFRICA</h3>
//                     <p className="text-gray-700 text-center">Algeria, Kenya, Egypt, Morocco, Tunisia</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default InformationSection;







import React from 'react';

// Updated map URL for your address
const mapEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34326.960849369905!2d55.30061283402954!3d25.264620811976506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cc9fd734caf%3A0x9caf12adcb822a37!2sGhanim%20Building!5e0!3m2!1sen!2s!4v1725719101871!5m2!1sen!2s';

const InformationSection = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        {/* Main Heading */}
        <h2 className="text-4xl font-extrabold mb-12 text-center text-gray-800">Our Global Reach and Regional Highlights</h2>

        <div className="lg:flex lg:items-start lg:space-x-8">
          {/* Left Side: Map */}
          <div className="lg:w-1/2 mb-8 lg:mb-0 flex items-center justify-center">
            <div className="relative w-full h-80 overflow-hidden rounded-lg shadow-lg border border-gray-300">
              <iframe
                className="w-full h-full"
                src={mapEmbedUrl}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>

          {/* Right Side: Information Boxes */}
          <div className="lg:w-1/2 flex flex-col space-y-8">
            <div className="flex flex-col h-full">
              <div className="space-y-8">
                {/* Row 1 */}
                <div className="flex flex-col gap-8 lg:flex-row lg:gap-8">
                  <div className="bg-gradient-to-r from-blue-500 to-blue-400 p-6 rounded-lg shadow-lg flex flex-col justify-between w-full hover:scale-105 transition-transform duration-300">
                    <h3 className="text-2xl font-bold mb-2 text-white text-center">THE AMERICAS</h3>
                    <p className="text-gray-100 text-center">USA, Canada, Mexico, Guatemala</p>
                  </div>
                  <div className="bg-gradient-to-r from-green-500 to-green-400 p-6 rounded-lg shadow-lg flex flex-col justify-between w-full hover:scale-105 transition-transform duration-300">
                    <h3 className="text-2xl font-bold mb-2 text-white text-center">EUROPE</h3>
                    <p className="text-gray-100 text-center">UK, Belgium, Denmark, Germany, Netherlands, Norway, Spain, Sweden, Turkey</p>
                  </div>
                </div>
                {/* Row 2 */}
                <div className="flex flex-col gap-8 lg:flex-row lg:gap-8">
                  <div className="bg-gradient-to-r from-red-500 to-red-400 p-6 rounded-lg shadow-lg flex flex-col justify-between w-full hover:scale-105 transition-transform duration-300">
                    <h3 className="text-2xl font-bold mb-2 text-white text-center">ASIA</h3>
                    <p className="text-gray-100 text-center">Iraq, Jordan, Kuwait, Lebanon, Saudi Arabia, UAE</p>
                  </div>
                  <div className="bg-gradient-to-r from-yellow-500 to-yellow-400 p-6 rounded-lg shadow-lg flex flex-col justify-between w-full hover:scale-105 transition-transform duration-300">
                    <h3 className="text-2xl font-bold mb-2 text-white text-center">AFRICA</h3>
                    <p className="text-gray-100 text-center">Algeria, Kenya, Egypt, Morocco, Tunisia</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InformationSection;
