





import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import {
    useGetCategoryByNameQuery,
    useGetAllCoffeeTypesByCategoryQuery,
    // useGetProductByIdQuery,
    useUpdateProductMutation,
    useGetProductForCoffeeForEditQuery,
    useCOffeeUpdateMutation,
    useGetallCatagoryForTypeAdminQuery
} from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";

const EditIndianIndianRIce = () => {
    const { id } = useParams(); // Get product ID from URL params
    const [name, setName] = useState('Coffee');
    const [categoryId, setCategoryId] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    // // Fetch categories
    // const { data: categories, isFetching: isFetchingCategories } = useGetCategoryByNameQuery(name, {
    //     skip: !name,
    // });
    // console.log("categories",categories)

    
// Fetch all categories
const { data: categories, error: categoriesError, isLoading: isLoadingCategories, isFetching: isFetchingCategories } = useGetallCatagoryForTypeAdminQuery();


console.log("data category",categories)
 

    // Fetch coffee types based on the selected category
    const { data: coffeeTypes = [], isFetching: isFetchingCoffeeTypes } = useGetAllCoffeeTypesByCategoryQuery(categoryId, {
        skip: !categoryId,
    });
    console.log("coffeeTypes",coffeeTypes)

    // Fetch product details by ID
    const { data: product, isFetching: isFetchingProduct } = useGetProductForCoffeeForEditQuery(id);

    console.log("product",product)
    // State management
    const [state, setState] = useState({
        name: '',
        // price: 0,
        category: categoryId ? categoryId : "nill",
        image1: '',
        instructorId: '',
        // Coffee-specific fields
        coffeeType: '',
        pcode: '',
        containerCapacity: {
            twentyFoot: '',
            fortyFoot: ''
        },
        description: '',
        thumbnail_url: '',
    
    
        // Packaging
        ppBags: '',
        paperBags: '',
        nonWovenBags: '',
        boppBags: '',
        vacuumBags: '',
        juteBags: '',
        pouches: '',
    
        // Additional fields specific to coffee
        newCropMonths: [],
        grade: '',
        sieve: '',
        screen: '',
        pb: '',
        triage: '',
        blackSeeds: '',
        moisture: '',
    
        // Product Specifications
        purity: '',
        admixture: '',
        oilContent: '',
        ffa: '',
        darkSeeds: '',
        split: '',
        unblanch: '',
        damaged: '',
        redNose: '',
        overRoasted: '',
        aflatoxin: '',
        color: '',
        count: '',
        averageLength: '',
        cooking: '',
        broken: '',
        kettRatio: '',
        moistureContent: '',
        foreignMatter: '',
        damagedDiscoloured: '',
        blackGrains: '',
        smell: '',
        process: '',
        ash: '',
        granulation: '',
        extraneousMatter: '',
        totalAsh: '',
        acidInsolubleAsh: '',
        volatileOil: '',
        ltr: '',
        lightBerries: '',
        nonVolatileEtherExtract: '',
    
        // Additional Product Specifications
        size: '',
        totalDefected: '',
        undersizeSeeds: '',
        brokenKernels: '',
        discolouredKernels: '',
        stones: '',
        starch: '',
        appearance: '',
        seeds: '',
        totalTartaricAcid: '',
        residualSO2: '',
        leadPb: '',
        shelfLife: '',
    
        // Nutritional Information
        energy: '',
        fat: '',
        protein: '',
        carbohydrates: '',
        ash: '',
        crudeFiber: '',
        calcium: '',
        iron: '',
        thiamin: '',
        riboflavin: '',
        niacin: '',

                // new here 
        
shape:"",
colouruniformity:"",
capstems:"",
colourcontamination:"",
sticks:"",
cartons:"",
colour:"",
nlsglng:"",



pieces:"",
taste:"",
texture:"",
mouldInsects:"",


quarters:"",
halves:"",



caffeinehplc:"",
bulkdensity:"",
ph:"",
colorvalue:"",
bottles:"",
hdpebags:"",
refillpack:"",
blend:"",

//NEW B HERE 
 pungency:"",
colorasta:"",
looseseeds:"",
blackorsplit:"",
shrivelled:"",
malformed:"",
ecoil:"",
salomonella:"", 



    });



    const [preview, setPreview] = useState({
        image1: ''
    });


    const [value, setValue] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateProduct, { isLoading: isUpdating ,isSuccess },response] = useCOffeeUpdateMutation();
console.log("isUpdating",isUpdating)
console.log("response",response)
    useEffect(() => {
        if (product) {
            // Prepopulate the form with product details
            setState({
                ...product,
                containerCapacity: product.containerCapacity || {
                    twentyFoot: '',
                    fortyFoot: ''
                }
            });
            setValue(product.description || '');
            setCategoryId(product.category);
            setSelectedCategoryId(product.category)
        }
    }, [product]);

    useEffect(() => {
        if (categories?._id) {
            setCategoryId(categories._id);
        }
    }, [categories]);


    useEffect(() => {
        if (categories) {
            console.log("categories?.categories?._id",categories?.categories?._id)
            setCategoryId(selectedCategoryId);
        }
    }, [categories,selectedCategoryId]);

    const handleInput = e => {
        const { name, value } = e.target;
        if (name === 'twentyFoot' || name === 'fortyFoot') {
            setState({ ...state, containerCapacity: { ...state.containerCapacity, [name]: value } });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);

        updateProduct({ id, formData });
    };

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error?.data?.errors]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSuccess("Product updated successfully"));
            navigate('/dashboard/get/indianrice');
        }
    }, [isSuccess, dispatch, navigate]);

    // if (isFetchingProduct || isFetchingCategories || isFetchingCoffeeTypes) return <Spinner />;

    if (isFetchingProduct || isFetchingCategories || isFetchingCoffeeTypes) {
        return (
            <WrapperTeacher>
                <ScreenHeaderTeacher>
                    <Link to="/dashboard/get/indianrice" className="btn-dark">
                        <i className="bi bi-arrow-left-short"></i>back  to indian Rice List
                    </Link>
                </ScreenHeaderTeacher>
                <Toaster position="top-right" reverseOrder={true} />
                <Spinner />
                <Skeleton />
            </WrapperTeacher>
        );
    }

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/get/indianrice" className="btn-dark bg-blue-500 hover:bg-red-500 ">
                    <i className="bi bi-arrow-left-short"></i> back  to Product List
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3 bg-yellow-300 ">
                <form className="w-full xl:w-8/12 p-3" onSubmit={handleSubmit}>
                    <div className="flex flex-wrap ">
                        <div className="w-full p-3">
                            <label htmlFor="name" className="label">name</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="name..."
                                onChange={handleInput}
                                value={state.name}
                            />
                        </div>
                        {/* <div className="w-full p-3">
                            <label htmlFor="price" className="label">Price</label>
                            <input
                                type="number"
                                name="price"
                                className="form-control"
                                id="price"
                                placeholder="Price..."
                                onChange={handleInput}
                                value={state.price}
                            />
                        </div> */}
                        <div className="w-full p-3">
                            <label htmlFor="category" className="label">Category</label>
                            <select
                                name="category"
                                className="form-control"
                                id="category"
                                // onChange={e => setCategoryId(e.target.value)}
                                // value={categoryId}
                                onChange={(e) => { setSelectedCategoryId(e.target.value); handleInput(e)  }}  value={state.category}
                            >
                                <option value="">Select Category</option>
                                {/* {categories ?(
                                    <option key={categories._id} value={categories._id}>{categories.name}</option>
                                ) : <p>no found</p>} */}

{categories ? categories.map((category) => (

<option  value={category._id} key={category._id}>{category.name}</option>

                            ) ) : <p>sadasd</p>}





                            </select>
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="coffeeType" className="label">Coffee Type</label>
                            <select
                                name="coffeeType"
                                className="form-control"
                                id="coffeeType"
                                onChange={handleInput}
                                value={state.coffeeType}
                            >
                                <option value="">Select Coffee Type</option>
                                {coffeeTypes?.map(type => (
                                    <option key={type._id} value={type._id}>{type.name}</option>
                                ))}
                            </select>
                        </div>



{/* 
                        <div className="w-full p-3">
                            <label htmlFor="pcode" className="label">Product Code</label>
                            <input
                                type="text"
                                name="pcode"
                                className="form-control"
                                id="pcode"
                                placeholder="Product Code..."
                                onChange={handleInput}
                                value={state.pcode}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="containerCapacity" className="label">Container Capacity (Twenty Foot)</label>
                            <input
                                type="text"
                                name="twentyFoot"
                                className="form-control"
                                id="twentyFoot"
                                placeholder="Twenty Foot Capacity..."
                                onChange={handleInput}
                                value={state.containerCapacity.twentyFoot}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="containerCapacity" className="label">Container Capacity (Forty Foot)</label>
                            <input
                                type="text"
                                name="fortyFoot"
                                className="form-control"
                                id="fortyFoot"
                                placeholder="Forty Foot Capacity..."
                                onChange={handleInput}
                                value={state.containerCapacity.fortyFoot}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="newCropMonths" className="label">New Crop Months</label>
                            <input
                                type="text"
                                name="newCropMonths"
                                className="form-control"
                                id="newCropMonths"
                                placeholder="New Crop Months..."
                                onChange={handleInput}
                                value={state.newCropMonths}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="grade" className="label">Grade</label>
                            <input
                                type="text"
                                name="grade"
                                className="form-control"
                                id="grade"
                                placeholder="Grade..."
                                onChange={handleInput}
                                value={state.grade}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="sieve" className="label">Sieve</label>
                            <input
                                type="text"
                                name="sieve"
                                className="form-control"
                                id="sieve"
                                placeholder="Sieve..."
                                onChange={handleInput}
                                value={state.sieve}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="screen" className="label">Screen</label>
                            <input
                                type="text"
                                name="screen"
                                className="form-control"
                                id="screen"
                                placeholder="Screen..."
                                onChange={handleInput}
                                value={state.screen}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="pb" className="label">PB</label>
                            <input
                                type="text"
                                name="pb"
                                className="form-control"
                                id="pb"
                                placeholder="PB..."
                                onChange={handleInput}
                                value={state.pb}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="triage" className="label">Triage</label>
                            <input
                                type="text"
                                name="triage"
                                className="form-control"
                                id="triage"
                                placeholder="Triage..."
                                onChange={handleInput}
                                value={state.triage}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="blackSeeds" className="label">Black Seeds</label>
                            <input
                                type="text"
                                name="blackSeeds"
                                className="form-control"
                                id="blackSeeds"
                                placeholder="Black Seeds..."
                                onChange={handleInput}
                                value={state.blackSeeds}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="moisture" className="label">Moisture</label>
                            <input
                                type="text"
                                name="moisture"
                                className="form-control"
                                id="moisture"
                                placeholder="Moisture..."
                                onChange={handleInput}
                                value={state.moisture}
                            />
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="packaging" className="label">Packaging</label>
                            <input
                                type="text"
                                name="packaging"
                                className="form-control"
                                id="packaging"
                                placeholder="Packaging..."
                                onChange={handleInput}
                                value={state.packaging}
                            />
                        </div>
 */}















                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="pcode" className="label text-black">Product Code</label>
                            <input type="text" name="pcode" className="form-control" id="pcode" placeholder="Product Code..." onChange={handleInput} value={state.pcode} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="grade" className="label text-black">Grade</label>
                            <input type="text" name="grade" className="form-control" id="grade" placeholder="Grade..." onChange={handleInput} value={state.grade} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="containerCapacityTwentyFoot" className="label text-black">20' Container Capacity</label>
                            <input type="text" name="twentyFoot" className="form-control" id="containerCapacityTwentyFoot" placeholder="20' Container Capacity..." onChange={handleInput} value={state.containerCapacity.twentyFoot} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="containerCapacityFortyFoot" className="label text-black">40' Container Capacity</label>
                            <input type="text" name="fortyFoot" className="form-control" id="containerCapacityFortyFoot" placeholder="40' Container Capacity..." onChange={handleInput} value={state.containerCapacity.fortyFoot} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="newCropMonths" className="label text-black">New Crop Months</label>
                            <input type="text" name="newCropMonths" className="form-control" id="newCropMonths" placeholder="New Crop Months..." onChange={handleInput} value={state.newCropMonths} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="sieve" className="label text-black">Sieve</label>
                            <input type="text" name="sieve" className="form-control" id="sieve" placeholder="Sieve..." onChange={handleInput} value={state.sieve} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="screen" className="label text-black">Screen</label>
                            <input type="number" name="screen" className="form-control" id="screen" placeholder="Screen..." onChange={handleInput} value={state.screen} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="pb" className="label text-black">PB</label>
                            <input type="text" name="pb" className="form-control" id="pb" placeholder="PB..." onChange={handleInput} value={state.pb} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="triage" className="label text-black">Triage</label>
                            <input type="text" name="triage" className="form-control" id="triage" placeholder="Triage..." onChange={handleInput} value={state.triage} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="blackSeeds" className="label text-black">Black Seeds</label>
                            <input type="text" name="blackSeeds" className="form-control" id="blackSeeds" placeholder="Black Seeds..." onChange={handleInput} value={state.blackSeeds} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="moisture" className="label text-black">Moisture</label>
                            <input type="text" name="moisture" className="form-control" id="moisture" placeholder="Moisture..." onChange={handleInput} value={state.moisture} />
                        </div>
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="packaging" className="label text-black">Packaging</label>
                            <input type="text" name="packaging" className="form-control" id="packaging" placeholder="Packaging..." onChange={handleInput} value={state.packaging} />
                        </div>



   {/* Packaging Fields */}
   <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ppBags" className="label text-black">PP Bags</label>
        <input
          type="text"
          name="ppBags"
          className="form-control"
          id="ppBags"
          placeholder="PP Bags..."
          onChange={handleInput}
          value={state.ppBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="paperBags" className="label text-black">Paper Bags</label>
        <input
          type="text"
          name="paperBags"
          className="form-control"
          id="paperBags"
          placeholder="Paper Bags..."
          onChange={handleInput}
          value={state.paperBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="nonWovenBags" className="label text-black">Non-Woven Bags</label>
        <input
          type="text"
          name="nonWovenBags"
          className="form-control"
          id="nonWovenBags"
          placeholder="Non-Woven Bags..."
          onChange={handleInput}
          value={state.nonWovenBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="boppBags" className="label text-black">BOPP Bags</label>
        <input
          type="text"
          name="boppBags"
          className="form-control"
          id="boppBags"
          placeholder="BOPP Bags..."
          onChange={handleInput}
          value={state.boppBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="vacuumBags" className="label text-black">Vacuum Bags</label>
        <input
          type="text"
          name="vacuumBags"
          className="form-control"
          id="vacuumBags"
          placeholder="Vacuum Bags..."
          onChange={handleInput}
          value={state.vacuumBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="juteBags" className="label text-black">Jute Bags</label>
        <input
          type="text"
          name="juteBags"
          className="form-control"
          id="juteBags"
          placeholder="Jute Bags..."
          onChange={handleInput}
          value={state.juteBags}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="pouches" className="label text-black">Pouches</label>
        <input
          type="text"
          name="pouches"
          className="form-control"
          id="pouches"
          placeholder="Pouches..."
          onChange={handleInput}
          value={state.pouches}
        />
      </div>









     {/* Product Specifications Fields */}
     <div className="w-full md:w-6/12 p-3">
        <label htmlFor="purity" className="label text-black">Purity</label>
        <input
          type="text"
          name="purity"
          className="form-control"
          id="purity"
          placeholder="Purity..."
          onChange={handleInput}
          value={state.purity}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="admixture" className="label text-black">Admixture</label>
        <input
          type="text"
          name="admixture"
          className="form-control"
          id="admixture"
          placeholder="Admixture..."
          onChange={handleInput}
          value={state.admixture}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="oilContent" className="label text-black">Oil Content</label>
        <input
          type="text"
          name="oilContent"
          className="form-control"
          id="oilContent"
          placeholder="Oil Content..."
          onChange={handleInput}
          value={state.oilContent}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ffa" className="label text-black">FFA</label>
        <input
          type="text"
          name="ffa"
          className="form-control"
          id="ffa"
          placeholder="FFA..."
          onChange={handleInput}
          value={state.ffa}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="darkSeeds" className="label text-black">Dark Seeds</label>
        <input
          type="text"
          name="darkSeeds"
          className="form-control"
          id="darkSeeds"
          placeholder="Dark Seeds..."
          onChange={handleInput}
          value={state.darkSeeds}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="split" className="label text-black">Split</label>
        <input
          type="text"
          name="split"
          className="form-control"
          id="split"
          placeholder="Split..."
          onChange={handleInput}
          value={state.split}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="unblanch" className="label text-black">Unblanch</label>
        <input
          type="text"
          name="unblanch"
          className="form-control"
          id="unblanch"
          placeholder="Unblanch..."
          onChange={handleInput}
          value={state.unblanch}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="damaged" className="label text-black">Damaged</label>
        <input
          type="text"
          name="damaged"
          className="form-control"
          id="damaged"
          placeholder="Damaged..."
          onChange={handleInput}
          value={state.damaged}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="redNose" className="label text-black">Red Nose</label>
        <input
          type="text"
          name="redNose"
          className="form-control"
          id="redNose"
          placeholder="Red Nose..."
          onChange={handleInput}
          value={state.redNose}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="overRoasted" className="label text-black">Over Roasted</label>
        <input
          type="text"
          name="overRoasted"
          className="form-control"
          id="overRoasted"
          placeholder="Over Roasted..."
          onChange={handleInput}
          value={state.overRoasted}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="aflatoxin" className="label text-black">Aflatoxin</label>
        <input
          type="text"
          name="aflatoxin"
          className="form-control"
          id="aflatoxin"
          placeholder="Aflatoxin..."
          onChange={handleInput}
          value={state.aflatoxin}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="color" className="label text-black">Color</label>
        <input
          type="text"
          name="color"
          className="form-control"
          id="color"
          placeholder="Color..."
          onChange={handleInput}
          value={state.color}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="count" className="label text-black">Count</label>
        <input
          type="text"
          name="count"
          className="form-control"
          id="count"
          placeholder="Count..."
          onChange={handleInput}
          value={state.count}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="averageLength" className="label text-black">Average Length</label>
        <input
          type="text"
          name="averageLength"
          className="form-control"
          id="averageLength"
          placeholder="Average Length..."
          onChange={handleInput}
          value={state.averageLength}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="cooking" className="label text-black">Cooking</label>
        <input
          type="text"
          name="cooking"
          className="form-control"
          id="cooking"
          placeholder="Cooking..."
          onChange={handleInput}
          value={state.cooking}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="broken" className="label text-black">Broken</label>
        <input
          type="text"
          name="broken"
          className="form-control"
          id="broken"
          placeholder="Broken..."
          onChange={handleInput}
          value={state.broken}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="kettRatio" className="label text-black">Kett Ratio</label>
        <input
          type="text"
          name="kettRatio"
          className="form-control"
          id="kettRatio"
          placeholder="Kett Ratio..."
          onChange={handleInput}
          value={state.kettRatio}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="moistureContent" className="label text-black">Moisture Content</label>
        <input
          type="text"
          name="moistureContent"
          className="form-control"
          id="moistureContent"
          placeholder="Moisture Content..."
          onChange={handleInput}
          value={state.moistureContent}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="foreignMatter" className="label text-black">Foreign Matter</label>
        <input
          type="text"
          name="foreignMatter"
          className="form-control"
          id="foreignMatter"
          placeholder="Foreign Matter..."
          onChange={handleInput}
          value={state.foreignMatter}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="damagedDiscoloured" className="label text-black">Damaged Discoloured</label>
        <input
          type="text"
          name="damagedDiscoloured"
          className="form-control"
          id="damagedDiscoloured"
          placeholder="Damaged Discoloured..."
          onChange={handleInput}
          value={state.damagedDiscoloured}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="blackGrains" className="label text-black">Black Grains</label>
        <input
          type="text"
          name="blackGrains"
          className="form-control"
          id="blackGrains"
          placeholder="Black Grains..."
          onChange={handleInput}
          value={state.blackGrains}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="smell" className="label text-black">Smell</label>
        <input
          type="text"
          name="smell"
          className="form-control"
          id="smell"
          placeholder="Smell..."
          onChange={handleInput}
          value={state.smell}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="process" className="label text-black">Process</label>
        <input
          type="text"
          name="process"
          className="form-control"
          id="process"
          placeholder="Process..."
          onChange={handleInput}
          value={state.process}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ash" className="label text-black">Ash</label>
        <input
          type="text"
          name="ash"
          className="form-control"
          id="ash"
          placeholder="Ash..."
          onChange={handleInput}
          value={state.ash}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="granulation" className="label text-black">Granulation</label>
        <input
          type="text"
          name="granulation"
          className="form-control"
          id="granulation"
          placeholder="Granulation..."
          onChange={handleInput}
          value={state.granulation}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="extraneousMatter" className="label text-black">Extraneous Matter</label>
        <input
          type="text"
          name="extraneousMatter"
          className="form-control"
          id="extraneousMatter"
          placeholder="Extraneous Matter..."
          onChange={handleInput}
          value={state.extraneousMatter}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="totalAsh" className="label text-black">Total Ash</label>
        <input
          type="text"
          name="totalAsh"
          className="form-control"
          id="totalAsh"
          placeholder="Total Ash..."
          onChange={handleInput}
          value={state.totalAsh}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="acidInsolubleAsh" className="label text-black">Acid Insoluble Ash</label>
        <input
          type="text"
          name="acidInsolubleAsh"
          className="form-control"
          id="acidInsolubleAsh"
          placeholder="Acid Insoluble Ash..."
          onChange={handleInput}
          value={state.acidInsolubleAsh}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="volatileOil" className="label text-black">Volatile Oil</label>
        <input
          type="text"
          name="volatileOil"
          className="form-control"
          id="volatileOil"
          placeholder="Volatile Oil..."
          onChange={handleInput}
          value={state.volatileOil}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ltr" className="label text-black">LTR</label>
        <input
          type="text"
          name="ltr"
          className="form-control"
          id="ltr"
          placeholder="LTR..."
          onChange={handleInput}
          value={state.ltr}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="lightBerries" className="label text-black">Light Berries</label>
        <input
          type="text"
          name="lightBerries"
          className="form-control"
          id="lightBerries"
          placeholder="Light Berries..."
          onChange={handleInput}
          value={state.lightBerries}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="nonVolatileEtherExtract" className="label text-black">Non-Volatile Ether Extract</label>
        <input
          type="text"
          name="nonVolatileEtherExtract"
          className="form-control"
          id="nonVolatileEtherExtract"
          placeholder="Non-Volatile Ether Extract..."
          onChange={handleInput}
          value={state.nonVolatileEtherExtract}
        />
      </div>












      {/* Additional Product Specifications Fields */}
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="size" className="label text-black">Size</label>
        <input
          type="text"
          name="size"
          className="form-control"
          id="size"
          placeholder="Size..."
          onChange={handleInput}
          value={state.size}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="totalDefected" className="label text-black">Total Defected</label>
        <input
          type="text"
          name="totalDefected"
          className="form-control"
          id="totalDefected"
          placeholder="Total Defected..."
          onChange={handleInput}
          value={state.totalDefected}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="undersizeSeeds" className="label text-black">Undersize Seeds</label>
        <input
          type="text"
          name="undersizeSeeds"
          className="form-control"
          id="undersizeSeeds"
          placeholder="Undersize Seeds..."
          onChange={handleInput}
          value={state.undersizeSeeds}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="brokenKernels" className="label text-black">Broken Kernels</label>
        <input
          type="text"
          name="brokenKernels"
          className="form-control"
          id="brokenKernels"
          placeholder="Broken Kernels..."
          onChange={handleInput}
          value={state.brokenKernels}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="discolouredKernels" className="label text-black">Discoloured Kernels</label>
        <input
          type="text"
          name="discolouredKernels"
          className="form-control"
          id="discolouredKernels"
          placeholder="Discoloured Kernels..."
          onChange={handleInput}
          value={state.discolouredKernels}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="stones" className="label text-black">Stones</label>
        <input
          type="text"
          name="stones"
          className="form-control"
          id="stones"
          placeholder="Stones..."
          onChange={handleInput}
          value={state.stones}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="starch" className="label text-black">Starch</label>
        <input
          type="text"
          name="starch"
          className="form-control"
          id="starch"
          placeholder="Starch..."
          onChange={handleInput}
          value={state.starch}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="appearance" className="label text-black">Appearance</label>
        <input
          type="text"
          name="appearance"
          className="form-control"
          id="appearance"
          placeholder="Appearance..."
          onChange={handleInput}
          value={state.appearance}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="seeds" className="label text-black">Seeds</label>
        <input
          type="text"
          name="seeds"
          className="form-control"
          id="seeds"
          placeholder="Seeds..."
          onChange={handleInput}
          value={state.seeds}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="totalTartaricAcid" className="label text-black">Total Tartaric Acid</label>
        <input
          type="text"
          name="totalTartaricAcid"
          className="form-control"
          id="totalTartaricAcid"
          placeholder="Total Tartaric Acid..."
          onChange={handleInput}
          value={state.totalTartaricAcid}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="residualSO2" className="label text-black">Residual SO2</label>
        <input
          type="text"
          name="residualSO2"
          className="form-control"
          id="residualSO2"
          placeholder="Residual SO2..."
          onChange={handleInput}
          value={state.residualSO2}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="leadPb" className="label text-black">Lead Pb</label>
        <input
          type="text"
          name="leadPb"
          className="form-control"
          id="leadPb"
          placeholder="Lead Pb..."
          onChange={handleInput}
          value={state.leadPb}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="shelfLife" className="label text-black">Shelf Life</label>
        <input
          type="text"
          name="shelfLife"
          className="form-control"
          id="shelfLife"
          placeholder="Shelf Life..."
          onChange={handleInput}
          value={state.shelfLife}
        />
      </div>


      {/* Nutritional Information Fields */}
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="energy" className="label text-black">Energy</label>
        <input
          type="text"
          name="energy"
          className="form-control"
          id="energy"
          placeholder="Energy..."
          onChange={handleInput}
          value={state.energy}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="fat" className="label text-black">Fat</label>
        <input
          type="text"
          name="fat"
          className="form-control"
          id="fat"
          placeholder="Fat..."
          onChange={handleInput}
          value={state.fat}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="protein" className="label text-black">Protein</label>
        <input
          type="text"
          name="protein"
          className="form-control"
          id="protein"
          placeholder="Protein..."
          onChange={handleInput}
          value={state.protein}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="carbohydrates" className="label text-black">Carbohydrates</label>
        <input
          type="text"
          name="carbohydrates"
          className="form-control"
          id="carbohydrates"
          placeholder="Carbohydrates..."
          onChange={handleInput}
          value={state.carbohydrates}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ash" className="label text-black">Ash</label>
        <input
          type="text"
          name="ash"
          className="form-control"
          id="ash"
          placeholder="Ash..."
          onChange={handleInput}
          value={state.ash}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="crudeFiber" className="label text-black">Crude Fiber</label>
        <input
          type="text"
          name="crudeFiber"
          className="form-control"
          id="crudeFiber"
          placeholder="Crude Fiber..."
          onChange={handleInput}
          value={state.crudeFiber}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="calcium" className="label text-black">Calcium</label>
        <input
          type="text"
          name="calcium"
          className="form-control"
          id="calcium"
          placeholder="Calcium..."
          onChange={handleInput}
          value={state.calcium}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="iron" className="label text-black">Iron</label>
        <input
          type="text"
          name="iron"
          className="form-control"
          id="iron"
          placeholder="Iron..."
          onChange={handleInput}
          value={state.iron}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="thiamin" className="label text-black">Thiamin</label>
        <input
          type="text"
          name="thiamin"
          className="form-control"
          id="thiamin"
          placeholder="Thiamin..."
          onChange={handleInput}
          value={state.thiamin}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="riboflavin" className="label text-black">Riboflavin</label>
        <input
          type="text"
          name="riboflavin"
          className="form-control"
          id="riboflavin"
          placeholder="Riboflavin..."
          onChange={handleInput}
          value={state.riboflavin}
        />
      </div>

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="niacin" className="label text-black">Niacin</label>
        <input
          type="text"
          name="niacin"
          className="form-control"
          id="niacin"
          placeholder="Niacin..."
          onChange={handleInput}
          value={state.niacin}
        />
      </div>





      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="shape" className="label text-black">shape</label>
        <input
          type="text"
          name="shape"
          className="form-control"
          id="shape"
          placeholder="shape..."
          onChange={handleInput}
          value={state.shape}
        />
      </div>





      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="colouruniformity" className="label text-black">Colouruni Formity</label>
        <input
          type="text"
          name="colouruniformity"
          className="form-control"
          id="colouruniformity"
          placeholder="colouruniformity..."
          onChange={handleInput}
          value={state.colouruniformity}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="capstems" className="label text-black">Cap Stems</label>
        <input
          type="text"
          name="capstems"
          className="form-control"
          id="capstems"
          placeholder="capstems..."
          onChange={handleInput}
          value={state.capstems}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="colourcontamination" className="label text-black">Colour Contamination</label>
        <input
          type="text"
          name="colourcontamination"
          className="form-control"
          id="colourcontamination"
          placeholder="colourcontamination..."
          onChange={handleInput}
          value={state.colourcontamination}
        />
      </div>



      



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="sticks" className="label text-black">Sticks</label>
        <input
          type="text"
          name="sticks"
          className="form-control"
          id="sticks"
          placeholder="sticks..."
          onChange={handleInput}
          value={state.sticks}
        />
      </div>

      



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="cartons" className="label text-black">Cartons</label>
        <input
          type="text"
          name="cartons"
          className="form-control"
          id="cartons"
          placeholder="cartons..."
          onChange={handleInput}
          value={state.cartons}
        />
      </div>


      



      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="colour" className="label text-black">Colour</label>
        <input
          type="text"
          name="colour"
          className="form-control"
          id="colour"
          placeholder="colour..."
          onChange={handleInput}
          value={state.colour}
        />
      </div>


      


         
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="nlsglng" className="label text-black">NLSG NLG	</label>
        <input
          type="text"
          name="nlsglng"
          className="form-control"
          id="nlsglng"
          placeholder="nlsglng..."
          onChange={handleInput}
          value={state.nlsglng}
        />
      </div>




      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="pieces" className="label text-black">pieces	</label>
        <input
          type="text"
          name="pieces"
          className="form-control"
          id="pieces"
          placeholder="pieces..."
          onChange={handleInput}
          value={state.pieces}
        />
      </div>



            
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="taste" className="label text-black">Taste	</label>
        <input
          type="text"
          name="taste"
          className="form-control"
          id="taste"
          placeholder="taste..."
          onChange={handleInput}
          value={state.taste}
        />
      </div>




            
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="texture" className="label text-black">Texture	</label>
        <input
          type="text"
          name="texture"
          className="form-control"
          id="texture"
          placeholder="texture..."
          onChange={handleInput}
          value={state.texture}
        />
      </div>




            
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="mouldInsects" className="label text-black">Mould ,Insects 	</label>
        <input
          type="text"
          name="mouldInsects"
          className="form-control"
          id="mouldInsects"
          placeholder="mouldInsects..."
          onChange={handleInput}
          value={state.mouldInsects}
        />
      </div>


      



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="quarters" className="label text-black">Quarters 	</label>
        <input
          type="text"
          name="quarters"
          className="form-control"
          id="quarters"
          placeholder="quarters..."
          onChange={handleInput}
          value={state.quarters}
        />
      </div>


      

      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="halves" className="label text-black">Halves 	</label>
        <input
          type="text"
          name="halves"
          className="form-control"
          id="halves"
          placeholder="halves..."
          onChange={handleInput}
          value={state.halves}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="caffeinehplc" className="label text-black">Caffeine (HPLC) 	</label>
        <input
          type="text"
          name="caffeinehplc"
          className="form-control"
          id="caffeinehplc"
          placeholder="caffeinehplc..."
          onChange={handleInput}
          value={state.caffeinehplc}
        />
      </div>



      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="bulkdensity" className="label text-black">Bulk Density	 	</label>
        <input
          type="text"
          name="bulkdensity"
          className="form-control"
          id="bulkdensity"
          placeholder="bulkdensity..."
          onChange={handleInput}
          value={state.bulkdensity}
        />
      </div>





      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ph" className="label text-black">ph 	</label>
        <input
          type="text"
          name="ph"
          className="form-control"
          id="ph"
          placeholder="ph..."
          onChange={handleInput}
          value={state.ph}
        />
      </div>





      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="colorvalue" className="label text-black">Color Value	 	</label>
        <input
          type="text"
          name="colorvalue"
          className="form-control"
          id="colorvalue"
          placeholder="colorvalue..."
          onChange={handleInput}
          value={state.colorvalue}
        />
      </div>



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="blend" className="label text-black">Blend 	 	</label>
        <input
          type="text"
          name="blend"
          className="form-control"
          id="blend"
          placeholder="blend..."
          onChange={handleInput}
          value={state.blend}
        />
      </div>





      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="bottles" className="label text-black">Bottles 	</label>
        <input
          type="text"
          name="bottles"
          className="form-control"
          id="bottles"
          placeholder="bottles..."
          onChange={handleInput}
          value={state.bottles}
        />
      </div>





      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="hdpebags" className="label text-black">HDPE Bags	 	</label>
        <input
          type="text"
          name="hdpebags"
          className="form-control"
          id="hdpebags"
          placeholder="hdpebags..."
          onChange={handleInput}
          value={state.hdpebags}
        />
      </div>





      
      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="refillpack" className="label text-black">Refill Pack	 	</label>
        <input
          type="text"
          name="refillpack"
          className="form-control"
          id="refillpack"
          placeholder="refillpack..."
          onChange={handleInput}
          value={state.refillpack}
        />
      </div>







      {/* pungency:"",
colorasta:"",
looseseeds:"",
blackorsplit:"",
shrivelled:"",
malformed:"",
ecoil:"",
salomonella:"", */}

<div className="w-full md:w-6/12 p-3">
        <label htmlFor="pungency" className="label text-black">Pungency	 	</label>
        <input
          type="text"
          name="pungency"
          className="form-control"
          id="pungency"
          placeholder="pungency..."
          onChange={handleInput}
          value={state.pungency}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="colorasta" className="label text-black"> color ASTA	 	</label>
        <input
          type="text"
          name="colorasta"
          className="form-control"
          id="colorasta"
          placeholder="colorasta..."
          onChange={handleInput}
          value={state.colorasta}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="looseseeds" className="label text-black">Loose Seeds 	 	</label>
        <input
          type="text"
          name="looseseeds"
          className="form-control"
          id="looseseeds"
          placeholder="looseseeds..."
          onChange={handleInput}
          value={state.looseseeds}
        />
      </div>



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="blackorsplit" className="label text-black">Black or Split 	 	</label>
        <input
          type="text"
          name="blackorsplit"
          className="form-control"
          id="blackorsplit"
          placeholder="blackorsplit..."
          onChange={handleInput}
          value={state.blackorsplit}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="shrivelled" className="label text-black">Shrivelled	 	</label>
        <input
          type="text"
          name="shrivelled"
          className="form-control"
          id="shrivelled"
          placeholder="shrivelled..."
          onChange={handleInput}
          value={state.shrivelled}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="malformed" className="label text-black">Malformed 	 	</label>
        <input
          type="text"
          name="malformed"
          className="form-control"
          id="malformed"
          placeholder="malformed..."
          onChange={handleInput}
          value={state.malformed}
        />
      </div>




      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="ecoil" className="label text-black">E Coil 	 	</label>
        <input
          type="text"
          name="ecoil"
          className="form-control"
          id="ecoil"
          placeholder="ecoil..."
          onChange={handleInput}
          value={state.ecoil}
        />
      </div>



      <div className="w-full md:w-6/12 p-3">
        <label htmlFor="salomonella" className="label text-black">Salomonella 	 	</label>
        <input
          type="text"
          name="salomonella"
          className="form-control"
          id="salomonella"
          placeholder="salomonella..."
          onChange={handleInput}
          value={state.salomonella}
        />
      </div>





















                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label">Product Image</label>
                            <input
                                type="file"
                                name="image1"
                                id="image1"
                                className="form-control"
                                onChange={imageHandle}
                            />
                            {preview.image1 && <ImagesPreview url={preview.image1} heading="Image 1" image={preview.image1} />}
                        </div>
                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">Description</label>
                            <ReactQuill value={value} onChange={setValue} />
                        </div>
                        <div className="w-full p-3">
                            <button type="submit" className="btn-primary bg-blue-500 p-4 rounded hover:bg-red-500" disabled={isUpdating}>
                                {isUpdating ? "Updating..." : "Update"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </WrapperTeacher>
    );
};

export default EditIndianIndianRIce;











// Skeleton.js
const Skeleton = () => (
    <div className="p-3 space-y-4">
        {Array.from({ length: 12 }).map((_, index) => (
            <div key={index} className="animate-pulse bg-gray-200 h-6 rounded"></div>
        ))}
    </div>
);

// export default Skeleton;