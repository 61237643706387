// import React, { useEffect, useState } from 'react';
// import HomeNav from "./HomeNav";

// const CommonCore = () => {
//     // Sample course data
//     const courses = [
//         {
//             id: 1,
//             url: 'https://ajitfhamacademy.teachable.com/digital-products/517175',
//             image: 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://cdn.filestackcontent.com/RA5M4ysbQueaHmhOMy4v',
//             title: 'Course and exercise book 2 bac sm',
//             fileCount: '1',
//             description: 'This book contains all the courses and exercises for level 2 Bac Sm with the correction of the exercises.',
//             authorImage: 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:30,height:30/https://www.filepicker.io/api/file/lrMGynjUSuyo0x8IHiQn',
//             authorName: 'zakaria bouicha',
//             price: 'FREE',
//         },
//         // Add more course objects here if needed
//     ];

//     const [branchFilter, setBranchFilter] = useState('all');
//     const [teacherFilter, setTeacherFilter] = useState('all');
//     const [branchOpen, setBranchOpen] = useState(false);
//     const [teacherOpen, setTeacherOpen] = useState(false);

//     return (
//         <>
//             <HomeNav />
//             <div className="flex flex-wrap -mx-4 mt-8 py-8 px-8">
//                 {/* Branch Filter */}
//                 <div className="relative w-full md:w-1/3">
//                     <label className="text-gray-700 font-medium mb-2 block">Option (Branch)</label>
//                     <button
//                         onClick={() => setBranchOpen(!branchOpen)}
//                         className="bg-gray-200 border border-gray-300 text-gray-700 px-4 py-2 rounded-lg w-full flex justify-between items-center"
//                     >
//                         {branchFilter}
//                         <span className="ml-2">&#9662;</span>
//                     </button>
//                     {branchOpen && (
//                         <ul
//                             className="absolute bg-white border border-gray-300 rounded-lg shadow-lg w-full mt-1 z-40"
//                             role="menu"
//                         >
//                             <li>
//                                 <button
//                                     onClick={() => {
//                                         setBranchFilter('all');
//                                         setBranchOpen(false);
//                                     }}
//                                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
//                                 >
//                                     All
//                                 </button>
//                             </li>
//                             <li>
//                                 <button
//                                     onClick={() => {
//                                         setBranchFilter('2nd year baccalaureate in mathematical sciences');
//                                         setBranchOpen(false);
//                                     }}
//                                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
//                                 >
//                                     2nd year baccalaureate in mathematical sciences (26)
//                                 </button>
//                             </li>
//                             {/* Add other options similarly */}
//                         </ul>
//                     )}
//                 </div>

//                 {/* Teacher Filter */}
//                 <div className="relative w-full md:w-1/3">
//                     <label className="text-gray-700 font-medium mb-2 block">Teacher</label>
//                     <button
//                         onClick={() => setTeacherOpen(!teacherOpen)}
//                         className="bg-gray-200 border border-gray-300 text-gray-700 px-4 py-2 rounded-lg w-full flex justify-between items-center"
//                     >
//                         {teacherFilter}
//                         <span className="ml-2 ">&#9662;</span>
//                     </button>
//                     {teacherOpen && (
//                         <ul
//                             className="absolute bg-white border border-gray-300 rounded-lg shadow-lg w-full mt-1 z-40"
//                             role="menu"
//                         >
//                             <li>
//                                 <button
//                                     onClick={() => {
//                                         setTeacherFilter('all');
//                                         setTeacherOpen(false);
//                                     }}
//                                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
//                                 >
//                                     All
//                                 </button>
//                             </li>
//                             <li>
//                                 <button
//                                     onClick={() => {
//                                         setTeacherFilter('Alaeddine ABIDA');
//                                         setTeacherOpen(false);
//                                     }}
//                                     className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
//                                 >
//                                     Alaeddine ABIDA
//                                 </button>
//                             </li>
//                             {/* Add other options similarly */}
//                         </ul>
//                     )}
//                 </div>

//                 {/* Search Form */}
//                 <div className="w-full md:w-1/3 mt-[30px] flex flex-col justify-end">
//                     <form role="search" method="get" action="/courses" className="w-full">
//                         <div className="relative flex items-center">
//                             <input
//                                 className="form-control w-full px-4 py-2 border border-gray-300 rounded-lg"
//                                 id="search-courses"
//                                 name="query"
//                                 placeholder="Search for a course"
//                                 type="text"
//                             />
//                             <button
//                                 aria-label="Search Courses"
//                                 id="search-course-button"
//                                 className="absolute right-0 top-0 mt-2 mr-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
//                                 type="submit"
//                             >
//                                 <i className="fa fa-search"></i>
//                             </button>
//                         </div>
//                     </form>
//                 </div>

//                 {/* Course Cards */}
//                 {courses.map((course) => (
//                     <div key={course.id} className="w-full mx-auto mt-[30px] sm:w-1/2 md:w-1/3 px-4 mb-6">
//                         <div className="text-center mb-8">
//                             <h2 className="text-3xl font-bold mb-2">Tronc Commun Scientifique</h2>
//                             <p className="text-xl text-gray-600">Available Courses</p>
//                         </div>
//                         <a href={course.url} className="block bg-white shadow-md rounded-lg overflow-hidden">
//                             <div className="relative">
//                                 <img
//                                     className="w-full object-cover"
//                                     style={{ height: 'auto', aspectRatio: '16/9' }}
//                                     src={course.image}
//                                     alt={course.title}
//                                 />
//                             </div>
//                             <div className="p-4">
//                                 <h2 className="text-xl font-semibold mb-2" title={course.title}>
//                                     {course.title}
//                                 </h2>
//                                 <p className="text-sm text-gray-600 mb-2">{course.fileCount} File</p>
//                                 <p className="text-sm text-gray-600 mb-4">{course.description}</p>
//                                 <div className="flex items-center justify-between text-sm text-gray-600">
//                                     <div className="flex items-center">
//                                         <img
//                                             className="w-8 h-8 rounded-full"
//                                             src={course.authorImage}
//                                             alt={course.authorName}
//                                         />
//                                         <span className="ml-2">{course.authorName}</span>
//                                     </div>
//                                     <span className="text-green-600 font-bold">{course.price}</span>
//                                 </div>
//                             </div>
//                         </a>
//                     </div>
//                 ))}

//                 {/* Button */}
//                 <div className="w-full mt-8 flex justify-center">
//                     <a
//                         href="/user"
//                         className="bg-blue-600 text-white py-2 px-4 rounded-lg text-lg font-medium hover:bg-blue-700"
//                     >
//                         see all available courses          </a>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default CommonCore;


import React, { useState } from 'react';
import bgImage from "../../assets/images/mm2c.png"
import { useTranslation } from 'react-i18next';

import {
  useGetTeachersQuery,
  useGetCoursesByTeacherIdQuery,
  useGetLecturesByCourseIdQuery,
  useGetCourseRandomQuery,
  useGetCategoriesQuery, // Import the new hook
  useGetProductsByCategoryQuery
} from '../../store/services/SupService';
import CoursesCardList from './CoursesCardList';
import Navs from "./HomeNav";
import { Link } from 'react-router-dom';
import Footer from './FooterLms';


const CourseSelection = () => {
  const { t } = useTranslation();

    const coursesecound = [
                {
                    id: 1,
                    url: 'https://ajitfhamacademy.teachable.com/digital-products/517175',
                    image: bgImage,
                    title: 'Course and exercise book 2 bac sm',
                    fileCount: '1',
                    description: 'This book contains all the courses and exercises for level 2 Bac Sm with the correction of the exercises.',
                    authorImage: 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:30,height:30/https://www.filepicker.io/api/file/lrMGynjUSuyo0x8IHiQn',
                    authorName: 'zakaria bouicha',
                    price: 'FREE',
                },
                // Add more course objects here if needed
            ];
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState(null);


  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(''); // "teacher", "subject", or "search"
  const [activeTabsecound, setActiveTabsecound] = useState(true); // "teacher", "subject", or "search"

  const { data: teachers, isLoading: teachersLoading, isError: teachersError } = useGetTeachersQuery();
  const { data: courses, isLoading: coursesLoading, isError: coursesError } = useGetCoursesByTeacherIdQuery(selectedTeacherId, { skip: !selectedTeacherId });
  const { data: allCoursesData, isLoading: allCoursesLoading, isError: allCoursesError } = useGetCourseRandomQuery();
  const { data: lectures, isLoading: lecturesLoading, isError: lecturesError } = useGetLecturesByCourseIdQuery(selectedCourseId, { skip: !selectedCourseId });
  const { data: categories, isLoading: categoriesLoading, isError: categoriesError } = useGetCategoriesQuery(); // Fetch categories

  const { data: products, isLoading, isError } = useGetProductsByCategoryQuery(selectedCategoryId, { skip: !selectedCategoryId });


  console.log("teachers",teachers)
  console.log("courses",courses)
  console.log("allCoursesData",allCoursesData)
  console.log("lectures",lectures)
  console.log("categories",categories)
  console.log("selectedCategory",selectedCategory)

  console.log("products",products)

  // Ensure allCourses is an array
  const allCourses = Array.isArray(allCoursesData) ? allCoursesData : [];

  const handleTeacherChange = (e) => {
    const value = e.target.value;
    setSelectedTeacherId(value);
    setSelectedCourseId(null);
    setActiveTabsecound(false)
    setSelectedCategory(''); // Reset category selection
    setSelectedCategoryId('');
    setSearchQuery(''); // Reset search query
    setActiveTab('teacher');
  };

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
    setSelectedCategory(e.target.value);
    setSelectedTeacherId(null); // Reset teacher selection
    setSelectedCourseId(null);
    setActiveTabsecound(false)
    setSearchQuery(''); // Reset search query
    setActiveTab('subject');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setSelectedTeacherId(null); // Reset teacher selection
    setSelectedCourseId(null);
    setActiveTabsecound(false)
    setSelectedCategory(''); // Reset category selection
    setActiveTab('search');
  };

  const filteredCoursesByCategory = () => {
    if (selectedCategory.toLowerCase() === 'all') {
      return allCourses;
    }
    return allCourses.filter(course => course.category.toLowerCase() === selectedCategory.toLowerCase());
  };

  const filteredCoursesBySearch = () => {
    return allCourses.filter(course => course.name.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  return (

<>
<Navs />
<div className="p-4 space-y-8 mt-[60px] bg-gray-200">
      {/* Selection Options */}
      <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
        {/* Teacher Selection */}
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            {/* Teacher Based */}
            {t('commoncore-top-menu-teacher')}
            </h2>
          {teachersLoading && <p>
            
            {/* Loading teachers... */}
            {t('commoncore-top-menu-teacher-loading')}

            </p>}
          {teachersError && <p>
            
            {/* Error loading teachers. */}
            {t('commoncore-top-menu-teacher-error')}

            
            </p>}
          {!teachersLoading && !teachersError && (
            <select 
              onChange={handleTeacherChange}
              className="p-2 border rounded-md w-full"
              defaultValue=""
            >
              <option value="" disabled>
                
                {/* Select a teacher */}
                {t('commoncore-top-menu-teacher-select')}

                </option>
              <option value="All">
                All Teachers</option>
              {teachers?.teachers.map((teacher) => (
                <option key={teacher._id} value={teacher._id}>{teacher.name}</option>
              ))}
            </select>
          )}
        </div>

{/* subject base */}
<div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            {/* Subject Based */}
            {t('commoncore-top-menu-Subject')}

            </h2>
          {categoriesLoading && <p>
            
            {/* Loading Subject... */}
            {t('commoncore-top-menu-subject-loading')}

            </p>}
          {categoriesError && <p>
            
            {/* Error loading Subject. */}
            {t('commoncore-top-menu-Subject-error')}

            </p>}
          {!categoriesLoading && !categoriesError && (
            <select 
              onChange={handleCategoryChange}
              className="p-2 border rounded-md w-full"
              defaultValue=""
            >
              <option value="" disabled>
                
                {/* Select a Subject */}
                {t('commoncore-top-menu-subject-slect')}

                </option>
              <option value="All">
                
                All Subject

                </option>
              {teachers?.catagory.map((catagory) => (
                <option key={catagory._id} value={catagory.catagory}>{catagory.name}</option>
              ))}
            </select>
          )}
        </div>

        {/* Search Input */}
        <div className="flex-1">
          <h2 className="text-lg font-bold mb-2">
            {/* Search Based */}
            {t('commoncore-top-menu-Search')}

            </h2>
          <input
            type="text"
            placeholder={t('commoncore-top-menu-Search-placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
            className="p-2 border rounded-md w-full"
          />
        </div>
      </div>

      {/* Display Data */}
      <div>
        {/* Teacher Based Data */}
        {activeTab === 'teacher' && selectedTeacherId && (
          <>
            {coursesLoading && <p>Loading courses...</p>}
            {coursesError && <p>Error loading courses.</p>}
            {!coursesLoading && !coursesError && selectedTeacherId !== 'All' && courses?.length > 0 && (
              <CoursesCardList courses={courses} />
            )}
            {!coursesLoading && !coursesError && selectedTeacherId === 'All' && allCourses.length > 0 && (
              <CoursesCardList courses={allCourses} />
            )}
          </>
        )}

        {/* random Based Data */}
        {activeTabsecound && (
        <div  className="flex items-center justify-center w-full h-full mx-auto ">
                   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    
    <Link 
      key={4+4} 
      to={`/coursesmainsingle/66c70d718b31459945fbdf6f`} 
      className="block"
    >
      <div className="p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 border border-transparent hover:border-red-500 hover:-translate-y-1 transform transition-transform duration-300">
        {/* Course Thumbnail */}
       
          <img 
            src={bgImage}
            alt={4+4} 
            // className="w-full h-48 object-cover rounded-t-lg mb-4" 
            className="w-full object-cover"
             style={{ height: 'auto', aspectRatio: '16/9' }}
          />
        
        {/* Course Title */}
        <h3 className="text-lg font-semibold">Mohsan</h3>
        <p className="text-gray-600 mb-4">This book contains all the courses and exercises for level 2 Bac Sm with the correction of the exercises.</p>

        {/* Price Section */}
        <div className="flex justify-between items-center mt-4">
          <p className="text-green-500 font-bold"> {t('price')} :300</p>
          <p className="text-gray-500 line-through"> {t('price')}: 350</p>
        </div>
      </div>
    </Link>
  
</div>
        </div>
        )}

        {/* Subject Based Data */}

            {activeTab === 'subject' && selectedCategoryId && (
          <>
            {isLoading && <p>Loading courses...</p>}
            {isError && <p>Error loading courses.</p>}
            {!isLoading && !isError && selectedCategoryId !== 'All' && products?.length > 0 && (
              <CoursesCardList courses={products} />
            )}
            {!isLoading && !isError && selectedCategoryId === 'All' && allCourses.length > 0 && (
              <CoursesCardList courses={products} />
            )}
          </>
        )}

        {/* Search Based Data */}
        {activeTab === 'search' && searchQuery && (
          <>
            {allCoursesLoading && <p>Loading courses...</p>}
            {allCoursesError && <p>Error loading courses.</p>}
            {!allCoursesLoading && !allCoursesError && filteredCoursesBySearch()?.length > 0 && (
              <CoursesCardList courses={filteredCoursesBySearch()} />
            )}
          </>
        )}
      </div>

      {/* Lectures */}
      {selectedCourseId && (
        <>
          {lecturesLoading && <p>Loading lectures...</p>}
          {lecturesError && <p>Error loading lectures.</p>}
          {!lecturesLoading && !lecturesError && lectures?.length > 0 && (
            <div className="p-4 border rounded-md">
              <h3 className="text-xl font-semibold mb-2">Lectures:</h3>
              <ul>
                {lectures.map((lecture) => (
                  <li key={lecture.id}>{lecture.name}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
    <Footer/>
</>

  
  );
};

export default CourseSelection;



