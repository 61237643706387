


import React, { useState, useEffect } from 'react';
import exampleImage from '../../assets/contacta.webp'; 
import NavDubai from './NavDubai';
const mapEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34326.960849369905!2d55.30061283402954!3d25.264620811976506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cc9fd734caf%3A0x9caf12adcb822a37!2sGhanim%20Building!5e0!3m2!1sen!2s!4v1725719101871!5m2!1sen!2s';


// Adjust the path according to the location of your component
const skeletonStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: '#e0e0e0',
  borderRadius: '8px',
  animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
};

const pulseKeyframes = `
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;





const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [mapError, setMapError] = useState(false);

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust time as needed for your use case
  }, []);
  
  return (



    <>
    <NavDubai />
    
    <div>
      {/* Top Div with Contact Us Text */}
      <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 mt-[96px] h-[210px] flex items-center justify-center text-white">
        <div className="flex items-center">
          <hr className="w-16 border-white mr-4" />
          <div className="text-3xl font-bold">📞 Contact Us</div>
          <hr className="w-16 border-white ml-4" />
        </div>
      </div>

      {/* Responsive Layout for Form and Image */}
      <div className="flex flex-col lg:flex-row bg-gradient-to-r from-gray-100 to-gray-200  lg:p-16">
        {/* Image Section */}
        {/* <div className="lg:w-1/2 w-full  h-auto flex items-center justify-center mb-8 lg:mb-0">
          <img
            src={exampleImage}
            alt="Contact"
            className="object-cover w-full h-full rounded-lg shadow-xl border-4 border-white transform hover:scale-105 transition-transform duration-300 ease-in-out"
            style={{ maxWidth: '350px', maxHeight: '400px' }} // Increased max width and height for a more prominent image
          />
        </div> */}
           <div className="lg:w-1/2 text-center flex flex-col items-center justify-center">
           {loading ? (
                     <div style={skeletonStyle} />

          ) : imageError ? (
            <div className="text-red-500">Failed to load image.</div>
          ) : (
            // <img
            //   src={exampleImage}
            //   alt="Contact"
            //   className="shrink-0 w-5/6 rounded-lg shadow-xl border-4 border-white transform hover:scale-105 transition-transform duration-300 ease-in-out"
            //   onError={() => setImageError(true)}
            // />
            <img  
            src={exampleImage}
            alt="Contact"
            onError={() => setImageError(true)}
            class="shrink-0 w-5/6" 
            />


          )}
          {/* <img  src={exampleImage} class="shrink-0 w-5/6" /> */}
        </div>

        {/* Form Section */}
        <div className="lg:w-1/2 w-full bg-red-400">
        {/* <div className="lg:w-1/2 w-full bg-red-400 lg:p-10 rounded-lg shadow-lg"> */}
          {/* <p className="text-center mb-6 text-lg font-semibold text-gray-700">
            We would love to hear from you! Please fill out the form below, and our team will get in touch with you shortly.
          </p> */}

          <form className="space-y-6 bg-gradient-to-r from-gray-50 to-gray-100 p-8 rounded-lg shadow-xl">
          <p className="text-center mb-6 text-lg font-semibold text-gray-700">
            We would love to hear from you! Please fill out the form below, and our team will get in touch with you shortly.
          </p>
            <div>
              <label className="block text-sm font-medium text-gray-700">Your Name</label>
              <input
                type="text"
                className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Company</label>
              <input
                type="text"
                className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
                placeholder="Enter your company name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Country</label>
              <input
                type="text"
                className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
                placeholder="Enter your country"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Enquiry Details</label>
              <textarea
                className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
                rows="4"
                placeholder="Enter your enquiry details"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-6 bg-gradient-to-r from-purple-600 to-purple-700 text-white font-bold rounded-lg shadow-lg hover:bg-purple-800 transition duration-300 ease-in-out"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* Visit Us Section with Google Map and Info Boxes */}
      <div className="relative">
        {/* Header for Visit Us Section */}
        <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 h-[210px] flex items-center justify-center text-white">
          <div className="flex items-center">
            <hr className="w-16 border-white mr-4" />
            <div className="text-3xl font-bold">🌍 Visit Us</div>
            <hr className="w-16 border-white ml-4" />
          </div>
        </div>

        {/* Main Container for Google Map and Info Boxes */}
        <div className="flex flex-col lg:flex-row lg:space-x-8 p-8 lg:p-16 bg-gradient-to-r from-gray-100 to-gray-200">
          {/* Google Map Section */}
          <div className="lg:w-1/2 w-full h-80 lg:h-[500px] mb-8 lg:mb-0">
            <iframe
              className="w-full h-full rounded-lg shadow-lg"
              src={mapEmbedUrl}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>

          {/* Info Boxes Section */}
          <div className="lg:w-1/2 w-full flex flex-col space-y-4 lg:space-y-8 mb-4 lg:mb-0">
            {/* Address Box */}
            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
              <h3 className="text-lg font-semibold mb-2 text-gray-800">📍 Our Address</h3>
              <p className="mb-2">Office Number M-13,</p>
              <p className="mb-2">Obaid Ghanim Al Mutaiwei Building,</p>
              <p className="mb-2">Al Ras, Dubai, UAE </p>
            </div>

            {/* Contact Box */}
            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
              <h3 className="text-lg font-semibold mb-2 text-gray-800">📞 Contact Us</h3>
              <p className="mb-2">Phone: +971 527 84 2024</p>
              <p>
                Email:{' '}
                <a href="mailto:operations.wgk@gmail.com" className="text-indigo-600">
                  operations.wgk@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Careers Section */}
      <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 h-[210px] flex items-center justify-center text-white">
        <div className="flex items-center">
          <hr className="w-16 border-white mr-4" />
          <div className="text-3xl font-bold">🌍 Careers </div>
          <hr className="w-16 border-white ml-4" />
        </div>
      </div>
      <div className="bg-gradient-to-r from-gray-100 to-gray-200 p-8 lg:p-16">
        <div className="max-w-3xl mx-auto p-8 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Join Our Team</h2>
          <p className="text-gray-700 mb-4">
            At  WGK Company, we're always looking for talented and motivated individuals to join our team. If you're passionate about
            your work and ready to make a difference, we want to hear from you!
          </p>
          <a
            href="/careers"
            className="text-indigo-600 hover:text-indigo-800 font-semibold transition duration-300 ease-in-out"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
    
    </>
   
  );
};

export default ContactUs;








// import React, { useState, useEffect } from 'react';
// import exampleImage from '../../assets/contacta.webp'; 
// import NavDubai from './NavDubai';
// const mapEmbedUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34326.960849369905!2d55.30061283402954!3d25.264620811976506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cc9fd734caf%3A0x9caf12adcb822a37!2sGhanim%20Building!5e0!3m2!1sen!2s!4v1725719101871!5m2!1sen!2s';

// const skeletonStyle = {
//   width: '100%',
//   height: '100%',
//   backgroundColor: '#e0e0e0',
//   borderRadius: '8px',
//   animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
// };

// const pulseKeyframes = `
//   @keyframes pulse {
//     0% { opacity: 1; }
//     50% { opacity: 0.5; }
//     100% { opacity: 1; }
//   }
// `;

// const ContactUs = () => {
//   const [loading, setLoading] = useState(true);
//   const [imageError, setImageError] = useState(false);

//   useEffect(() => {
//     // Simulate loading data
//     setTimeout(() => {
//       setLoading(false);
//     }, 2000);
//   }, []);

//   return (
//     <>
//       <NavDubai />
      
//       {/* Top Div with Contact Us Text */}
//       <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 mt-[96px] h-[210px] flex items-center justify-center text-white">
//         <div className="flex items-center">
//           <hr className="w-16 border-white mr-4" />
//           <div className="text-3xl font-bold">📞 Contact Us</div>
//           <hr className="w-16 border-white ml-4" />
//         </div>
//       </div>

//       {/* Responsive Layout for Form and Image */}
//       <div className="flex flex-col lg:flex-row bg-gradient-to-r from-gray-100 to-gray-200 lg:p-16 space-y-8 lg:space-y-0">
//         {/* Image Section */}
//         <div className="lg:w-1/2 w-full flex items-center justify-center">
//           {loading ? (
//             <div style={skeletonStyle} />
//           ) : imageError ? (
//             <div className="text-red-500">Failed to load image.</div>
//           ) : (
//             <img  
//               src={exampleImage}
//               alt="Contact"
//               onError={() => setImageError(true)}
//               className="w-full h-auto max-w-md rounded-lg shadow-xl border-4 border-white transform hover:scale-105 transition-transform duration-300 ease-in-out"
//             />
//           )}
//         </div>

//         {/* Form Section */}
//         <div className="lg:w-1/2 w-full bg-gradient-to-r from-gray-50 to-gray-100 p-8 rounded-lg shadow-xl">
//           <p className="text-center mb-6 text-lg font-semibold text-gray-700">
//             We would love to hear from you! Please fill out the form below, and our team will get in touch with you shortly.
//           </p>
//           <form className="space-y-6">
//             <div>
//               <label className="block text-sm font-medium text-gray-700">Your Name</label>
//               <input
//                 type="text"
//                 className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
//                 placeholder="Enter your name"
//               />
//             </div>
//             <div>
//               <label className="block text-sm font-medium text-gray-700">Company</label>
//               <input
//                 type="text"
//                 className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
//                 placeholder="Enter your company name"
//               />
//             </div>
//             <div>
//               <label className="block text-sm font-medium text-gray-700">Email</label>
//               <input
//                 type="email"
//                 className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
//                 placeholder="Enter your email"
//               />
//             </div>
//             <div>
//               <label className="block text-sm font-medium text-gray-700">Country</label>
//               <input
//                 type="text"
//                 className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
//                 placeholder="Enter your country"
//               />
//             </div>
//             <div>
//               <label className="block text-sm font-medium text-gray-700">Enquiry Details</label>
//               <textarea
//                 className="mt-1 block w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition ease-in-out duration-150"
//                 rows="4"
//                 placeholder="Enter your enquiry details"
//               />
//             </div>
//             <button
//               type="submit"
//               className="w-full py-3 px-6 bg-gradient-to-r from-purple-600 to-purple-700 text-white font-bold rounded-lg shadow-lg hover:bg-purple-800 transition duration-300 ease-in-out"
//             >
//               Submit
//             </button>
//           </form>
//         </div>
//       </div>

//       {/* Visit Us Section with Google Map and Info Boxes */}
//       <div className="relative">
//         {/* Header for Visit Us Section */}
//         <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 h-[210px] flex items-center justify-center text-white">
//           <div className="flex items-center">
//             <hr className="w-16 border-white mr-4" />
//             <div className="text-3xl font-bold">🌍 Visit Us</div>
//             <hr className="w-16 border-white ml-4" />
//           </div>
//         </div>

//         {/* Main Container for Google Map and Info Boxes */}
//         <div className="flex flex-col lg:flex-row lg:space-x-8 p-8 lg:p-16 bg-gradient-to-r from-gray-100 to-gray-200">
//           {/* Google Map Section */}
//           <div className="lg:w-1/2 w-full h-80 lg:h-[500px] mb-8 lg:mb-0">
//             <iframe
//               className="w-full h-full rounded-lg shadow-lg"
//               src={mapEmbedUrl}
//               allowFullScreen=""
//               loading="lazy"
//             ></iframe>
//           </div>

//           {/* Info Boxes Section */}
//           <div className="lg:w-1/2 w-full flex flex-col space-y-4 lg:space-y-8 mb-4 lg:mb-0">
//             {/* Address Box */}
//             <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
//               <h3 className="text-lg font-semibold mb-2 text-gray-800">📍 Our Address</h3>
//               <p className="mb-2">Office Number M-13,</p>
//               <p className="mb-2">Obaid Ghanim Al Mutaiwei Building,</p>
//               <p className="mb-2">Al Ras, Dubai, UAE</p>
//             </div>

//             {/* Contact Box */}
//             <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
//               <h3 className="text-lg font-semibold mb-2 text-gray-800">📞 Contact Us</h3>
//               <p className="mb-2">Phone: +971 527 84 2024</p>
//               <p>
//                 Email:{' '}
//                 <a href="mailto:operations.wgk@gmail.com" className="text-indigo-600 hover:underline">
//                   operations.wgk@gmail.com
//                 </a>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Careers Section */}
//       <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 h-[210px] flex items-center justify-center text-white">
//         <div className="flex items-center">
//           <hr className="w-16 border-white mr-4" />
//           <div className="text-3xl font-bold">🌍 Careers</div>
//           <hr className="w-16 border-white ml-4" />
//         </div>
//       </div>
//       <div className="bg-gradient-to-r from-gray-100 to-gray-200 p-8 lg:p-16">
//         <div className="max-w-3xl mx-auto p-8 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg">
//           <h2 className="text-2xl font-bold mb-4 text-gray-800">Join Our Team</h2>
//           <p className="text-gray-700 mb-4">
//             At WGK Company, we're always looking for talented and motivated individuals to join our team. If you're passionate about
//             your work and ready to make a difference, we want to hear from you!
//           </p>
//           <a
//             href="/careers"
//             className="inline-block px-6 py-3 bg-gradient-to-r from-purple-600 to-purple-700 text-white font-bold rounded-lg shadow-lg hover:bg-purple-800 transition duration-300 ease-in-out"
//           >
//             See Open Positions
//           </a>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ContactUs;





















