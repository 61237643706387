import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import exampleImage from '../../asset/bgabout.png'; // Adjust the path according to the location of your component
import agri from '../../asset/agri.png'; // Adjust the path according to the location of your component
import ImageSection from './ImageSection';
import aa from '../../asset/aa.jpg'; // Adjust the path according to the location of your component
import bb from '../../asset/bb.jpg'; // Adjust the path according to the location of your component
import cc from '../../asset/cc.jpg'; // Adjust the path according to the location of your component
import dd from '../../asset/dd.jpg'; // Adjust the path according to the location of your component
import ee from '../../asset/ee.jpg'; // Adjust the path according to the location of your component

const AboutDubai = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [activeSection, setActiveSection] = useState(0); // New state for active section


    const slides = [
      {
        image: `${aa}`,
        title: 'Breathtaking Nature',
        description: 'Explore the beauty of untouched landscapes and serene vistas.',
        buttonText: 'Discover More',
        onClick: () => {
          console.log('Breathtaking Nature clicked');
        },
      },
      {
        image: `${bb}`,
        title: 'Urban Exploration',
        description: 'Dive into the heart of the city and uncover its secrets.',
        buttonText: 'Learn More',
        onClick: () => {
          console.log('Urban Exploration clicked');
        },
      },
      {
        image: `${cc}`,
        title: 'Futuristic Tech',
        description: 'Discover the cutting-edge technologies shaping our world.',
        buttonText: 'Explore Now',
        onClick: () => {
          console.log('Futuristic Tech clicked');
        },
      },
      {
        image: `${dd}`,
        title: 'Ocean Wonders',
        description: 'Dive deep into the mysteries of the ocean.',
        buttonText: 'Dive In',
        onClick: () => {
          console.log('Ocean Wonders clicked');
        },
      },
    ];
  

    const testimonials = [
      {
        id: 1,
        text: 'At WGK, we are dedicated to enriching lives through the finest spices and premium coffee. Our mission is to deliver exceptional quality, unparalleled flavor, and a commitment to sustainability, enhancing culinary experiences and fostering a love for exceptional ingredients.',
        author: 'WGK Team',
        location: 'Dubai, UAE'
      },
      {
        id: 2,
        text: 'WGK’s commitment to quality is unmatched. Their spice blends and coffee solutions have truly enhanced our culinary offerings. We appreciate their dedication to sustainability and their exceptional customer service.',
        author: 'Gourmet Chef',
        location: 'Dubai, UAE'
      },
      {
        id: 3,
        text: 'The quality of WGK’s products is top-notch. Their consulting services helped us craft the perfect spice blends and coffee options for our menu. We look forward to continuing our partnership and exploring new flavors with WGK.',
        author: 'Restaurant Owner',
        location: 'Dubai, UAE'
      }
    ];
    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    };
  
    const goToSlide = (index) => {
      setCurrentIndex(index);
    };

  return (
    <>
      {/* First Section */}


       {/* First Section */}
       <div id='aboutus'
                className="relative bg-cover bg-center py-24 px-4 sm:px-6 lg:px-8 mt-[100px]"
                style={{ backgroundImage: `url(${exampleImage})` }}
            >
                {/* Black Overlay */}
                <div className="absolute inset-0 bg-black opacity-60"></div>

                {/* Centered Content */}
                <div className="relative z-10 flex flex-col items-center justify-center text-center space-y-4 max-w-2xl mx-auto">
                    <h1 className="text-4xl font-bold text-white">
                        WGK
                    </h1>
                    <h2 className="text-xl font-semibold text-white">
                        A Leading Name in Spices and Coffee
                    </h2>
                    <p className="text-base text-white">
                        At WGK, we are dedicated to enriching lives through the finest spices and premium coffee. Our mission is to deliver exceptional quality, unparalleled flavor, and a commitment to sustainability, enhancing culinary experiences and fostering a love for exceptional ingredients.
                    </p>
                </div>
            </div>

            {/* Second Section */}
            <div
                className="relative bg-cover bg-center py-24 px-4 sm:px-6 lg:px-8"
                style={{ backgroundImage: `url(${agri})` }}
            >
                {/* Black Overlay */}
                <div className="absolute inset-0 bg-black opacity-60"></div>

                {/* Centered Content */}
                <div className="relative z-10 flex flex-col items-center justify-center text-center space-y-4 max-w-2xl mx-auto">
                    <h1 className="text-4xl font-bold text-white">
                        Core Values
                    </h1>
                    <p className="text-base text-white">
                        <strong>Quality:</strong> Sourcing and providing only the highest quality spices and coffee, ensuring freshness and flavor in every product.
                    </p>
                    <p className="text-base text-white">
                        <strong>Sustainability:</strong> Committed to environmentally-friendly practices and ethical sourcing to protect our planet and support local communities.
                    </p>
                    <p className="text-base text-white">
                        <strong>Customer Delight:</strong> Prioritizing our customers by offering personalized service, expert recommendations, and an exceptional experience.
                    </p>
                    <p className="text-base text-white">
                        <strong>Innovation:</strong> Continuously exploring new flavors and blends to offer unique and exciting products that inspire creativity in the kitchen.
                    </p>
                </div>
            </div>

            {/* Third Section */}
            <div id='services' className="py-24 px-4 sm:px-6 lg:px-8 bg-gray-100">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-800">
                        Key Services
                    </h2>
                </div>
                <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Service Card */}
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Spice Blends</h3>
                        <p className="text-base text-gray-700 mb-4">
                            A diverse range of custom and ready-made spice blends designed to enhance every dish, from everyday meals to gourmet creations.
                        </p>
                    </div>
                    {/* Service Card */}
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Coffee Solutions</h3>
                        <p className="text-base text-gray-700 mb-4">
                            Offering a variety of single-origin coffees, blends, and brewing equipment, catering to both individual and commercial needs.
                        </p>
                    </div>
                    {/* Service Card */}
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Consulting Services</h3>
                        <p className="text-base text-gray-700 mb-4">
                            Expert advice on spice and coffee selection, menu development, and flavor pairing for restaurants and culinary professionals.
                        </p>
                    </div>
                    {/* Service Card */}
                    <div className="bg-white shadow-lg rounded-lg p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Subscription Boxes</h3>
                        <p className="text-base text-gray-700 mb-4">
                            Curated spice and coffee boxes delivered monthly, featuring new and exclusive products to explore and enjoy.
                        </p>
                    </div>
                </div>
            </div>






















      {/* Third Section */}

            <div id="testimonial" className="py-24 px-4 sm:px-6 lg:px-8 bg-gray-300">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          What Our Fantastic Customers Say
        </h2>
        <p className="text-base text-gray-600">
          Here’s what some of our valued customers have to say about their experience with us.
        </p>
      </div>
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="bg-white shadow-lg rounded-lg p-6 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
          >
            <p className="text-base text-gray-700 mb-4">
              {testimonial.text}
            </p>
            {testimonial.author && (
              <p className="font-bold text-gray-800 mt-4">
                {testimonial.author}
              </p>
            )}
            {testimonial.location && (
              <p className="text-gray-600">
                {testimonial.location}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>











        
    

     

      {/* Fourth Section */}
      <div  className="text-center py-12 bg-gradient-to-r from-indigo-600 to-purple-800 text-white mb-2">
          <h2 className="text-4xl font-bold text-Black">Awards</h2>
        </div>
      <div id='award' className="relative h-screen w-full bg-red">
        {/* <div className="text-center py-12">
          <h2 className="text-4xl font-bold text-Black">Awar</h2>
        </div> */}

        <div
          className="absolute inset-0 bg-cover bg-center md:mx-[20%] mt-6"
          style={{ backgroundImage: `url(${slides[currentIndex].image})` }}
        >
          <div className="absolute inset-0 "></div>

          <div className="absolute inset-0 flex flex-col justify-center items-center text-white px-4 md:px-8">
            {/* <h2 className="text-3xl md:text-5xl font-bold mb-4 text-center">
              {slides[currentIndex].title}
            </h2> */}
            {/* <p className="text-lg md:text-2xl mb-8 text-center">
              {slides[currentIndex].description}
            </p> */}
            {/* <button
              className="px-6 py-2 bg-blue-500 rounded-md text-white font-semibold"
              onClick={slides[currentIndex].onClick}
            >
              {slides[currentIndex].buttonText}
            </button> */}
          </div>

          {/* Arrows */}
          <div className="absolute top-1/2 left-0 transform -translate-y-1/2 px-4 cursor-pointer">
            <FaArrowLeft
              className="text-white text-2xl"
              onClick={prevSlide}
            />
          </div>
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 px-4 cursor-pointer">
            <FaArrowRight
              className="text-white text-2xl"
              onClick={nextSlide}
            />
          </div>

          {/* Dots */}
          <div className="absolute bottom-4 w-full flex justify-center space-x-2">
            {slides.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full cursor-pointer ${
                  index === currentIndex ? 'bg-white' : 'bg-gray-500'
                }`}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>
      <div id='certf' className=' mt-[200px] text-black'>

      </div>
      <ImageSection  />
    </>
  );
};

export default AboutDubai;
