// import {Link, useNavigate, useParams} from "react-router-dom"
// import { useTranslation } from "react-i18next";

// import {useState, useEffect} from "react"
// import { useDispatch } from "react-redux"
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import ScreenHeader from "../../components/ScreenHeader";
// import Wrapper from "./Wrapper"
// import WrapperTeacher from "./WrapperTeacher"
// import { setSuccess } from "../../store/reducers/globalReducer";
// import { useFetchCategoryQuery, useUpdateCategoryMutation } from "../../store/services/categoryServiceTeacher";
// import Spinner from "../../components/Spinner";
// const UpdateCategory = () => {
//     const { t } = useTranslation(); // Using the useTranslation hook

//     const [state, setState] = useState('');
//     const {id} = useParams();
//     const {data, isFetching} = useFetchCategoryQuery(id);
//     console.log('category data: ',data)
//     useEffect(() => {
//         data?.category && setState(data?.category?.name);
//     }, [data?.category])
//     const [saveCategory, response] = useUpdateCategoryMutation();
//     console.log(response)
//      const errors = response?.error?.data?.errors ? response?.error?.data?.errors : [];
//     const updateSubmit = e => {
//        e.preventDefault();
//        saveCategory({name: state, id});
//    }
//    const navigate = useNavigate();
//    const dispatch = useDispatch();
//    useEffect(() => {
//        if(response?.isSuccess) {
//            dispatch(setSuccess(response?.data?.message));
//           navigate('/dashboard/tcategories');
//        }
//    }, [response?.isSuccess])
//     return(
//        <WrapperTeacher>
//            <ScreenHeaderTeacher>
//               <Link to="/dashboard/teacher/tcategories" className="btn-dark"><i className="bi bi-arrow-left-short"></i>
//                {/* categories list */}
//                {t("dashboard-teacher-updateCategory-backToCategoriesList")}
//                </Link>
//            </ScreenHeaderTeacher>
//            {!isFetching ? <form className="w-full md:w-8/12" onSubmit={updateSubmit}>
//                <h3 className="text-lg capitalize mb-3">
//                 {/* Update category */}
//                 {t("dashboard-teacher-updateCategory-updateCategory")}
//                 </h3>
//                {errors.length > 0 && errors.map((error, key) => (
//                    <p className="alert-danger" key={key}>{error.msg}</p>
//                ))}
//                <div className="mb-3">
//                    {/* <input type="text" name="" className="form-control" placeholder="Category Name..." value={state} onChange={(e) => setState(e.target.value)} /> */}
//                    <input type="text" name="" className="form-control"  placeholder={t("dashboard-teacher-updateCategory-categoryNamePlaceholder")} value={state} onChange={(e) => setState(e.target.value)} />
//                </div>
//                <div className="mb-3">
//                    {/* <input type="submit" value='Update' className="btn btn-indigo" /> */}
//                    <input type="submit" value={t("dashboard-teacher-updateCategory-updateButton")} className="btn btn-indigo" />
//                </div>
//            </form> : <Spinner />}
//        </WrapperTeacher>
//     )
// }
// export default UpdateCategory;













import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useGetCategoryByIdQuery, useUpdateCategoryMutation } from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import ImagesPreview from "../../components/ImagesPreview";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";

const EditCategory = () => {
    const { id } = useParams(); // To get category ID from URL
    const [value, setValue] = useState(''); // For description editor
    const [state, setState] = useState({
        name: '',
        image1: '',
    });
    const [preview, setPreview] = useState({
        image1: ''
    });

    const { data, error, isLoading } = useGetCategoryByIdQuery(id); // Fetch the existing category
    const [updateCategory, response] = useUpdateCategoryMutation(); // Mutation for updating

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleInput = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const editCategory = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);
        formData.append('image1', state.image1);

        console.log("form data ",formData)
        updateCategory({ id, formData });
    };

    useEffect(() => {
        if (data) {
            setState({
                name: data.name,
                image1: data.image1
            });
            setValue(data.description);
            setPreview({
                image1: data.image1
            });
        }
        if (error) {
            toast.error("Failed to load the category details");
        }
    }, [data, error]);

    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error?.data?.errors]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response.data.msg));
            navigate('/dashboard/teacher/tcategories');
        }
    }, [response?.isSuccess]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher/tcategories" className="btn-dark p-2 bg-blue-500 rounded hover:bg-red-500">
                    <i className="bi bi-arrow-left-short"></i>
                    Category List
                </Link>
            </ScreenHeaderTeacher>
            <Toaster position="top-right" reverseOrder={true} />
            <div className="flex flex-wrap -mx-3 bg-yellow-300">
                <form className="w-full xl:w-8/12 p-3" onSubmit={editCategory}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="name" className="label">
                                Name
                            </label>
                            <input type="text" name="name" className="form-control" id="name" placeholder="Name..." onChange={handleInput} value={state.name} />
                        </div>

                        <div className="w-full p-3">
                            <label htmlFor="image1" className="label">
                                Image 1
                            </label>
                            <input type="file" name="image1" id="image1" className="input-file" onChange={imageHandle} />
                        </div>

                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">
                                Description
                            </label>
                            <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                        </div>

                        <div className="w-full p-3">
                            <input type="submit" value={response.isLoading ? 'Loading...' : 'Update Category'} disabled={response.isLoading} className="p-2 bg-blue-500 rounded hover:bg-red-500" />
                        </div>
                    </div>
                </form>
                <div className="w-full xl:w-4/12 p-3">
                    <ImagesPreview url={preview.image1} heading="Image 1" />
                </div>
            </div>
        </WrapperTeacher>
    );
};

export default EditCategory;
