












import { clearMessage } from "../../store/reducers/globalReducer";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useCProductMutation,useSearchCoursesQuery, useCProductoilseedMutation,useAllCategoriesQuery,useCoffeeTypesQuery,useGetCategoryByNameQuery,useGetAllCoffeeTypesByCategoryQuery ,useGetallCatagoryMainQuery,useGetallCatagoryForTypeAdminQuery,useGetallCatagoryTypeMainQuery,useGetallCatagoryTypeMainWithOutpageQuery} from "../../store/services/productServiceTeacher";


import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "../../components/Spinner"; // Assuming you have a Spinner component for loading

const SearchProductDubai = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  const [searchFields, setSearchFields] = useState({ productName: "", coffeeTypeName: "" });
  const [searchTerm, setSearchTerm] = useState(""); // Combined search term
  const { data = [], isFetching } = useSearchCoursesQuery(searchTerm, { skip: !searchTerm });
  const { success } = useSelector(state => state.globalReducer);
  const dispatch = useDispatch();
console.log("data",data)
  useEffect(() => {
    if (success) {
      toast.success(success);
    }
    return () => {
      dispatch(clearMessage());
    };
  }, [success, dispatch]);

  const handleSearch = () => {
    const queryParams = new URLSearchParams(
      Object.fromEntries(
        Object.entries(searchFields).filter(([key, value]) => value.trim() !== '')
      )
    ).toString();
    
    setSearchTerm(queryParams);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchFields(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'productName' ? { coffeeTypeName: '' } : { productName: '' })
    }));
  };

  return (
    <WrapperTeacher>
      <ScreenHeaderTeacher>
        {/* <Link to="/dashboard/create-student" className="btn-dark">Create Student</Link> */}
        <p className="bg-yellow-500 p-4 mt-3 text-black font-bold">
          Product Search
          </p>
        <Toaster position="top-right" />
      </ScreenHeaderTeacher>
      <div>
        <Toaster position="top-right" />
        <div className="flex space-x-4 mb-4 text-black">
          <input
            type="text"
            name="productName"
            placeholder="Product name "
            value={searchFields.productName}
            onChange={handleChange}
            className="p-2 rounded"
          />
          <input
            type="text"
            name="coffeeTypeName"
            placeholder="Product Type Name"
            value={searchFields.coffeeTypeName}
            onChange={handleChange}
            className="p-2 rounded"
          />
          <button
            onClick={handleSearch}
            className="btn btn-primary hover:bg-red-500 bg-blue-500"
          >
            Search

          </button>
        </div>

        {!isFetching ? (
          data.products?.length > 0 ? (
            <>
               <table className="w-full bg-gray-900 rounded-md">
              <thead>
                <tr className="border-b border-gray-800 text-left">
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Product Name 
                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Catagory Name

                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Type Name

                    </th>
                  <th className="p-3 uppercase text-sm font-medium text-gray-500">
                    Edit Prodct 

                    </th>
                </tr>
              </thead>
              <tbody>
                {data.products.map(course => (
                  <tr className="odd:bg-gray-800" key={course._id}>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.name}</td>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.category.name}</td>
                    <td className="p-3 text-sm font-normal text-gray-400">{course.coffeeType.name}</td>
                   
                    <td className="p-3 capitalize text-sm font-normal text-gray-400">
                      <Link
                        to={`/dashboard/edit/indianrice/${course._id}`}
                        className="btn btn-warning"
                      >
                        Edit
                      </Link>
                    </td>
                   
                   
                   
                   
                  </tr>
                ))}
              </tbody>
            </table>


          
            </>
         

            
          ) : <p className="bg-red-500 p-4">
                   No Product Found
          </p>
        ) : <Spinner />}
      </div>
    </WrapperTeacher>
  );
};

export default SearchProductDubai;

