// import { useState, useEffect } from "react";
// import { useParams, Link, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import toast, { Toaster } from 'react-hot-toast';
// import 'react-quill/dist/quill.snow.css';
// import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
// import WrapperTeacher from "./WrapperTeacher";
// import { useGetCategoryByIdQuery, useUpdateCategoryTypeMutation ,   useGetCategoryTypeByIdQuery, useUpdateCategoryTypeByIdMutation} from "../../store/services/productServiceTeacher";
// import Spinner from "../../components/Spinner";
// import { setSuccess } from "../../store/reducers/globalReducer";
// import ReactQuill from "react-quill";

// const UpdateCategoryTypeTeacher = () => {
//     const { id } = useParams(); // Get ID from route params
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
    
//     // Fetch the category details using the ID from the params
//     const { data: category, isFetching, error: fetchError } = useGetCategoryTypeByIdQuery(id);
//     console.log("category",category)
//     // Mutation for updating the category
//     const [updateCategory, response] = useUpdateCategoryTypeByIdMutation();
    
//     // State for form data
//     const [state, setState] = useState({
//         name: '',
//         category: '',
//     });
    
//     const [value, setValue] = useState(''); // For description (using ReactQuill)

//     // Fill form with fetched category data
//     useEffect(() => {
//         if (category) {
//             setState({
//                 name: category.name || '',
//                 category: category.category || '', // Assuming 'category' is also part of the fetched data
//             });
//             setValue(category.description || '');
//         }
//     }, [category]);

//     // Handle form input change
//     const handleInput = e => {
//         setState({ ...state, [e.target.name]: e.target.value });
//     };

//     // Handle form submission
//     const updateCategoryHandler = e => {
//         e.preventDefault();
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(state));
//         formData.append('description', value);

//         // Call the update mutation with the ID and form data
//         updateCategory({ id, formData });
//     };

//     // Handle success and errors during form submission
//     useEffect(() => {
//         if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
//             response.error.data.errors.forEach(err => {
//                 toast.error(err.msg);
//             });
//         } else if (response?.error) {
//             toast.error("An unexpected error occurred.");
//         }
//     }, [response?.error]);

//     // Handle success navigation and message
//     useEffect(() => {
//         if (response?.isSuccess) {
//             dispatch(setSuccess(response.data.msg));
//             navigate('/dashboard/teacher/tcategories/type');
//         }
//     }, [response?.isSuccess, dispatch, navigate]);

//     return (
//         <WrapperTeacher>
//             <ScreenHeaderTeacher>
//                 <Link to="/dashboard/teacher/tcategories/type" className="p-2 bg-blue-500 rounded hover:bg-red-500">
//                     <i className="bi bi-arrow-left-short "></i> Catagory Type List
//                 </Link>
//             </ScreenHeaderTeacher>

//             <Toaster position="top-right" reverseOrder={true} />

//             <div className="flex flex-wrap -mx-3 bg-yellow-300">
//                 <form className="w-full xl:w-8/12 p-3" onSubmit={updateCategoryHandler}>
//                     <div className="flex flex-wrap">
//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="name" className="label">Name</label>
//                             {isFetching ? (
//                                 <p>lodaing </p>
//                             ) : (
//                                 <input
//                                     type="text"
//                                     name="name"
//                                     className="form-control"
//                                     id="name"
//                                     placeholder="name..."
//                                     onChange={handleInput}
//                                     value={state.name}
//                                 />
//                             )}
//                         </div>

//                         <div className="w-full md:w-6/12 p-3">
//                             <label htmlFor="categories" className="label">Categories</label>
//                             {isFetching ? (
//                                 <p>lodaing </p>
//                             ) : fetchError ? (
//                                 <div className="text-red-500">Failed to load category</div>
//                             ) : (
//                                 <select
//                                     name="category"
//                                     id="categories"
//                                     className="form-control"
//                                     onChange={handleInput}
//                                     value={state.category}
//                                 >
//                                     <option value="">Choose category</option>
//                                     {/* Assuming we have a list of categories to choose from */}
//                                     <option value="category1">Category 1</option>
//                                     <option value="category2">Category 2</option>
//                                 </select>
//                             )}
//                         </div>

//                         <div className="w-full p-3">
//                             <label htmlFor="description" className="label">Description</label>
//                             {isFetching ? (
//                                 <p>lodaing </p>
//                             ) : (
//                                 <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
//                             )}
//                         </div>

//                         <div className="w-full p-3">
//                             <input
//                                 type="submit"
//                                 value={response.isLoading ? 'Updating...' : 'Update Category'}
//                                 disabled={response.isLoading}
//                                 className="p-2 bg-blue-500 rounded hover:bg-red-500"
//                             />
//                         </div>
//                     </div>
//                 </form>

//                 <div className="w-full xl:w-4/12 p-3"></div>
//             </div>
//         </WrapperTeacher>
//     );
// };

// export default UpdateCategoryTypeTeacher;












import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import ScreenHeaderTeacher from "../../components/ScreenHeaderTeacher";
import WrapperTeacher from "./WrapperTeacher";
import { useGetCategoryByIdQuery, useUpdateCategoryTypeMutation, useGetCategoryTypeByIdQuery, useUpdateCategoryTypeByIdMutation, useGetallCatagoryForTypeAdminQuery } from "../../store/services/productServiceTeacher";
import Spinner from "../../components/Spinner";
import { setSuccess } from "../../store/reducers/globalReducer";
import ReactQuill from "react-quill";

const UpdateCategoryTypeTeacher = () => {
    const { id } = useParams(); // Get ID from route params
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    // Fetch the category type details using the ID from the params
    const { data: categoryType, isFetching, error: fetchError } = useGetCategoryTypeByIdQuery(id);

    // Fetch all categories for the dropdown
    const { data: categories, isFetching: isFetchingCategories } = useGetallCatagoryForTypeAdminQuery();

    // Mutation for updating the category type
    const [updateCategory, response] = useUpdateCategoryTypeByIdMutation();
    
    // State for form data
    const [state, setState] = useState({
        name: '',
        category: '', // This will store the parent category
    });
    
    const [value, setValue] = useState(''); // For description (using ReactQuill)

    // Fill form with fetched category type data
    useEffect(() => {
        if (categoryType) {
            setState({
                name: categoryType.name || '',
                category: categoryType.category || '', // Set parent category if available
            });
            setValue(categoryType.description || '');
        }
    }, [categoryType]);

    // Handle form input change
    const handleInput = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    // Handle form submission
    const updateCategoryHandler = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('data', JSON.stringify(state));
        formData.append('description', value);

        // Call the update mutation with the ID and form data
        updateCategory({ id, formData });
    };

    // Handle success and errors during form submission
    useEffect(() => {
        if (response?.error?.data?.errors && Array.isArray(response.error.data.errors)) {
            response.error.data.errors.forEach(err => {
                toast.error(err.msg);
            });
        } else if (response?.error) {
            toast.error("An unexpected error occurred.");
        }
    }, [response?.error]);

    // Handle success navigation and message
    useEffect(() => {
        if (response?.isSuccess) {
            dispatch(setSuccess(response.data.msg));
            navigate('/dashboard/teacher/tcategories/type');
        }
    }, [response?.isSuccess, dispatch, navigate]);

    return (
        <WrapperTeacher>
            <ScreenHeaderTeacher>
                <Link to="/dashboard/teacher/tcategories/type" className="p-2 bg-blue-500 rounded hover:bg-red-500">
                    <i className="bi bi-arrow-left-short "></i> Category Type List
                </Link>
            </ScreenHeaderTeacher>

            <Toaster position="top-right" reverseOrder={true} />

            <div className="flex flex-wrap -mx-3 bg-yellow-300">
                <form className="w-full xl:w-8/12 p-3" onSubmit={updateCategoryHandler}>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="name" className="label">Name</label>
                            {isFetching ? (
                                <p>Loading...</p>
                            ) : (
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    id="name"
                                    placeholder="name..."
                                    onChange={handleInput}
                                    value={state.name}
                                />
                            )}
                        </div>

                        <div className="w-full md:w-6/12 p-3">
                            <label htmlFor="categories" className="label">Categories</label>
                            {isFetchingCategories ? (
                                <p>Loading categories...</p>
                            ) : fetchError ? (
                                <div className="text-red-500">Failed to load categories</div>
                            ) : (
                                <select
                                    name="category"
                                    id="categories"
                                    className="form-control"
                                    onChange={handleInput}
                                    value={state.category}
                                >
                                    <option value="">Choose category</option>
                                    {categories?.map(cat => (
                                        <option key={cat._id} value={cat._id}>
                                            {cat.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="w-full p-3">
                            <label htmlFor="description" className="label">Description</label>
                            {isFetching ? (
                                <p>Loading...</p>
                            ) : (
                                <ReactQuill theme="snow" id="description" value={value} onChange={setValue} placeholder="Description..." />
                            )}
                        </div>

                        <div className="w-full p-3">
                            <input
                                type="submit"
                                value={response.isLoading ? 'Updating...' : 'Update Category'}
                                disabled={response.isLoading}
                                className="p-2 bg-blue-500 rounded hover:bg-red-500"
                            />
                        </div>
                    </div>
                </form>

                <div className="w-full xl:w-4/12 p-3"></div>
            </div>
        </WrapperTeacher>
    );
};

export default UpdateCategoryTypeTeacher;

