import {useEffect} from "react"
import { useTranslation } from 'react-i18next';

import { Link, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import toast, { Toaster } from 'react-hot-toast';
import { clearMessage } from "../../store/reducers/globalReducer";
import Wrapper from "./Wrapper"
import { useDeleteProductMutation,useGetStudentsallQuery } from "../../store/services/productService";
import ScreenHeader from "../../components/ScreenHeader";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
const Products = () => {
   // const { t } = useTranslation('admin-dashboard-products-students-welcome');
   const { t } = useTranslation();

   let {page} = useParams();
   if(!page) {
      page = 1;
   }
   const {data = [], isFetching} = useGetStudentsallQuery(page);
   console.log(data);
   const {success} = useSelector(state => state.globalReducer);
    const dispatch = useDispatch();
    useEffect(() => {
     if(success) {
       toast.success(success);
     }
     return () => {
        dispatch(clearMessage())
     }
    }, [])
    const [delProduct, response] = useDeleteProductMutation();
    
    const deleteProduct = id => {
      if(window.confirm("Are you really want to delete this product?")) {
          delProduct(id);
      }
    }
    return(
       <Wrapper>
         <ScreenHeader>
          <Link to="/dashboard/products" className="btn-dark">
          {/* Well Come Admin To Students */}
          {t('admin-dashboard-products-students-welcome')}

          </Link>
          <Toaster position="top-right" />
          </ScreenHeader>
          {!isFetching ? data?.products?.length > 0 ? <div>
            <table className="w-full bg-gray-900 rounded-md ">
            <thead>
                    <tr className="border-b border-gray-800 text-left">
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* name */}
                        {t('admin-dashboard-products-students-name')}
 
                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* email */}
                        {t('admin-dashboard-products-students-email')}

                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Whatsapp */}
                        {t('admin-dashboard-products-students-whatsapp')}

                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* EmailVerified */}
                        {t('admin-dashboard-products-students-emailVerified')}

                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* Paid Course */}
                        {t('admin-dashboard-products-students-paidCourse')}

                        </th>
                       <th className="p-3 uppercase text-sm font-medium text-gray-500">
                        {/* UserID */}
                        {t('admin-dashboard-products-students-userID')}

                        </th>
                    </tr>
                 </thead>
                 <tbody>
                  {data?.products?.map(product => (
                     <tr className="odd:bg-gray-800" key={product._id}>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400">{product.name}</td>
                        <td className="p-3  text-sm font-normal text-gray-400">{product.email}</td>
                        <td className="p-3  text-sm font-normal text-gray-400">{product.whatsapp_number}</td>
                        <td className="p-3  text-sm font-normal text-gray-400">{product.email_verified ? "yes" : "No"}</td>
                        <td className="p-3  text-sm font-normal text-gray-400">{product.paid_courses.length}</td>
                        <td className="p-3  text-sm font-normal text-gray-400">{product._id}</td>
                        {/* <td className="p-3 capitalize text-sm font-normal text-gray-400">
                           <img src={`/images/${product.image1}`} alt="image name" className="w-20 h-20 rounded-md object-cover" />
                        </td> */}
                        <td className="p-3 capitalize text-sm font-normal text-gray-400"><Link to={`/dashboard/edit-student/${product._id}`} className="btn btn-warning">
                        {/* edit */}
                        {t('admin-dashboard-products-students-edit')}

                        </Link></td>
                        <td className="p-3 capitalize text-sm font-normal text-gray-400"><span className="btn btn-danger cursor-pointer" onClick={() => deleteProduct(product._id)}>
                           {/* delete */}
                           {t('admin-dashboard-products-students-delete')}

                           </span></td>
                     </tr>
                  ))}
                 </tbody>
            </table>
            <Pagination page={parseInt(page)} perPage={data.perPage} count={data.count} path="dashboard/products" />
          </div> : "no student": <Spinner />}
       </Wrapper>
    )
}
export default Products;