import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const apiUrl = process.env.REACT_APP_API_URL;
export const supService = createApi({
  reducerPath: 'supService',
  // baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:5000/api/course-pic' }),
  baseQuery: fetchBaseQuery({baseUrl: `${apiUrl}/api/course-pic`}),
  endpoints: (builder) => ({
    // SUPERROUTE
    createCoursePic: builder.mutation({
      query: (coursePicData) => ({
        url: '/create',
        method: 'POST',
        body: coursePicData,
      }),
    }),
    // SUPERROUTE
    getCoursePics: builder.query({
      query: (page) => {
        console.log("slice",page)
        return {
            url: `/${page}`,
            method: 'GET'
        }
       },
    }),


         //Endpoint to get courses with random base SupRoutes
 getCourseRandom: builder.query({
      query: () => {
        console.log("slice")
        return {
            url: `/courcesrandom`,
            method: 'GET'
        }
       },
    }),

      //Endpoint to get courses with teacher base SupRoutes
    getTeachers: builder.query({
      query: () => {
        console.log("fgdfgfgdfgdfg")
        return {
            url: `/teachers`,
            method: 'GET'
        }
       },
    }),

    // superRoute
    getCourses: builder.query({
      query: () => {
        console.log("fgdfgfgdfgdfg")
        return {
            url: `/courses`,
            method: 'GET'
        }
       },
    }),

    // getTeachers: builder.query({
    //   query: () => '/teachers',
    // }),
    getCoursesByTeacherId: builder.query({
      query: (teacherId) =>  `/courses?teacherId=${teacherId}`,
    }),
    // SUPER ROUTES
    getLecturesByCourseId: builder.query({
      query: (courseId) => `/lectures?courseId=${courseId}`,
    }),

    // SUPERROUTE
    getCategories: builder.query({
        query: () => '/categories',
      }),

      //Endpoint to get courses with Category SupRoutes
      getProductsByCategory: builder.query({
        query: (categoryId) => `/categoriesid?category=${categoryId}`,
      }),


      getPackages: builder.query({
        query: () => '/get/package/home/student-packages-three',
      }),

      getPackagesone: builder.query({
        query: () => '/get/package/home/student-packages-one',
      }),

      getPackagestwo: builder.query({
        query: () => '/get/package/home/student-packages-two',
      }),

      getPackageById: builder.query({
        query: (id) => `/get/package/byid/home/student-packages/${id}`,
      }),


        // payments
        getPaymentMethods: builder.query({
          query: () => '/web/get/allpayment',
        }),

  }),
});

export const {  useGetPaymentMethodsQuery, useGetPackageByIdQuery,useGetPackagesoneQuery,useGetPackagestwoQuery, useGetPackagesQuery,useCreateCoursePicMutation, useGetProductsByCategoryQuery ,useGetCategoriesQuery, useGetCoursePicsQuery ,useGetCourseRandomQuery,useGetTeachersQuery, useGetCoursesByTeacherIdQuery, useGetLecturesByCourseIdQuery , useGetCoursesQuery } = supService;
